import React, {useRef, useState, useEffect} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {
    Button,
    Container,
    Row,
    Col,
    Image,
    Form,
    Media,
    Dropdown,
} from "react-bootstrap";
import {connect, useSelector} from "react-redux";
import {
    postFileUploadStart,
    savePostStart,
    postFileRemoveStart,
    fetchPostCategoriesStart,
    postFilterUploadStart,
    postFileThumbnailUploadStart,
    postFileRemoveThubnailStart,
    saveDraftPostStart,
    saveSingleDraftPostsStart,
    saveSchadualTimeStart,
    saveSingleSchedualDraftPostsStart,
} from "../../../store/actions/PostAction";

import {
    fetchTrendingUsersStart,
    searchUserStart,
} from "../../../store/actions/HomeAction";

import {createNotification} from "react-redux-notify/lib/modules/Notifications";
import {getErrorNotificationMessage} from "../../helper/NotificationMessage";
import {translate, t} from "react-multi-lang";
import ContentEditable from "react-contenteditable";
import {set} from "date-fns/esm";
import PostEditor from "../postMentions/PostEditor";
import {stateToHTML} from "draft-js-export-html";
import {Multiselect} from "multiselect-react-dropdown";
import ContentCreatorSteps from "./ContentCreatorSteps";
import PostFilterModal from "./PostFilterModal";
import {GridContextProvider, GridDropZone, GridItem} from "react-grid-dnd";
import Select from "react-select";
import {components} from "react-select";
import makeAnimated from "react-select/animated";

import {Field, Formik} from "formik";
import {getSubscriptionLevelsStart} from "../../../store/actions/SubscriptionAction";
import AccessControlRestrict from "./AccessControlRestrict";
import {
    getUserSubscriberStart,
    getUserSubscribersStart,
} from "../../../store/actions/SubscriberAction";
import AccessControlMain from "./AccessControlMain";
import AccessControlSubMain from "./AccessControlSubMain";
import {EditorState, convertFromHTML, convertFromRaw} from "draft-js";
import PostEditorWithValue from "../postMentions/PostEditorWithValue";
import PostSchedualDateTModal from "./PostSchedualDateTModal";
import moment from "moment";
import PostSchedualDateTHomeModal from "./PostSchedualDateTHomeModal";
import {reducer} from "react-redux-notify";

const CreatePostIndex = (props) => {
    const {
        singleDraftPost: {data: singleDPost},
    } = useSelector((state) => state.post);

    const {
        postCategories: {data: postCategoriesD},
    } = useSelector((state) => state.post);
    // console.log("🚀 ~ CreatePostIndex ~ singleDPost:", singleDPost?.posts[0]?.content)
    console.log(
        "🚀 ~ CreatePostIndex ~ singleDPost:",
        singleDPost?.posts?.find((ele) => ele)
    );
    const {data} = useSelector((state) => state.users.profile);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    console.log("🚀 ~ CreatePostIndex ~ editorState:", editorState);

    const audioThumbRef = useRef(null);

    const [inputData, setInputData] = useState({});
    const [postFileData, setPostFileData] = useState([]);
    console.log("🚀 ~ CreatePostIndex ~ postFileData:", postFileData);
    const [currentPostFileData, setCurrentPostFileData] = useState([]);
    const [previewImage, setPreviewImage] = useState(false);
    console.log("🚀 ~ CreatePostIndex ~ previewImage:", previewImage);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(false);
    const [audioPreviewUrl, setAudioPreviewUrl] = useState(false);
    const [paidPost, setPaidPost] = useState(false);
    const [videoTitle, setVideoTitle] = useState("");
    const [isScheduleModdalOpen, setIsScheduleModdalOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [isSingleSchedualData, setIsSingleSchedualData] = useState(null);
    const [descriptionNoteS, setDescriptionNoteS] = useState("");
    const [IsStartDateError, setIsStartDateError] = useState(false);
    const [postContent, setPostContent] = useState(false);
    // console.log("🚀 ~ CreatePostIndex ~ startDate:", startDate)
    // console.log("🚀 ~ CreatePostIndex ~ descriptionNoteS:", descriptionNoteS)

    const [fileUploadStatus, setFileUploadStatus] = useState(false);

    const [videoThumbnailStatus, setVideoThumbnailStatus] = useState(false);
    const [audioThumbnailStatus, setAudioThumbnailStatus] = useState(false);

    const mentionsRef = useRef();

    const [editorContentState, setEditorContentstate] = useState("");

    const [editorHtmlContent, setEditorHtmlContent] = useState("");
    console.log("🚀 ~ CreatePostIndex ~ editorHtmlContent:", editorHtmlContent);

    const [disableImage, setDisableImage] = useState(false);

    const [disableVideo, setDisableVideo] = useState(false);
    console.log("🚀 ~ CreatePostIndex ~ disableVideo:", disableVideo);

    const [filterImageFilePath, setFilterImageFilePath] = useState("");

    const [videoThumbnail, setVideoThumbnail] = useState({
        file: "",
        post_file_id: "",
        type: "videoThubmnail",
    });

    const [audioTitle, setAudioTitle] = useState("");

    const [audioThumbnail, setAudioThumbnail] = useState({
        file: "",
        post_file_id: "",
        type: "audioThubmnail",
    });

    console.log("🚀 ~ CreatePostIndex ~ audioThumbnail:", audioThumbnail);

    const [disableAudio, setDisableAudio] = useState(false);
    console.log("🚀 ~ CreatePostIndex ~ disableAudio:", disableAudio);

    const [videoPreview, setVideoPreview] = useState({previewVideo: ""});
    const [audioPreview, setAudioPreview] = useState({previewAudio: ""});
    const {
        singleDraftPost: {
            data: {posts},
        },
    } = useSelector((state) => state.post);

    const [getAllFile, setGetAllFile] = useState([]);
    const [getAllId, setGetAllId] = useState("");
    console.log("🚀 ~ CreatePostIndex ~ getAllId:", getAllId);
    const {fileUpload} = useSelector((state) => state.post);
    const [showFilterModal, setshowFilterModal] = useState(false);
    const [showFilterModalAnimation, setshowFilterModalAnimation] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [durationT, setDurationT] = useState({
        durationValue: "",
        videoId: "",
    });

    const [isCategoriesSelected, setIsCategoriesSelected] = useState([]);

    const [listGroupedOp, setListGroupedOp] = useState({
        accessRestrictGropOpt: [
            {
                label: "Subscribers",
                options: [],
            },
            {
                label: "Friends",
                options: [],
            },
            {
                label: "Followers",
                options: [],
            },
        ],

        downloadRestrictGropOpt: [
            {
                label: "Subscribers",
                options: [],
            },
            {
                label: "Friends",
                options: [],
            },
            {
                label: "Followers",
                options: [],
            },
        ],

        forwardRestrictGropOpt: [
            {
                label: "Subscribers",
                options: [],
            },
            {
                label: "Friends",
                options: [],
            },
            {
                label: "Followers",
                options: [],
            },
        ],

        commentRestrictGropOpt: [
            {
                label: "Subscribers",
                options: [],
            },
            {
                label: "Friends",
                options: [],
            },
            {
                label: "Followers",
                options: [],
            },
        ],
    });

    const [accessMainValue, setAccessMainValue] = useState("public");

    console.log("🚀 ~ CreatePostIndex ~ accessMainValue:", accessMainValue);
    const [downloadAccessMainValue, setDownloadAccessMainValue] =
        useState("public");
    // console.log(
    console.log("🚀 ~ CreatePostIndex ~ listGroupedOp:", listGroupedOp);
    //   "🚀 ~ CreatePostIndex ~ downloadAccessMainValue:",
    //   downloadAccessMainValue
    // );
    const [forwardAccessMainValue, setForwardAccessMainValue] =
        useState("public");
    // console.log(
    //   "🚀 ~ CreatePostIndex ~ forwardAccessMainValue:",
    //   forwardAccessMainValue
    // );
    const [commentAccessMainValue, setCommentAccessMainValue] =
        useState("public");
    // console.log(
    //   "🚀 ~ CreatePostIndex ~ commentAccessMainValue:",
    //   commentAccessMainValue
    // );
    const [accessRestrictValue, setAccessRestrictValue] = useState({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
        friends: false,
    });

    console.log(
        "🚀 ~ CreatePostIndex ~ accessRestrictValue:",
        accessRestrictValue
    );
    const [downloadRestrictValue, setDownloadRestrictValue] = useState({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
        friends: false,
    });

    const [forwardRestrictValue, setForwardRestrictValue] = useState({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
        friends: false,
    });

    const [commentRestrictValue, setCommentRestrictValue] = useState({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
        friends: false,
    });
    console.log(
        "🚀 ~ CreatePostIndex ~ accessRestrictValue:",
        accessRestrictValue
    );
    console.log(
        "🚀 ~ CreatePostIndex ~ downloadRestrictValue:",
        downloadRestrictValue
    );
    console.log(
        "🚀 ~ CreatePostIndex ~ forwardRestrictValue:",
        forwardRestrictValue
    );
    console.log(
        "🚀 ~ CreatePostIndex ~ commentRestrictValue:",
        commentRestrictValue
    );

    const [subLevelOptions, setSubLevelOptions] = useState([]);
    const [subListsOptions, setSubListsOptions] = useState([]);

    const [accessControlMainSelected, setAccessControlMainSelected] = useState({
        selectedSubLevel: null,
        selectedSubList: null,
    });

    console.log(
        "🚀 ~ CreatePostIndex ~ accessControlMainSelected:",
        accessControlMainSelected
    );

    const [downloadMainSelected, setDownloadMainSelected] = useState({
        selectedSubLevel: null,
        selectedSubList: null,
    });

    console.log(
        "🚀 ~ CreatePostIndex ~ downloadMainSelected:",
        downloadMainSelected
    );

    const [forwardMainSelected, setForwardMainSelected] = useState({
        selectedSubLevel: null,
        selectedSubList: null,
    });

    console.log(
        "🚀 ~ CreatePostIndex ~ forwardMainSelected:",
        forwardMainSelected
    );

    const [commentMainSelected, setCommentMainSelected] = useState({
        selectedSubLevel: null,
        selectedSubList: null,
    });

    console.log(
        "🚀 ~ CreatePostIndex ~ commentMainSelected:",
        commentMainSelected
    );

    const [selectedSubLevel, setSelectedSubLevel] = useState(null);
    const [selectedSubList, setSelectedSubList] = useState(null);
    const [multiCheckbox, setMultiCheckbox] = useState({
        tipsCheck: true,
        bookMarkCheck: true,
        LikeCheck: true,
        reportCheck: true,
        commentCheck: true,
    });

    const [isRestritError, setIsRestritError] = useState({
        accessRestrictError: {subLevelError: false, subListError: false},
        downloadRestrictError: {subLevelError: false, subListError: false},
        forwardRestrictError: {subLevelError: false, subListError: false},
        commentRestrictError: {subLevelError: false, subListError: false},
    });

    // console.log("🚀 ~ CreatePostIndex ~ multiCheckbox:", multiCheckbox)

    const {allSubLevelList} = useSelector((state) => state.subscriptions);

    // console.log("🚀 ~ CreatePostIndex ~ selectedSubLevel:", selectedSubLevel);
    // console.log("🚀 ~ CreatePostIndex ~ subListsOptions:", subListsOptions);

    // console.log("🚀 ~ CreatePostIndex ~ subLevelOptions:", subLevelOptions);

    // console.log(
    // console.log("🚀 ~ CreatePostIndex ~ isRestritError:", isRestritError)
    //   "🚀 ~ CreatePostIndex ~ certifiedCreatorAccessValue:",
    //   certifiedCreatorAccessValue
    // );

    // console.log("🚀 ~ CreatePostIndex ~ creatorAccessValue:", creatorAccessValue);

    const inputFileVideoThumb = useRef(null);

    const videoEl = useRef(null);

    const locationD = useLocation();
    const params = useParams();
    console.log("🚀 ~ CreatePostIndex ~ params:", params);
    console.log("🚀 ~ CreatePostIndex ~ locationD:", locationD);

    function getRandomDuration(min, max, duration) {
        if (max < min) {
            return []; // Return an empty array if max is less than min
        } else {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }

    const handleLoadedMetadata = (index, duration, video) => {
        setPostFileData((prevVideos) => {
            const minDuration = 5;
            const maxDuration = Math.trunc(duration);
            const durationsArray =
                duration <= 30
                    ? Array.from({length: 1}, () =>
                        getRandomDuration(2, maxDuration, duration)
                    )
                    : Array.from({length: 3}, () =>
                        getRandomDuration(minDuration, maxDuration, duration)
                    );
            const updatedVideos = [...prevVideos];

            // updatedVideos[index].duration = Math.trunc(duration);
            updatedVideos[index] = {
                ...updatedVideos[index],
                durationsArray: durationsArray,
            };

            if (index == 0) {
                setDurationT({
                    durationValue: updatedVideos[0].durationsArray[0],
                    videoId: video?.post_file_id,
                });
            }

            return updatedVideos;
        });
    };

    const handleChangeDescriptionNote = (e) => {
        setDescriptionNoteS(e.target.value);
    };

    function onChange(sourceId, sourceIndex, targetIndex, forType) {
        if (sourceId !== "items" || targetIndex === null) {
            return;
        }
        // console.log("🚀 ~ onChange ~ sourceId:", sourceId)
        // console.log("🚀 ~ onChange ~ sourceIndex:", sourceIndex)
        // console.log("🚀 ~ onChange ~ targetIndex:", targetIndex)

        const nextState = [...postFileData];
        const [item] = nextState.splice(sourceIndex, 1);
        nextState.splice(targetIndex, 0, item);
        // console.log("🚀 ~ onChange ~ nextState:", nextState)

        const getAllFiltredId = nextState.map((item) => {
            return item.post_file_id;
        });

        if (forType === "video") {

            if (!videoThumbnail.file) {
                setDurationT({
                    durationValue: nextState[0]?.durationsArray[0],
                    videoId: nextState[0]?.post_file_id,
                });
            } else {
                setVideoThumbnail({
                    ...videoThumbnail,
                    post_file_id: getAllFiltredId?.toString(),
                });
            }
        }

        // console.log("🚀 ~ getAllFiltredId ~ getAllFiltredId:", getAllFiltredId)
        setGetAllId(getAllFiltredId?.toString());
        setPostFileData(nextState);
    }

    const handleOpenFilterModal = (event, image, for_image) => {
        event.preventDefault();
        setCurrentPostFileData(image);
        setshowFilterModal(true);
        setshowFilterModalAnimation(true);
    };

    const handleCloseFilterModal = () => {
        setshowFilterModal(false);
        setTimeout(() => {
            setshowFilterModalAnimation(false);
        }, 600);
    };

    useEffect(() => {
        if (params?.id) {
            props.dispatch(
                saveSingleDraftPostsStart({
                    dragt_post_id: params?.id,
                    setEditorState: setEditorState,
                    setAccessMainValue,
                    setDownloadAccessMainValue,
                    setForwardAccessMainValue,
                    setCommentAccessMainValue,
                    setAccessRestrictValue,
                    setDownloadRestrictValue,
                    setForwardRestrictValue,
                    setCommentRestrictValue,
                    setMultiCheckbox,
                    setAccessControlMainSelected,
                    setCommentMainSelected,
                    setDownloadMainSelected,
                    setForwardMainSelected,
                    setIsCategoriesSelected,
                    postFileData,
                    setPostFileData,
                    setGetAllId,
                    getAllId,
                    setPreviewImage,
                    setAudioPreviewUrl,
                    setDisableVideo,
                    setDisableAudio,
                    setDisableImage,
                    setListGroupedOp,
                    listGroupedOp,
                    setAudioThumbnail,
                    setAudioThumbnailStatus,
                    setVideoPreviewUrl,
                    handleLoadedMetadata,
                    setEditorHtmlContent,
                    setFileUploadStatus,
                })
            );
        } else if (params?.schedualId) {
            debugger;

            props.dispatch(
                saveSingleSchedualDraftPostsStart({
                    schedule_post_id: params?.schedualId,
                    setEditorState: setEditorState,
                    setAccessMainValue,
                    setDownloadAccessMainValue,
                    setForwardAccessMainValue,
                    setCommentAccessMainValue,
                    setAccessRestrictValue,
                    setDownloadRestrictValue,
                    setForwardRestrictValue,
                    setCommentRestrictValue,
                    setMultiCheckbox,
                    setAccessControlMainSelected,
                    setCommentMainSelected,
                    setDownloadMainSelected,
                    setForwardMainSelected,
                    setIsCategoriesSelected,
                    postFileData,
                    setPostFileData,
                    setGetAllId,
                    getAllId,
                    setPreviewImage,
                    setAudioPreviewUrl,
                    setDisableVideo,
                    setDisableAudio,
                    setDisableImage,
                    setListGroupedOp,
                    listGroupedOp,
                    setAudioThumbnail,
                    setAudioThumbnailStatus,
                    setVideoPreviewUrl,
                    handleLoadedMetadata,
                    setEditorHtmlContent,
                    setFileUploadStatus,
                    // setStartDate,
                    // setDescriptionNoteS
                })
            );
        }

        props.dispatch(fetchPostCategoriesStart());
    }, []);

    useEffect(() => {
        if (
            props.fileUpload.loading === false &&
            props.fileUpload.data.post_file.length > 0
        ) {
            let files = [];
            props.fileUpload.data.post_file.map((value, i) => {
                files.push(value.post_file);
            });
            // setPostFileData(files);
            setInputData({
                ...inputData,
                post_file_id: props.fileUpload.data.post_file_id,
                // post_file_id: inputData.post_file_id
                //   ? inputData.post_file_id + "," + props.fileUpload.data.post_file_id
                //   : props.fileUpload.data.post_file_id,
            });
        }
    }, [!props.fileUpload.loading]);

    useEffect(() => {
        if (props.fileFilterUpload.imageFilterDisable) {
            const oldFile =
                props.fileFilterUpload.inputData.postFileData.post_file_id;
            const newFilePath = props.fileFilterUpload.inputData.filterImageBase64;

            setFilterImageFilePath(newFilePath);

            const filterPostFileData = postFileData.map((image) => {
                return {
                    ...image,
                    file: oldFile === image.post_file_id ? newFilePath : image.file,
                };
            });
            setPostFileData(filterPostFileData);
        }
    }, [props.fileFilterUpload.imageFilterDisable]);

    const checkImageFilerLoading = (imageFilePath) => {
        return imageFilePath === filterImageFilePath ? true : false;
    };

    useEffect(() => {
        if (props.fileRemove.loading === false) {
            let files = [];
            if (props.fileRemove.data.post_file.length > 0) {
                props.fileRemove.data.post_file.map((value, i) => {
                    files.push(value);
                });
            } else {
                setFileUploadStatus(false);
                setDisableVideo(false);
                setDisableAudio(false);
                setPaidPost(false);
                setDisableImage(false);
                setPreviewImage(false);
                setVideoPreviewUrl(false);
                setAudioPreviewUrl(false);
                setVideoThumbnailStatus(false);
                setAudioThumbnailStatus(false);
                // setAudioThumbnail(false);
            }
            setInputData({
                ...inputData,
                post_file_id: props.fileRemove.data.post_file_id,
            });
            // setPostFileData(files);

            if (postFileData.length === 0) {
                setAudioThumbnail({
                    file: "",
                    post_file_id: "",
                    type: "audioThubmnail",
                });
                setVideoThumbnail({
                    file: "",
                    post_file_id: "",
                    type: "videoThubmnail",
                });
                setDisableImage(false);
                setDisableVideo(false);
                setDisableAudio(false);
            }
        }
    }, [!props.fileRemove.loading]);

    const handleChangeImage = (event, fileType) => {
        if (event.target.files.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (postFileData.length >= 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (event.target.files.length + postFileData.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            let data_array = [];

            [...event.target.files].forEach((file, key) => {
                let name = "file[" + key + "]";

                data_array[name] = file;
            });
            data_array["file_type"] = fileType;

            setPreviewImage(true);
            setFileUploadStatus(true);
            setPaidPost(true);
            // setDisableVideo(true);
            // setDisableAudio(true);

            if (event.target.files.length > 0) {
                setDisableVideo(true);
                setDisableAudio(true);

                props.dispatch(
                    postFileUploadStart({
                        data: data_array,
                        setPostFileData,
                        postFileData,
                        setGetAllFile,
                        getAllFile,
                        setGetAllId,
                        setDisableVideo,
                        setDisableAudio,
                        setDisableImage,
                        fileType: fileType
                    })
                );
            } else {
                if (postFileData.length === 0) {
                    setDisableVideo(false);
                    setDisableAudio(false);
                    setDisableImage(false);
                } else {
                    setDisableVideo(true);
                    setDisableAudio(true);
                    setDisableImage(false);
                }
            }
        }
    };

    const handleOpenSchdualModal = () => {
        setIsScheduleModdalOpen(true);
        setIsStartDateError(false);
        setIsSingleSchedualData(null);

        if (params?.schedualId && posts[0]?.schedule_note) {
            setDescriptionNoteS(posts[0]?.schedule_note);
        }

        if (params?.schedualId && posts[0]?.schedule_time) {
            const parsedValue = new Date(Date.parse(posts[0]?.schedule_time));

            setIsSingleSchedualData(parsedValue);
            // setStartDate(parsedValue);
        }
    };

    const handleCloseSchdualModal = () => {
        setIsScheduleModdalOpen(false);

        setStartDate(null);
        setDescriptionNoteS("");
    };

    const handleChangeVideo = (event, fileType) => {

        if (event.target.files.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (postFileData.length >= 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (event.target.files.length + postFileData.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            let data_array = [];

            [...event.target.files].forEach((file, key) => {
                let name = "file[" + key + "]";

                data_array[name] = file;
            });

            data_array["file_type"] = fileType;

            setPaidPost(true);
            setFileUploadStatus(true);
            setVideoThumbnailStatus(true);
            // setDisableImage(true);
            // setDisableAudio(true);
            setVideoPreviewUrl(true);

            if (event.target.files.length > 0) {
                setDisableImage(true);
                setDisableAudio(true);

                props.dispatch(
                    postFileUploadStart({
                        data: data_array,
                        setPostFileData,
                        postFileData,
                        setGetAllFile,
                        getAllFile,
                        setGetAllId,
                        setDisableImage,
                        setDisableAudio,
                        setDisableVideo,
                        fileType: fileType
                    })
                );
            } else {
                if (postFileData.length === 0) {
                    setDisableVideo(false);
                    setDisableAudio(false);
                    setDisableImage(false);
                } else {
                    setDisableVideo(false);
                    setDisableAudio(true);
                    setDisableImage(true);
                }
            }
        }
    };

    const handleChangeAudio = (event, fileType) => {
        if (event.target.files.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (postFileData.length >= 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else if (event.target.files.length + postFileData.length > 20) {
            const notificationMessage = getErrorNotificationMessage(
                t("max_post_upload_limit")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            let data_array = [];

            [...event.target.files].forEach((file, key) => {
                let name = "file[" + key + "]";

                data_array[name] = file;
            });

            data_array["file_type"] = fileType;

            setPaidPost(true);
            setFileUploadStatus(true);
            setAudioThumbnailStatus(true);
            // setAudioThumbnail(true);
            // setDisableImage(true);
            // setDisableVideo(true);
            setAudioPreviewUrl(true);

            if (event.target.files.length > 0) {
                setDisableImage(true);
                setDisableVideo(true);

                props.dispatch(
                    postFileUploadStart({
                        data: data_array,
                        setPostFileData,
                        postFileData,
                        setGetAllFile,
                        getAllFile,
                        setGetAllId,
                        setDisableImage,
                        setDisableVideo,
                        setDisableAudio,
                        fileType: fileType
                    })
                );
            } else {
                if (postFileData.length === 0) {
                    setDisableVideo(false);
                    setDisableAudio(false);
                    setDisableImage(false);
                } else {
                    setDisableVideo(true);
                    setDisableAudio(false);
                    setDisableImage(true);
                }
            }
        }
    };

    const handleClose = (event, post_file, type) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                t("file_being_uploaded")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            // setPostFileData([]);
            props.dispatch(
                postFileRemoveStart({
                    data: {
                        file: post_file?.file,
                        file_type: props.fileUpload?.data?.post_file?.file_type,
                        blur_file: props.fileUpload?.data?.post_file?.blur_file,
                        // post_file_id: inputData.post_file_id,
                        post_file_id: post_file?.post_file_id,
                    },
                    setPostFileData,
                    postFileData,
                    setInputData,
                    inputData,
                    setGetAllId,
                    setDurationT,
                    durationT,
                    type,
                    setVideoThumbnail,
                    videoThumbnail,
                })
            );
        }
    };

    const handleRemoveThubnail = (event, post_file, type) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                t("file_being_uploaded")
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            if (type === "audio") {
                props.dispatch(
                    postFileRemoveThubnailStart({
                        data: {
                            id: audioThumbnail?.post_file_id,
                            post_id: getAllId,
                        },
                        setAudioThumbnail,
                        audioThumbnail,
                        type,
                        audioThumbRef,
                    })
                );
            } else {
                props.dispatch(
                    postFileRemoveThubnailStart({
                        data: {
                            id: videoThumbnail?.post_file_id,
                            post_id: getAllId,
                        },
                        setVideoThumbnail,
                        videoThumbnail,
                        type,
                        setDurationT,
                        postFileData,
                        inputFileVideoThumb,
                    })
                );
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const filtreAccessControlSubLevelId =
            accessControlMainSelected.selectedSubLevel?.map((ele) => ele.id);

        const filtreAccessControlSubListSubscribers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreAccessControlSubListId =
            filtreAccessControlSubListSubscribers?.map((ele) => ele.id);

        const filtreAccessControlSubListFollowers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreAccessControlFollowerListId =
            filtreAccessControlSubListFollowers?.map((ele) => ele.id);

        const filtreAccessControlSubListFriends =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreAccessControlFriendListId =
            filtreAccessControlSubListFriends?.map((ele) => ele.id);

        console.log(
            "🚀 ~ handleSubmit ~ filtreAccessControlSubListSubscribers:",
            filtreAccessControlSubListSubscribers
        );
        console.log(
            "🚀 ~ handleSubmit ~ filtreAccessControlSubListId:",
            filtreAccessControlSubListId
        );
        console.log(
            "🚀 ~ handleSubmit ~ filtreAccessControlSubListFollowers:",
            filtreAccessControlSubListFollowers
        );
        console.log(
            "🚀 ~ handleSubmit ~ filtreAccessControlFollowerListId:",
            filtreAccessControlFollowerListId
        );

        console.log(
            "🚀 ~ handleSubmit ~ filtreAccessControlSubLevelId:",
            filtreAccessControlSubLevelId
        );

        const filtreDownloadSubLevelId = downloadMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListSubscribers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreDownloadSubListId = filtreDownloadSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFollowers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreDownloadFollowerListId = filtreDownloadSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFriends =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreDownloadFriendsListId = filtreDownloadSubListFriends?.map(
            (ele) => ele.id
        );

        const filtreForwardSubLevelId = forwardMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListSubscribers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreForwardSubListId = filtreForwardSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListFollowers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreForwardFollowerListId = filtreForwardSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListFriends =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreForwardFriendsListId = filtreForwardSubListFriends?.map(
            (ele) => ele.id
        );

        const filtreCommentSubLevelId = commentMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListSubscribers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreCommentSubListId = filtreCommentSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListFollowers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreCommentFollowerListId = filtreCommentSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListFriends =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreCommentFriendListId = filtreCommentSubListFriends?.map(
            (ele) => ele.id
        );

        console.log(
            "🚀 ~ handleSubmit ~ filtreCommentSubLevelId:",
            filtreCommentSubLevelId
        );
        if (
            accessMainValue === "restrict_user" &&
            accessRestrictValue.subscriber_level === true &&
            (accessControlMainSelected?.selectedSubLevel?.length === 0 ||
                accessControlMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                accessRestrictError: {
                    ...isRestritError.accessRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            accessMainValue === "restrict_user" &&
            accessRestrictValue.subscriber_list === true &&
            (accessControlMainSelected?.selectedSubList?.length === 0 ||
                accessControlMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                accessRestrictError: {
                    ...isRestritError.accessRestrictError,
                    subListError: true,
                },
            });
        } else if (
            downloadAccessMainValue === "restrict_user" &&
            downloadRestrictValue.subscriber_level === true &&
            (downloadMainSelected?.selectedSubLevel?.length === 0 ||
                downloadMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                downloadRestrictError: {
                    ...isRestritError.downloadRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            downloadAccessMainValue === "restrict_user" &&
            downloadRestrictValue.subscriber_list === true &&
            (downloadMainSelected?.selectedSubList?.length === 0 ||
                downloadMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                downloadRestrictError: {
                    ...isRestritError.downloadRestrictError,
                    subListError: true,
                },
            });
        } else if (
            forwardAccessMainValue === "restrict_user" &&
            forwardRestrictValue.subscriber_level === true &&
            (forwardMainSelected?.selectedSubLevel?.length === 0 ||
                forwardMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                forwardRestrictError: {
                    ...isRestritError.forwardRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            forwardAccessMainValue === "restrict_user" &&
            forwardRestrictValue.subscriber_list === true &&
            (forwardMainSelected?.selectedSubList?.length === 0 ||
                forwardMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                forwardRestrictError: {
                    ...isRestritError.forwardRestrictError,
                    subListError: true,
                },
            });
        } else if (
            commentAccessMainValue === "restrict_user" &&
            commentRestrictValue.subscriber_level === true &&
            (commentMainSelected?.selectedSubLevel?.length === 0 ||
                commentMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                commentRestrictError: {
                    ...isRestritError.commentRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            commentAccessMainValue === "restrict_user" &&
            commentRestrictValue.subscriber_list === true &&
            (commentMainSelected?.selectedSubList?.length === 0 ||
                commentMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                commentRestrictError: {
                    ...isRestritError.commentRestrictError,
                    subListError: true,
                },
            });
        } else {
            debugger;
            if (fileUploadStatus) {
                if (durationT.durationValue) {
                    debugger;
                    props.dispatch(
                        savePostStart({
                            content: editorHtmlContent,
                            amount: inputData.amount ? inputData.amount : "",
                            video_thubnail__posid:
                                durationT.videoId === undefined ? "" : durationT.videoId,
                            video_thubnail_sec:
                                durationT.durationValue === undefined
                                    ? ""
                                    : durationT.durationValue,
                            // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                            post_file_id: getAllId,
                            preview_file: inputData.preview_file
                                ? inputData.preview_file
                                : "",
                            category_ids: inputData.category_ids
                                ? inputData.category_ids
                                : [],
                            video_preview_file: inputData.video_preview_file
                                ? inputData.video_preview_file
                                : "",
                            is_mature: postContent ? 1 : 0,
                            access_control:
                                accessMainValue === "public"
                                    ? 1
                                    : accessMainValue === "restrict_user"
                                        ? 2
                                        : accessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            ac_follower_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            ac_follwing_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.following === true
                                        ? 1
                                        : 0,

                            ac_subscriber_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            ac_friends_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            ac_restrict_level_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubLevelId === undefined
                                        ? null
                                        : filtreAccessControlSubLevelId.toString(),

                            ac_restrict_list_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubListId === undefined
                                        ? null
                                        : filtreAccessControlSubListId.toString(),

                            ac_restrict_follower_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFollowerListId === undefined
                                        ? null
                                        : filtreAccessControlFollowerListId.toString(),

                            ac_restrict_friends_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFriendListId === undefined
                                        ? null
                                        : filtreAccessControlFriendListId.toString(),

                            download_type:
                                downloadAccessMainValue === "public"
                                    ? 1
                                    : downloadAccessMainValue === "restrict_user"
                                        ? 2
                                        : downloadAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            download_follower_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            download_following_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.following === true
                                        ? 1
                                        : 0,

                            download_subscriber_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            download_friends_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            download_restrict_level_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubLevelId === undefined
                                        ? null
                                        : filtreDownloadSubLevelId.toString(),

                            download_restrict_list_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubListId === undefined
                                        ? null
                                        : filtreDownloadSubListId.toString(),

                            download_restrict_follower_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFollowerListId === undefined
                                        ? null
                                        : filtreDownloadFollowerListId.toString(),

                            download_restrict_friends_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFriendsListId === undefined
                                        ? null
                                        : filtreDownloadFriendsListId.toString(),

                            forward_type:
                                forwardAccessMainValue === "public"
                                    ? 1
                                    : forwardAccessMainValue === "restrict_user"
                                        ? 2
                                        : forwardAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            forward_follower_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            forward_following_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.following === true
                                        ? 1
                                        : 0,

                            forward_subscriber_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            forward_friends_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            forward_restrict_level_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubLevelId === undefined
                                        ? null
                                        : filtreForwardSubLevelId.toString(),

                            forward_restrict_list_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubListId === undefined
                                        ? null
                                        : filtreForwardSubListId.toString(),

                            forward_restrict_follower_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFollowerListId === undefined
                                        ? null
                                        : filtreForwardFollowerListId.toString(),

                            forward_restrict_friends_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFriendsListId === undefined
                                        ? null
                                        : filtreForwardFriendsListId.toString(),

                            comment_type:
                                commentAccessMainValue === "public"
                                    ? 1
                                    : commentAccessMainValue === "restrict_user"
                                        ? 2
                                        : commentAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            comment_follower_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            comment_following_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.following === true
                                        ? 1
                                        : 0,

                            comment_subscriber_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            comment_friends_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            comment_restrict_level_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubLevelId === undefined
                                        ? null
                                        : filtreCommentSubLevelId.toString(),

                            comment_restrict_list_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubListId === undefined
                                        ? null
                                        : filtreCommentSubListId.toString(),

                            comment_restrict_follower_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFollowerListId === undefined
                                        ? null
                                        : filtreCommentFollowerListId.toString(),

                            comment_restrict_friends_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFriendListId === undefined
                                        ? null
                                        : filtreCommentFriendListId.toString(),

                            tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                            bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                            like: multiCheckbox.LikeCheck === true ? 1 : 0,
                            report: multiCheckbox.reportCheck === true ? 1 : 0,
                            comment: multiCheckbox.commentCheck === true ? 1 : 0,
                        })
                    );
                } else {
                    debugger;
                    props.dispatch(
                        savePostStart({
                            content: editorHtmlContent,
                            amount: inputData.amount ? inputData.amount : "",
                            // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                            post_file_id: getAllId,
                            preview_file: inputData.preview_file
                                ? inputData.preview_file
                                : "",
                            category_ids: inputData.category_ids
                                ? inputData.category_ids
                                : [],
                            video_preview_file: inputData.video_preview_file
                                ? inputData.video_preview_file
                                : "",
                            is_mature: postContent ? 1 : 0,

                            access_control:
                                accessMainValue === "public"
                                    ? 1
                                    : accessMainValue === "restrict_user"
                                        ? 2
                                        : accessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            ac_follower_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            ac_follwing_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.following === true
                                        ? 1
                                        : 0,

                            ac_subscriber_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            ac_friends_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            ac_restrict_level_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubLevelId === undefined
                                        ? null
                                        : filtreAccessControlSubLevelId.toString(),

                            ac_restrict_list_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubListId === undefined
                                        ? null
                                        : filtreAccessControlSubListId.toString(),

                            ac_restrict_follower_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFollowerListId === undefined
                                        ? null
                                        : filtreAccessControlFollowerListId.toString(),

                            ac_restrict_friends_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFriendListId === undefined
                                        ? null
                                        : filtreAccessControlFriendListId.toString(),

                            download_type:
                                downloadAccessMainValue === "public"
                                    ? 1
                                    : downloadAccessMainValue === "restrict_user"
                                        ? 2
                                        : downloadAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            download_follower_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            download_following_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.following === true
                                        ? 1
                                        : 0,

                            download_subscriber_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            download_friends_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            download_restrict_level_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubLevelId === undefined
                                        ? null
                                        : filtreDownloadSubLevelId.toString(),

                            download_restrict_list_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubListId === undefined
                                        ? null
                                        : filtreDownloadSubListId.toString(),

                            download_restrict_follower_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFollowerListId === undefined
                                        ? null
                                        : filtreDownloadFollowerListId.toString(),

                            download_restrict_friends_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFriendsListId === undefined
                                        ? null
                                        : filtreDownloadFriendsListId.toString(),

                            forward_type:
                                forwardAccessMainValue === "public"
                                    ? 1
                                    : forwardAccessMainValue === "restrict_user"
                                        ? 2
                                        : forwardAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            forward_follower_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            forward_following_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.following === true
                                        ? 1
                                        : 0,

                            forward_subscriber_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            forward_friends_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            forward_restrict_level_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubLevelId === undefined
                                        ? null
                                        : filtreForwardSubLevelId.toString(),

                            forward_restrict_list_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubListId === undefined
                                        ? null
                                        : filtreForwardSubListId.toString(),

                            forward_restrict_follower_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFollowerListId === undefined
                                        ? null
                                        : filtreForwardFollowerListId.toString(),

                            forward_restrict_friends_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFriendsListId === undefined
                                        ? null
                                        : filtreForwardFriendsListId.toString(),

                            comment_type:
                                commentAccessMainValue === "public"
                                    ? 1
                                    : commentAccessMainValue === "restrict_user"
                                        ? 2
                                        : commentAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            comment_follower_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            comment_following_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.following === true
                                        ? 1
                                        : 0,

                            comment_subscriber_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            comment_friends_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            comment_restrict_level_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubLevelId === undefined
                                        ? null
                                        : filtreCommentSubLevelId.toString(),

                            comment_restrict_list_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubListId === undefined
                                        ? null
                                        : filtreCommentSubListId.toString(),

                            comment_restrict_follower_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFollowerListId === undefined
                                        ? null
                                        : filtreCommentFollowerListId.toString(),

                            comment_restrict_friends_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFriendListId === undefined
                                        ? null
                                        : filtreCommentFriendListId.toString(),

                            tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                            bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                            like: multiCheckbox.LikeCheck === true ? 1 : 0,
                            report: multiCheckbox.reportCheck === true ? 1 : 0,
                            comment: multiCheckbox.commentCheck === true ? 1 : 0,
                        })
                    );
                }
            } else {
                debugger;
                props.dispatch(
                    savePostStart({
                        content: editorHtmlContent,
                        amount: inputData.amount ? inputData.amount : "",
                        category_ids: inputData.category_ids ? inputData.category_ids : [],
                        is_mature: postContent ? 1 : 0,

                        access_control:
                            accessMainValue === "public"
                                ? 1
                                : accessMainValue === "restrict_user"
                                    ? 2
                                    : accessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        ac_follower_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        ac_follwing_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.following === true
                                    ? 1
                                    : 0,
                        ac_subscriber_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,

                        ac_friends_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        ac_restrict_level_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlSubLevelId === undefined
                                    ? null
                                    : filtreAccessControlSubLevelId.toString(),

                        ac_restrict_list_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlSubListId === undefined
                                    ? null
                                    : filtreAccessControlSubListId.toString(),

                        ac_restrict_follower_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlFollowerListId === undefined
                                    ? null
                                    : filtreAccessControlFollowerListId.toString(),

                        ac_restrict_friends_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlFriendListId === undefined
                                    ? null
                                    : filtreAccessControlFriendListId.toString(),

                        download_type:
                            downloadAccessMainValue === "public"
                                ? 1
                                : downloadAccessMainValue === "restrict_user"
                                    ? 2
                                    : downloadAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        download_follower_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        download_following_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.following === true
                                    ? 1
                                    : 0,

                        download_subscriber_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,

                        download_friends_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        download_restrict_level_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadSubLevelId === undefined
                                    ? null
                                    : filtreDownloadSubLevelId.toString(),

                        download_restrict_list_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadSubListId === undefined
                                    ? null
                                    : filtreDownloadSubListId.toString(),

                        download_restrict_follower_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadFollowerListId === undefined
                                    ? null
                                    : filtreDownloadFollowerListId.toString(),

                        download_restrict_friends_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadFriendsListId === undefined
                                    ? null
                                    : filtreDownloadFriendsListId.toString(),

                        forward_type:
                            forwardAccessMainValue === "public"
                                ? 1
                                : forwardAccessMainValue === "restrict_user"
                                    ? 2
                                    : forwardAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        forward_follower_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        forward_following_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.following === true
                                    ? 1
                                    : 0,

                        forward_subscriber_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,

                        forward_friends_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        forward_restrict_level_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardSubLevelId === undefined
                                    ? null
                                    : filtreForwardSubLevelId.toString(),

                        forward_restrict_list_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardSubListId === undefined
                                    ? null
                                    : filtreForwardSubListId.toString(),

                        forward_restrict_follower_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardFollowerListId === undefined
                                    ? null
                                    : filtreForwardFollowerListId.toString(),

                        forward_restrict_friends_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardFriendsListId === undefined
                                    ? null
                                    : filtreForwardFriendsListId.toString(),

                        comment_type:
                            commentAccessMainValue === "public"
                                ? 1
                                : commentAccessMainValue === "restrict_user"
                                    ? 2
                                    : commentAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        comment_follower_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        comment_following_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.following === true
                                    ? 1
                                    : 0,

                        comment_subscriber_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,

                        comment_friends_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        comment_restrict_level_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentSubLevelId === undefined
                                    ? null
                                    : filtreCommentSubLevelId.toString(),

                        comment_restrict_list_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentSubListId === undefined
                                    ? null
                                    : filtreCommentSubListId.toString(),

                        comment_restrict_follower_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentFollowerListId === undefined
                                    ? null
                                    : filtreCommentFollowerListId.toString(),

                        comment_restrict_friends_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentFriendListId === undefined
                                    ? null
                                    : filtreCommentFriendListId.toString(),

                        tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                        bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                        like: multiCheckbox.LikeCheck === true ? 1 : 0,
                        report: multiCheckbox.reportCheck === true ? 1 : 0,
                        comment: multiCheckbox.commentCheck === true ? 1 : 0,
                    })
                );
                const notificationMessage = getErrorNotificationMessage(
                    t("please_upload_media_files")
                );
                props.dispatch(createNotification(notificationMessage));
            }
        }
    };

    const handleSubmitDraft = (event) => {
        event.preventDefault();

        const filtreAccessControlSubLevelId =
            accessControlMainSelected.selectedSubLevel?.map((ele) => ele.id);
        const filtreAccessControlSubListSubscribers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreAccessControlSubListId =
            filtreAccessControlSubListSubscribers?.map((ele) => ele.id);
        const filtreAccessControlSubListFollowers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreAccessControlFollowerListId =
            filtreAccessControlSubListFollowers?.map((ele) => ele.id);

        const filtreAccessControlSubListFriends =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreAccessControlFriendListId =
            filtreAccessControlSubListFriends?.map((ele) => ele.id);

        const filtreDownloadSubLevelId = downloadMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListSubscribers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreDownloadSubListId = filtreDownloadSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFollowers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreDownloadFollowerListId = filtreDownloadSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFriends =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreDownloadFriendsListId = filtreDownloadSubListFriends?.map(
            (ele) => ele.id
        );

        console.log(
            "🚀 ~ handleSubmitDraft ~ filtreDownloadFollowerListId:",
            filtreDownloadFollowerListId
        );

        const filtreForwardSubLevelId = forwardMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListSubscribers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreForwardSubListId = filtreForwardSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListFollowers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreForwardFollowerListId = filtreForwardSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListFriends =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreForwardFriendsListId = filtreForwardSubListFriends?.map(
            (ele) => ele.id
        );

        const filtreCommentSubLevelId = commentMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListSubscribers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreCommentSubListId = filtreCommentSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListFollowers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreCommentFollowerListId = filtreCommentSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListFriends =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreCommentFriendListId = filtreCommentSubListFriends?.map(
            (ele) => ele.id
        );
        if (
            accessMainValue === "restrict_user" &&
            accessRestrictValue === "subscriber_level" &&
            (accessControlMainSelected?.selectedSubLevel?.length === 0 ||
                accessControlMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                accessRestrictError: {
                    ...isRestritError.accessRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            accessMainValue === "restrict_user" &&
            accessRestrictValue === "select_from_list" &&
            (accessControlMainSelected?.selectedSubList?.length === 0 ||
                accessControlMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                accessRestrictError: {
                    ...isRestritError.accessRestrictError,
                    subListError: true,
                },
            });
        } else if (
            downloadAccessMainValue === "restrict_user" &&
            downloadRestrictValue === "subscriber_level" &&
            (downloadMainSelected?.selectedSubLevel?.length === 0 ||
                downloadMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                downloadRestrictError: {
                    ...isRestritError.downloadRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            downloadAccessMainValue === "restrict_user" &&
            downloadRestrictValue === "select_from_list" &&
            (downloadMainSelected?.selectedSubList?.length === 0 ||
                downloadMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                downloadRestrictError: {
                    ...isRestritError.downloadRestrictError,
                    subListError: true,
                },
            });
        } else if (
            forwardAccessMainValue === "restrict_user" &&
            forwardRestrictValue === "subscriber_level" &&
            (forwardMainSelected?.selectedSubLevel?.length === 0 ||
                forwardMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                forwardRestrictError: {
                    ...isRestritError.forwardRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            forwardAccessMainValue === "restrict_user" &&
            forwardRestrictValue === "select_from_list" &&
            (forwardMainSelected?.selectedSubList?.length === 0 ||
                forwardMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                forwardRestrictError: {
                    ...isRestritError.forwardRestrictError,
                    subListError: true,
                },
            });
        } else if (
            commentAccessMainValue === "restrict_user" &&
            commentRestrictValue === "subscriber_level" &&
            (commentMainSelected?.selectedSubLevel?.length === 0 ||
                commentMainSelected?.selectedSubLevel === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                commentRestrictError: {
                    ...isRestritError.commentRestrictError,
                    subLevelError: true,
                },
            });
        } else if (
            commentAccessMainValue === "restrict_user" &&
            commentRestrictValue === "select_from_list" &&
            (commentMainSelected?.selectedSubList?.length === 0 ||
                commentMainSelected?.selectedSubList === null)
        ) {
            setIsRestritError({
                ...isRestritError,
                commentRestrictError: {
                    ...isRestritError.commentRestrictError,
                    subListError: true,
                },
            });
        } else {
            if (fileUploadStatus) {
                if (durationT.durationValue) {
                    props.dispatch(
                        saveDraftPostStart({
                            content: editorHtmlContent,
                            amount: inputData.amount ? inputData.amount : "",
                            video_thubnail__posid:
                                durationT.videoId === undefined ? "" : durationT.videoId,
                            video_thubnail_sec:
                                durationT.durationValue === undefined
                                    ? ""
                                    : durationT.durationValue,
                            // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                            post_file_id: getAllId,
                            preview_file: inputData.preview_file
                                ? inputData.preview_file
                                : "",
                            category_ids: inputData.category_ids
                                ? inputData.category_ids
                                : [],
                            video_preview_file: inputData.video_preview_file
                                ? inputData.video_preview_file
                                : "",

                            is_mature: postContent ? 1 : 0,

                            access_control:
                                accessMainValue === "public"
                                    ? 1
                                    : accessMainValue === "restrict_user"
                                        ? 2
                                        : accessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            ac_follower_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            ac_follwing_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.following === true
                                        ? 1
                                        : 0,

                            ac_subscriber_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            ac_restrict_level_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubLevelId === undefined
                                        ? null
                                        : filtreAccessControlSubLevelId.toString(),

                            ac_friends_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            ac_restrict_friends_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFriendListId === undefined
                                        ? null
                                        : filtreAccessControlFriendListId.toString(),

                            ac_restrict_list_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubListId === undefined
                                        ? null
                                        : filtreAccessControlSubListId.toString(),

                            ac_restrict_follower_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFollowerListId === undefined
                                        ? null
                                        : filtreAccessControlFollowerListId.toString(),

                            download_type:
                                downloadAccessMainValue === "public"
                                    ? 1
                                    : downloadAccessMainValue === "restrict_user"
                                        ? 2
                                        : downloadAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            download_follower_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            download_following_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.following === true
                                        ? 1
                                        : 0,

                            download_subscriber_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,

                            download_restrict_level_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubLevelId === undefined
                                        ? null
                                        : filtreDownloadSubLevelId.toString(),

                            download_restrict_list_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubListId === undefined
                                        ? null
                                        : filtreDownloadSubListId.toString(),

                            download_restrict_follower_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFollowerListId === undefined
                                        ? null
                                        : filtreDownloadFollowerListId.toString(),

                            download_friends_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            download_restrict_friends_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFriendsListId === undefined
                                        ? null
                                        : filtreDownloadFriendsListId.toString(),

                            forward_type:
                                forwardAccessMainValue === "public"
                                    ? 1
                                    : forwardAccessMainValue === "restrict_user"
                                        ? 2
                                        : forwardAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            forward_follower_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            forward_following_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.following === true
                                        ? 1
                                        : 0,

                            forward_subscriber_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            forward_restrict_level_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubLevelId === undefined
                                        ? null
                                        : filtreForwardSubLevelId.toString(),

                            forward_restrict_list_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubListId === undefined
                                        ? null
                                        : filtreForwardSubListId.toString(),

                            forward_restrict_follower_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFollowerListId === undefined
                                        ? null
                                        : filtreForwardFollowerListId.toString(),

                            forward_friends_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            forward_restrict_friends_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFriendsListId === undefined
                                        ? null
                                        : filtreForwardFriendsListId.toString(),

                            comment_type:
                                commentAccessMainValue === "public"
                                    ? 1
                                    : commentAccessMainValue === "restrict_user"
                                        ? 2
                                        : commentAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            comment_follower_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            comment_following_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.following === true
                                        ? 1
                                        : 0,

                            comment_subscriber_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            comment_restrict_level_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubLevelId === undefined
                                        ? null
                                        : filtreCommentSubLevelId.toString(),

                            comment_restrict_list_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubListId === undefined
                                        ? null
                                        : filtreCommentSubListId.toString(),

                            comment_restrict_follower_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFollowerListId === undefined
                                        ? null
                                        : filtreCommentFollowerListId.toString(),

                            comment_friends_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            comment_restrict_friends_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFriendListId === undefined
                                        ? null
                                        : filtreCommentFriendListId.toString(),

                            tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                            bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                            like: multiCheckbox.LikeCheck === true ? 1 : 0,
                            report: multiCheckbox.reportCheck === true ? 1 : 0,
                            comment: multiCheckbox.commentCheck === true ? 1 : 0,
                        })
                    );
                } else {
                    props.dispatch(
                        saveDraftPostStart({
                            content: editorHtmlContent,
                            amount: inputData.amount ? inputData.amount : "",
                            // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                            post_file_id: getAllId,
                            preview_file: inputData.preview_file
                                ? inputData.preview_file
                                : "",
                            category_ids: inputData.category_ids
                                ? inputData.category_ids
                                : [],
                            video_preview_file: inputData.video_preview_file
                                ? inputData.video_preview_file
                                : "",
                            is_mature: postContent ? 1 : 0,

                            access_control:
                                accessMainValue === "public"
                                    ? 1
                                    : accessMainValue === "restrict_user"
                                        ? 2
                                        : accessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            ac_follower_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            ac_follwing_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.following === true
                                        ? 1
                                        : 0,

                            ac_subscriber_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            ac_restrict_level_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubLevelId === undefined
                                        ? null
                                        : filtreAccessControlSubLevelId.toString(),

                            ac_restrict_list_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubListId === undefined
                                        ? null
                                        : filtreAccessControlSubListId.toString(),

                            ac_restrict_follower_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFollowerListId === undefined
                                        ? null
                                        : filtreAccessControlFollowerListId.toString(),

                            ac_friends_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            ac_restrict_friends_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFriendListId === undefined
                                        ? null
                                        : filtreAccessControlFriendListId.toString(),

                            download_type:
                                downloadAccessMainValue === "public"
                                    ? 1
                                    : downloadAccessMainValue === "restrict_user"
                                        ? 2
                                        : downloadAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            download_follower_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            download_following_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.following === true
                                        ? 1
                                        : 0,

                            download_subscriber_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            download_restrict_level_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubLevelId === undefined
                                        ? null
                                        : filtreDownloadSubLevelId.toString(),

                            download_restrict_list_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubListId === undefined
                                        ? null
                                        : filtreDownloadSubListId.toString(),

                            download_restrict_follower_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFollowerListId === undefined
                                        ? null
                                        : filtreDownloadFollowerListId.toString(),

                            download_friends_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            download_restrict_friends_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFriendsListId === undefined
                                        ? null
                                        : filtreDownloadFriendsListId.toString(),

                            forward_type:
                                forwardAccessMainValue === "public"
                                    ? 1
                                    : forwardAccessMainValue === "restrict_user"
                                        ? 2
                                        : forwardAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            forward_follower_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            forward_following_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.following === true
                                        ? 1
                                        : 0,
                            forward_subscriber_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            forward_restrict_level_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubLevelId === undefined
                                        ? null
                                        : filtreForwardSubLevelId.toString(),

                            forward_restrict_list_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubListId === undefined
                                        ? null
                                        : filtreForwardSubListId.toString(),

                            forward_restrict_follower_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFollowerListId === undefined
                                        ? null
                                        : filtreForwardFollowerListId.toString(),

                            forward_friends_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            forward_restrict_friends_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFriendsListId === undefined
                                        ? null
                                        : filtreForwardFriendsListId.toString(),

                            comment_type:
                                commentAccessMainValue === "public"
                                    ? 1
                                    : commentAccessMainValue === "restrict_user"
                                        ? 2
                                        : commentAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            comment_follower_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            comment_following_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.following === true
                                        ? 1
                                        : 0,

                            comment_subscriber_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            comment_restrict_level_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubLevelId === undefined
                                        ? null
                                        : filtreCommentSubLevelId.toString(),

                            comment_restrict_list_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubListId === undefined
                                        ? null
                                        : filtreCommentSubListId.toString(),

                            comment_restrict_follower_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFollowerListId === undefined
                                        ? null
                                        : filtreCommentFollowerListId.toString(),

                            comment_friends_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            comment_restrict_friends_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFriendListId === undefined
                                        ? null
                                        : filtreCommentFriendListId.toString(),

                            tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                            bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                            like: multiCheckbox.LikeCheck === true ? 1 : 0,
                            report: multiCheckbox.reportCheck === true ? 1 : 0,
                            comment: multiCheckbox.commentCheck === true ? 1 : 0,
                        })
                    );
                }
            } else {
                props.dispatch(
                    saveDraftPostStart({
                        content: editorHtmlContent,
                        amount: inputData.amount ? inputData.amount : "",
                        category_ids: inputData.category_ids ? inputData.category_ids : [],

                        is_mature: postContent ? 1 : 0,

                        access_control:
                            accessMainValue === "public"
                                ? 1
                                : accessMainValue === "restrict_user"
                                    ? 2
                                    : accessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        ac_follower_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        ac_follwing_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.following === true
                                    ? 1
                                    : 0,

                        ac_subscriber_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,
                        ac_restrict_level_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlSubLevelId === undefined
                                    ? null
                                    : filtreAccessControlSubLevelId.toString(),

                        ac_restrict_list_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlSubListId === undefined
                                    ? null
                                    : filtreAccessControlSubListId.toString(),

                        ac_restrict_follower_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlFollowerListId === undefined
                                    ? null
                                    : filtreAccessControlFollowerListId.toString(),

                        ac_friends_only:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : accessRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        ac_restrict_friends_ids:
                            accessMainValue === "public" || accessMainValue === "no_one"
                                ? null
                                : filtreAccessControlFriendListId === undefined
                                    ? null
                                    : filtreAccessControlFriendListId.toString(),

                        download_type:
                            downloadAccessMainValue === "public"
                                ? 1
                                : downloadAccessMainValue === "restrict_user"
                                    ? 2
                                    : downloadAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        download_follower_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        download_following_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.following === true
                                    ? 1
                                    : 0,

                        download_subscriber_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,
                        download_restrict_level_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadSubLevelId === undefined
                                    ? null
                                    : filtreDownloadSubLevelId.toString(),

                        download_restrict_list_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadSubListId === undefined
                                    ? null
                                    : filtreDownloadSubListId.toString(),

                        download_restrict_follower_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadFollowerListId === undefined
                                    ? null
                                    : filtreDownloadFollowerListId.toString(),

                        download_friends_only:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : downloadRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        download_restrict_friends_ids:
                            downloadAccessMainValue === "public" ||
                            downloadAccessMainValue === "no_one"
                                ? null
                                : filtreDownloadFriendsListId === undefined
                                    ? null
                                    : filtreDownloadFriendsListId.toString(),

                        forward_type:
                            forwardAccessMainValue === "public"
                                ? 1
                                : forwardAccessMainValue === "restrict_user"
                                    ? 2
                                    : forwardAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        forward_follower_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        forward_following_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.following === true
                                    ? 1
                                    : 0,
                        forward_subscriber_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,
                        forward_restrict_level_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardSubLevelId === undefined
                                    ? null
                                    : filtreForwardSubLevelId.toString(),

                        forward_restrict_list_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardSubListId === undefined
                                    ? null
                                    : filtreForwardSubListId.toString(),

                        forward_restrict_follower_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardFollowerListId === undefined
                                    ? null
                                    : filtreForwardFollowerListId.toString(),

                        forward_friends_only:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : forwardRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        forward_restrict_friends_ids:
                            forwardAccessMainValue === "public" ||
                            forwardAccessMainValue === "no_one"
                                ? null
                                : filtreForwardFriendsListId === undefined
                                    ? null
                                    : filtreForwardFriendsListId.toString(),

                        comment_type:
                            commentAccessMainValue === "public"
                                ? 1
                                : commentAccessMainValue === "restrict_user"
                                    ? 2
                                    : commentAccessMainValue === "no_one"
                                        ? 3
                                        : 1,

                        comment_follower_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.follower === true
                                    ? 1
                                    : 0,

                        comment_following_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.following === true
                                    ? 1
                                    : 0,

                        comment_subscriber_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.subscriber_only === true
                                    ? 1
                                    : 0,
                        comment_restrict_level_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentSubLevelId === undefined
                                    ? null
                                    : filtreCommentSubLevelId.toString(),

                        comment_restrict_list_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentSubListId === undefined
                                    ? null
                                    : filtreCommentSubListId.toString(),

                        comment_restrict_follower_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentFollowerListId === undefined
                                    ? null
                                    : filtreCommentFollowerListId.toString(),

                        comment_friends_only:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : commentRestrictValue.friends === true
                                    ? 1
                                    : 0,

                        comment_restrict_friends_ids:
                            commentAccessMainValue === "public" ||
                            commentAccessMainValue === "no_one"
                                ? null
                                : filtreCommentFriendListId === undefined
                                    ? null
                                    : filtreCommentFriendListId.toString(),

                        tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                        bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                        like: multiCheckbox.LikeCheck === true ? 1 : 0,
                        report: multiCheckbox.reportCheck === true ? 1 : 0,
                        comment: multiCheckbox.commentCheck === true ? 1 : 0,
                    })
                );
                const notificationMessage = getErrorNotificationMessage(
                    t("please_upload_media_files")
                );
                props.dispatch(createNotification(notificationMessage));
            }
        }
    };

    const handleSubmitSchadual = (event) => {
        event.preventDefault();

        const filtreAccessControlSubLevelId =
            accessControlMainSelected.selectedSubLevel?.map((ele) => ele.id);
        const filtreAccessControlSubListSubscribers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreAccessControlSubListId =
            filtreAccessControlSubListSubscribers?.map((ele) => ele.id);
        const filtreAccessControlSubListFollowers =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreAccessControlFollowerListId =
            filtreAccessControlSubListFollowers?.map((ele) => ele.id);

        const filtreAccessControlSubListFriends =
            accessControlMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreAccessControlFriendListId =
            filtreAccessControlSubListFriends?.map((ele) => ele.id);

        const filtreDownloadSubLevelId = downloadMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListSubscribers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreDownloadSubListId = filtreDownloadSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFollowers =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreDownloadFollowerListId = filtreDownloadSubListFollowers?.map(
            (ele) => ele.id
        );
        const filtreDownloadSubListFriends =
            downloadMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreDownloadFriendsListId = filtreDownloadSubListFriends?.map(
            (ele) => ele.id
        );
        console.log(
            "🚀 ~ handleSubmitDraft ~ filtreDownloadFollowerListId:",
            filtreDownloadFollowerListId
        );

        const filtreForwardSubLevelId = forwardMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListSubscribers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreForwardSubListId = filtreForwardSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreForwardSubListFollowers =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreForwardFollowerListId = filtreForwardSubListFollowers?.map(
            (ele) => ele.id
        );

        const filtreForwardSubListFriends =
            forwardMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreForwardFriendsListId = filtreForwardSubListFriends?.map(
            (ele) => ele.id
        );

        const filtreCommentSubLevelId = commentMainSelected.selectedSubLevel?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListSubscribers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "subscriber"
            );
        const filtreCommentSubListId = filtreCommentSubListSubscribers?.map(
            (ele) => ele.id
        );
        const filtreCommentSubListFollowers =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "follower"
            );
        const filtreCommentFollowerListId = filtreCommentSubListFollowers?.map(
            (ele) => ele.id
        );

        const filtreCommentSubListFriends =
            commentMainSelected.selectedSubList?.filter(
                (ele) => ele.type === "friend"
            );
        const filtreCommentFriendListId = filtreCommentSubListFriends?.map(
            (ele) => ele.id
        );

        if (startDate !== null || isSingleSchedualData) {
            if (
                accessMainValue === "restrict_user" &&
                accessRestrictValue === "subscriber_level" &&
                (accessControlMainSelected?.selectedSubLevel?.length === 0 ||
                    accessControlMainSelected?.selectedSubLevel === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    accessRestrictError: {
                        ...isRestritError.accessRestrictError,
                        subLevelError: true,
                    },
                });
            } else if (
                accessMainValue === "restrict_user" &&
                accessRestrictValue === "select_from_list" &&
                (accessControlMainSelected?.selectedSubList?.length === 0 ||
                    accessControlMainSelected?.selectedSubList === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    accessRestrictError: {
                        ...isRestritError.accessRestrictError,
                        subListError: true,
                    },
                });
            } else if (
                downloadAccessMainValue === "restrict_user" &&
                downloadRestrictValue === "subscriber_level" &&
                (downloadMainSelected?.selectedSubLevel?.length === 0 ||
                    downloadMainSelected?.selectedSubLevel === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    downloadRestrictError: {
                        ...isRestritError.downloadRestrictError,
                        subLevelError: true,
                    },
                });
            } else if (
                downloadAccessMainValue === "restrict_user" &&
                downloadRestrictValue === "select_from_list" &&
                (downloadMainSelected?.selectedSubList?.length === 0 ||
                    downloadMainSelected?.selectedSubList === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    downloadRestrictError: {
                        ...isRestritError.downloadRestrictError,
                        subListError: true,
                    },
                });
            } else if (
                forwardAccessMainValue === "restrict_user" &&
                forwardRestrictValue === "subscriber_level" &&
                (forwardMainSelected?.selectedSubLevel?.length === 0 ||
                    forwardMainSelected?.selectedSubLevel === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    forwardRestrictError: {
                        ...isRestritError.forwardRestrictError,
                        subLevelError: true,
                    },
                });
            } else if (
                forwardAccessMainValue === "restrict_user" &&
                forwardRestrictValue === "select_from_list" &&
                (forwardMainSelected?.selectedSubList?.length === 0 ||
                    forwardMainSelected?.selectedSubList === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    forwardRestrictError: {
                        ...isRestritError.forwardRestrictError,
                        subListError: true,
                    },
                });
            } else if (
                commentAccessMainValue === "restrict_user" &&
                commentRestrictValue === "subscriber_level" &&
                (commentMainSelected?.selectedSubLevel?.length === 0 ||
                    commentMainSelected?.selectedSubLevel === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    commentRestrictError: {
                        ...isRestritError.commentRestrictError,
                        subLevelError: true,
                    },
                });
            } else if (
                commentAccessMainValue === "restrict_user" &&
                commentRestrictValue === "select_from_list" &&
                (commentMainSelected?.selectedSubList?.length === 0 ||
                    commentMainSelected?.selectedSubList === null)
            ) {
                setIsRestritError({
                    ...isRestritError,
                    commentRestrictError: {
                        ...isRestritError.commentRestrictError,
                        subListError: true,
                    },
                });
            } else {
                if (fileUploadStatus) {
                    if (durationT.durationValue) {
                        props.dispatch(
                            saveSchadualTimeStart({
                                content: editorHtmlContent,
                                amount: inputData.amount ? inputData.amount : "",
                                video_thubnail__posid:
                                    durationT.videoId === undefined ? "" : durationT.videoId,
                                video_thubnail_sec:
                                    durationT.durationValue === undefined
                                        ? ""
                                        : durationT.durationValue,
                                // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                                post_file_id: getAllId,
                                preview_file: inputData.preview_file
                                    ? inputData.preview_file
                                    : "",
                                category_ids: inputData.category_ids
                                    ? inputData.category_ids
                                    : [],
                                video_preview_file: inputData.video_preview_file
                                    ? inputData.video_preview_file
                                    : "",
                                is_mature: postContent ? 1 : 0,

                                access_control:
                                    accessMainValue === "public"
                                        ? 1
                                        : accessMainValue === "restrict_user"
                                            ? 2
                                            : accessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                ac_follower_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                ac_follwing_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.following === true
                                            ? 1
                                            : 0,

                                ac_subscriber_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                ac_restrict_level_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlSubLevelId === undefined
                                            ? null
                                            : filtreAccessControlSubLevelId.toString(),

                                ac_restrict_list_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlSubListId === undefined
                                            ? null
                                            : filtreAccessControlSubListId.toString(),

                                ac_restrict_follower_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlFollowerListId === undefined
                                            ? null
                                            : filtreAccessControlFollowerListId.toString(),

                                ac_friends_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                ac_restrict_friends_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlFriendListId === undefined
                                            ? null
                                            : filtreAccessControlFriendListId.toString(),

                                download_type:
                                    downloadAccessMainValue === "public"
                                        ? 1
                                        : downloadAccessMainValue === "restrict_user"
                                            ? 2
                                            : downloadAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                download_follower_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                download_following_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.following === true
                                            ? 1
                                            : 0,

                                download_subscriber_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,

                                download_restrict_level_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadSubLevelId === undefined
                                            ? null
                                            : filtreDownloadSubLevelId.toString(),

                                download_restrict_list_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadSubListId === undefined
                                            ? null
                                            : filtreDownloadSubListId.toString(),

                                download_restrict_follower_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadFollowerListId === undefined
                                            ? null
                                            : filtreDownloadFollowerListId.toString(),

                                download_friends_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                download_restrict_friends_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadFriendsListId === undefined
                                            ? null
                                            : filtreDownloadFriendsListId.toString(),

                                forward_type:
                                    forwardAccessMainValue === "public"
                                        ? 1
                                        : forwardAccessMainValue === "restrict_user"
                                            ? 2
                                            : forwardAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                forward_follower_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                forward_following_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.following === true
                                            ? 1
                                            : 0,

                                forward_subscriber_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                forward_restrict_level_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardSubLevelId === undefined
                                            ? null
                                            : filtreForwardSubLevelId.toString(),

                                forward_restrict_list_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardSubListId === undefined
                                            ? null
                                            : filtreForwardSubListId.toString(),

                                forward_restrict_follower_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardFollowerListId === undefined
                                            ? null
                                            : filtreForwardFollowerListId.toString(),

                                forward_friends_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                forward_restrict_friends_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardFriendsListId === undefined
                                            ? null
                                            : filtreForwardFriendsListId.toString(),

                                comment_type:
                                    commentAccessMainValue === "public"
                                        ? 1
                                        : commentAccessMainValue === "restrict_user"
                                            ? 2
                                            : commentAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                comment_follower_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                comment_following_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.following === true
                                            ? 1
                                            : 0,

                                comment_subscriber_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                comment_restrict_level_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentSubLevelId === undefined
                                            ? null
                                            : filtreCommentSubLevelId.toString(),

                                comment_restrict_list_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentSubListId === undefined
                                            ? null
                                            : filtreCommentSubListId.toString(),

                                comment_restrict_follower_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentFollowerListId === undefined
                                            ? null
                                            : filtreCommentFollowerListId.toString(),

                                comment_friends_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                comment_restrict_friends_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentFriendListId === undefined
                                            ? null
                                            : filtreCommentFriendListId.toString(),

                                tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                                bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                                like: multiCheckbox.LikeCheck === true ? 1 : 0,
                                report: multiCheckbox.reportCheck === true ? 1 : 0,
                                comment: multiCheckbox.commentCheck === true ? 1 : 0,

                                schedule_time: moment(startDate || isSingleSchedualData).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                                schedule_note: descriptionNoteS,
                                post_id: params?.schedualId || "",
                            })
                        );
                    } else {
                        props.dispatch(
                            saveSchadualTimeStart({
                                content: editorHtmlContent,
                                amount: inputData.amount ? inputData.amount : "",
                                // post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                                post_file_id: getAllId,
                                preview_file: inputData.preview_file
                                    ? inputData.preview_file
                                    : "",
                                category_ids: inputData.category_ids
                                    ? inputData.category_ids
                                    : [],
                                video_preview_file: inputData.video_preview_file
                                    ? inputData.video_preview_file
                                    : "",

                                is_mature: postContent ? 1 : 0,

                                access_control:
                                    accessMainValue === "public"
                                        ? 1
                                        : accessMainValue === "restrict_user"
                                            ? 2
                                            : accessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                ac_follower_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                ac_follwing_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.following === true
                                            ? 1
                                            : 0,

                                ac_subscriber_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                ac_restrict_level_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlSubLevelId === undefined
                                            ? null
                                            : filtreAccessControlSubLevelId.toString(),

                                ac_restrict_list_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlSubListId === undefined
                                            ? null
                                            : filtreAccessControlSubListId.toString(),

                                ac_restrict_follower_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlFollowerListId === undefined
                                            ? null
                                            : filtreAccessControlFollowerListId.toString(),

                                ac_friends_only:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : accessRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                ac_restrict_friends_ids:
                                    accessMainValue === "public" || accessMainValue === "no_one"
                                        ? null
                                        : filtreAccessControlFriendListId === undefined
                                            ? null
                                            : filtreAccessControlFriendListId.toString(),

                                download_type:
                                    downloadAccessMainValue === "public"
                                        ? 1
                                        : downloadAccessMainValue === "restrict_user"
                                            ? 2
                                            : downloadAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                download_follower_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                download_following_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.following === true
                                            ? 1
                                            : 0,

                                download_subscriber_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                download_restrict_level_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadSubLevelId === undefined
                                            ? null
                                            : filtreDownloadSubLevelId.toString(),

                                download_restrict_list_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadSubListId === undefined
                                            ? null
                                            : filtreDownloadSubListId.toString(),

                                download_restrict_follower_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadFollowerListId === undefined
                                            ? null
                                            : filtreDownloadFollowerListId.toString(),

                                download_friends_only:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : downloadRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                download_restrict_friends_ids:
                                    downloadAccessMainValue === "public" ||
                                    downloadAccessMainValue === "no_one"
                                        ? null
                                        : filtreDownloadFriendsListId === undefined
                                            ? null
                                            : filtreDownloadFriendsListId.toString(),

                                forward_type:
                                    forwardAccessMainValue === "public"
                                        ? 1
                                        : forwardAccessMainValue === "restrict_user"
                                            ? 2
                                            : forwardAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                forward_follower_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                forward_following_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.following === true
                                            ? 1
                                            : 0,
                                forward_subscriber_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                forward_restrict_level_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardSubLevelId === undefined
                                            ? null
                                            : filtreForwardSubLevelId.toString(),

                                forward_restrict_list_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardSubListId === undefined
                                            ? null
                                            : filtreForwardSubListId.toString(),

                                forward_restrict_follower_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardFollowerListId === undefined
                                            ? null
                                            : filtreForwardFollowerListId.toString(),

                                forward_friends_only:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : forwardRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                forward_restrict_friends_ids:
                                    forwardAccessMainValue === "public" ||
                                    forwardAccessMainValue === "no_one"
                                        ? null
                                        : filtreForwardFriendsListId === undefined
                                            ? null
                                            : filtreForwardFriendsListId.toString(),

                                comment_type:
                                    commentAccessMainValue === "public"
                                        ? 1
                                        : commentAccessMainValue === "restrict_user"
                                            ? 2
                                            : commentAccessMainValue === "no_one"
                                                ? 3
                                                : 1,

                                comment_follower_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.follower === true
                                            ? 1
                                            : 0,

                                comment_following_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.following === true
                                            ? 1
                                            : 0,

                                comment_subscriber_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.subscriber_only === true
                                            ? 1
                                            : 0,
                                comment_restrict_level_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentSubLevelId === undefined
                                            ? null
                                            : filtreCommentSubLevelId.toString(),

                                comment_restrict_list_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentSubListId === undefined
                                            ? null
                                            : filtreCommentSubListId.toString(),

                                comment_restrict_follower_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentFollowerListId === undefined
                                            ? null
                                            : filtreCommentFollowerListId.toString(),

                                comment_friends_only:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : commentRestrictValue.friends === true
                                            ? 1
                                            : 0,

                                comment_restrict_friends_ids:
                                    commentAccessMainValue === "public" ||
                                    commentAccessMainValue === "no_one"
                                        ? null
                                        : filtreCommentFriendListId === undefined
                                            ? null
                                            : filtreCommentFriendListId.toString(),

                                tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                                bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                                like: multiCheckbox.LikeCheck === true ? 1 : 0,
                                report: multiCheckbox.reportCheck === true ? 1 : 0,
                                comment: multiCheckbox.commentCheck === true ? 1 : 0,

                                schedule_time:
                                    moment(startDate).format("YYYY-MM-DD HH:mm:ss") || "",
                                schedule_note: descriptionNoteS,
                                post_id: params?.schedualId || "",
                            })
                        );
                    }
                } else {
                    props.dispatch(
                        saveSchadualTimeStart({
                            content: editorHtmlContent,
                            amount: inputData.amount ? inputData.amount : "",
                            category_ids: inputData.category_ids
                                ? inputData.category_ids
                                : [],

                            is_mature: postContent ? 1 : 0,

                            access_control:
                                accessMainValue === "public"
                                    ? 1
                                    : accessMainValue === "restrict_user"
                                        ? 2
                                        : accessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            ac_follower_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            ac_follwing_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.following === true
                                        ? 1
                                        : 0,

                            ac_subscriber_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            ac_restrict_level_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubLevelId === undefined
                                        ? null
                                        : filtreAccessControlSubLevelId.toString(),

                            ac_restrict_list_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlSubListId === undefined
                                        ? null
                                        : filtreAccessControlSubListId.toString(),

                            ac_restrict_follower_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFollowerListId === undefined
                                        ? null
                                        : filtreAccessControlFollowerListId.toString(),

                            ac_friends_only:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : accessRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            ac_restrict_friends_ids:
                                accessMainValue === "public" || accessMainValue === "no_one"
                                    ? null
                                    : filtreAccessControlFriendListId === undefined
                                        ? null
                                        : filtreAccessControlFriendListId.toString(),

                            download_type:
                                downloadAccessMainValue === "public"
                                    ? 1
                                    : downloadAccessMainValue === "restrict_user"
                                        ? 2
                                        : downloadAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            download_follower_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            download_following_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.following === true
                                        ? 1
                                        : 0,

                            download_subscriber_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            download_restrict_level_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubLevelId === undefined
                                        ? null
                                        : filtreDownloadSubLevelId.toString(),

                            download_restrict_list_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadSubListId === undefined
                                        ? null
                                        : filtreDownloadSubListId.toString(),

                            download_restrict_follower_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFollowerListId === undefined
                                        ? null
                                        : filtreDownloadFollowerListId.toString(),

                            download_friends_only:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : downloadRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            download_restrict_friends_ids:
                                downloadAccessMainValue === "public" ||
                                downloadAccessMainValue === "no_one"
                                    ? null
                                    : filtreDownloadFriendsListId === undefined
                                        ? null
                                        : filtreDownloadFriendsListId.toString(),

                            forward_type:
                                forwardAccessMainValue === "public"
                                    ? 1
                                    : forwardAccessMainValue === "restrict_user"
                                        ? 2
                                        : forwardAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            forward_follower_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            forward_following_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.following === true
                                        ? 1
                                        : 0,
                            forward_subscriber_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            forward_restrict_level_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubLevelId === undefined
                                        ? null
                                        : filtreForwardSubLevelId.toString(),

                            forward_restrict_list_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardSubListId === undefined
                                        ? null
                                        : filtreForwardSubListId.toString(),

                            forward_restrict_follower_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFollowerListId === undefined
                                        ? null
                                        : filtreForwardFollowerListId.toString(),

                            forward_friends_only:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : forwardRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            forward_restrict_friends_ids:
                                forwardAccessMainValue === "public" ||
                                forwardAccessMainValue === "no_one"
                                    ? null
                                    : filtreForwardFriendsListId === undefined
                                        ? null
                                        : filtreForwardFriendsListId.toString(),

                            comment_type:
                                commentAccessMainValue === "public"
                                    ? 1
                                    : commentAccessMainValue === "restrict_user"
                                        ? 2
                                        : commentAccessMainValue === "no_one"
                                            ? 3
                                            : 1,

                            comment_follower_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.follower === true
                                        ? 1
                                        : 0,

                            comment_following_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.following === true
                                        ? 1
                                        : 0,

                            comment_subscriber_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.subscriber_only === true
                                        ? 1
                                        : 0,
                            comment_restrict_level_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubLevelId === undefined
                                        ? null
                                        : filtreCommentSubLevelId.toString(),

                            comment_restrict_list_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentSubListId === undefined
                                        ? null
                                        : filtreCommentSubListId.toString(),

                            comment_restrict_follower_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFollowerListId === undefined
                                        ? null
                                        : filtreCommentFollowerListId.toString(),

                            comment_friends_only:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : commentRestrictValue.friends === true
                                        ? 1
                                        : 0,

                            comment_restrict_friends_ids:
                                commentAccessMainValue === "public" ||
                                commentAccessMainValue === "no_one"
                                    ? null
                                    : filtreCommentFriendListId === undefined
                                        ? null
                                        : filtreCommentFriendListId.toString(),

                            tips: multiCheckbox.tipsCheck === true ? 1 : 0,
                            bookmark: multiCheckbox.bookMarkCheck === true ? 1 : 0,
                            like: multiCheckbox.LikeCheck === true ? 1 : 0,
                            report: multiCheckbox.reportCheck === true ? 1 : 0,
                            comment: multiCheckbox.commentCheck === true ? 1 : 0,

                            schedule_time:
                                moment(startDate).format("YYYY-MM-DD HH:mm:ss") || "",
                            schedule_note: descriptionNoteS,
                            post_id: params?.schedualId || "",
                        })
                    );
                    const notificationMessage = getErrorNotificationMessage(
                        t("please_upload_media_files")
                    );
                    props.dispatch(createNotification(notificationMessage));
                }
            }
        } else {
            setIsStartDateError(true);
        }
    };

    const setValues = (inputValue) => {
        let user_id_arr = [];
        inputValue.map((value, i) => {
            user_id_arr.push(value.category_id);
        });
        setInputData({
            ...inputData,
            category_ids: user_id_arr,
        });
    };

    const handleVideopreviewImage = (event, fileType = "image") => {
        let data_array = [];

        [...event.target.files].forEach((file, key) => {
            let name = "file[" + key + "]";

            data_array[name] = file;
        });
        data_array["file_type"] = fileType;
        data_array["file"] = event.currentTarget.files[0];
        data_array["upload_type"] = "tumbnail";
        data_array["post_tumbnail_id"] = getAllId;

        // console.log("🚀 ~ handleVideopreviewImage ~ fileType:", fileType);
        // console.log(data_array, "data_array");

        setPreviewImage(true);
        setFileUploadStatus(true);
        setPaidPost(true);
        // setDisableVideo(true);
        setDisableAudio(true);
        props.dispatch(
            postFileThumbnailUploadStart({
                data: data_array,
                for: "video",
                setVideoThumbnail,
                videoThumbnail,
                setDurationT,
            })
        );

        // if (event.currentTarget.type === "file") {
        //   setFileUploadStatus(true);
        //   let reader = new FileReader();
        //   let file = event.currentTarget.files[0];
        //   reader.onloadend = () => {
        //     setVideoThumbnail({
        //       ...videoThumbnail,
        //       // file : file,
        //       videoPreviewImage: reader.result,
        //     });
        //   };

        //   if (file) {
        //     reader.readAsDataURL(file);
        //     setDurationT({
        //       durationValue: "",
        // videoId: "",
        //     })
        //   }

        //   setInputData({
        //     ...inputData,
        //     preview_file: file,
        //   });
        // }
    };

    const handleVideoPreview = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setVideoPreview({...videoPreview, previewVideo: reader.result});
            };

            if (file) {
                reader.readAsDataURL(file);
            }

            setInputData({
                ...inputData,
                video_preview_file: file,
            });
        }
    };

    //  Audio handle preview image

    const handleAudiopreviewImage = (event, fileType = "image") => {
        let data_array = [];

        let singleFile = event.currentTarget.files[0];

        // [...event.target.files].forEach((file, key) => {
        //   let name = "file[" + key + "]";

        //   data_array[name] = file;
        // });
        data_array["file_type"] = fileType;
        data_array["file"] = singleFile;
        data_array["upload_type"] = "tumbnail";
        data_array["post_tumbnail_id"] = getAllId;

        setFileUploadStatus(true);
        setPaidPost(true);
        setDisableVideo(true);
        // setDisableAudio(true);
        props.dispatch(
            postFileThumbnailUploadStart({
                data: data_array,
                for: "audio",
                setAudioThumbnail,
                audioThumbnail,
            })
        );

        // if (event.currentTarget.type === "file") {
        //   setFileUploadStatus(true);
        //   let reader = new FileReader();
        //   let file = event.currentTarget.files[0];
        //   reader.onloadend = () => {
        //     setAudioThumbnail({
        //       ...audioThumbnail,
        //       file: reader.result,
        //     });
        //   };
        //   if (file) {
        //     reader.readAsDataURL(file);
        //   }
        //   setInputData({
        //     ...inputData,
        //     preview_file: file,
        //   });
        // }
    };

    const handleAudioPreview = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setAudioPreview({...audioPreview, previewAudio: reader.result});
            };
            if (file) {
                reader.readAsDataURL(file);
            }
            setInputData({
                ...inputData,
                video_preview_file: file,
            });
        }
    };

    const handleAccessChange = (e, type) => {
        setIsRestritError({
            accessRestrictError: {subLevelError: false, subListError: false},
            downloadRestrictError: {subLevelError: false, subListError: false},
            forwardRestrictError: {subLevelError: false, subListError: false},
            commentRestrictError: {subLevelError: false, subListError: false},
        });

        if (type === "mainAccessControl") {
            if (e.target.value !== "restrict_user") {
                setAccessRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });

                setDownloadRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });

                setForwardRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });

                setCommentRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });
            }
            setAccessMainValue(e.target.value);

            setDownloadAccessMainValue(e.target.value);
            setForwardAccessMainValue(e.target.value);
            setCommentAccessMainValue(e.target.value);

            setAccessControlMainSelected({
                selectedSubList: null,
                selectedSubLevel: null,
            });

            setDownloadMainSelected({
                selectedSubList: null,
                selectedSubLevel: null,
            });

            setForwardMainSelected({selectedSubList: null, selectedSubLevel: null});

            setCommentMainSelected({selectedSubList: null, selectedSubLevel: null});
        } else if (type === "whoCanDownload") {
            if (e.target.value !== "restrict_user") {
                // setDownloadRestrictValue("following");
                setDownloadRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });
            }
            setDownloadMainSelected({
                selectedSubList: null,
                selectedSubLevel: null,
            });
            setDownloadAccessMainValue(e.target.value);
        } else if (type === "whoCanForward") {
            if (e.target.value !== "restrict_user") {
                setForwardRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });
            }
            setForwardMainSelected({selectedSubList: null, selectedSubLevel: null});
            setForwardAccessMainValue(e.target.value);
        } else if (type === "whoCanComment") {
            if (e.target.value !== "restrict_user") {
                setCommentRestrictValue({
                    follower: false,
                    following: false,
                    subscriber_only: false,
                    subscriber_level: false,
                    subscriber_list: false,
                });
            }
            setCommentMainSelected({selectedSubList: null, selectedSubLevel: null});
            setCommentAccessMainValue(e.target.value);
        }
    };

    const handleAccessRestrictChange = (e, type, name) => {
        setIsRestritError({
            accessRestrictError: {subLevelError: false, subListError: false},
            downloadRestrictError: {subLevelError: false, subListError: false},
            forwardRestrictError: {subLevelError: false, subListError: false},
            commentRestrictError: {subLevelError: false, subListError: false},
        });

        if (type === "mainAccessSubControl") {
            // setAccessRestrictValue(e.target.value);

            setAccessRestrictValue({
                ...accessRestrictValue,
                [name]: e.target.checked,
            });

            setDownloadRestrictValue({
                ...downloadRestrictValue,
                [name]: e.target.checked,
            });

            setForwardRestrictValue({
                ...forwardRestrictValue,
                [name]: e.target.checked,
            });

            setCommentRestrictValue({
                ...commentRestrictValue,
                [name]: e.target.checked,
            });

            if (!accessRestrictValue.subscriber_level) {
                setAccessControlMainSelected({
                    ...accessControlMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!accessRestrictValue.subscriber_list) {
                setAccessControlMainSelected({
                    ...accessControlMainSelected,
                    selectedSubList: null,
                });
            }

            if (!downloadRestrictValue.subscriber_level) {
                setDownloadMainSelected({
                    ...downloadMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!downloadRestrictValue.subscriber_list) {
                setDownloadMainSelected({
                    ...downloadMainSelected,
                    selectedSubList: null,
                });
            }

            if (!forwardRestrictValue.subscriber_level) {
                setForwardMainSelected({
                    ...forwardMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!forwardRestrictValue.subscriber_list) {
                setForwardMainSelected({
                    ...forwardMainSelected,
                    selectedSubList: null,
                });
            }

            if (!commentRestrictValue.subscriber_level) {
                setCommentMainSelected({
                    ...commentMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!commentRestrictValue.subscriber_list) {
                setCommentMainSelected({
                    ...commentMainSelected,
                    selectedSubList: null,
                });
            }
        } else if (type === "whoCanDownloadSub") {
            // setDownloadRestrictValue(e.target.value);
            setDownloadRestrictValue({
                ...downloadRestrictValue,
                [name]: e.target.checked,
            });

            // setDownloadMainSelected({
            //   selectedSubList: null,
            //   selectedSubLevel: null,
            // });

            if (!downloadRestrictValue.subscriber_level) {
                setDownloadMainSelected({
                    ...downloadMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!downloadRestrictValue.subscriber_list) {
                setDownloadMainSelected({
                    ...downloadMainSelected,
                    selectedSubList: null,
                });
            }
        } else if (type === "whoCanForwardSub") {
            // setForwardRestrictValue(e.target.value);
            setForwardRestrictValue({
                ...forwardRestrictValue,
                [name]: e.target.checked,
            });

            if (!forwardRestrictValue.subscriber_level) {
                setForwardMainSelected({
                    ...forwardMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!forwardRestrictValue.subscriber_list) {
                setForwardMainSelected({
                    ...forwardMainSelected,
                    selectedSubList: null,
                });
            }
        } else if (type === "whoCanCommentSub") {
            // setCommentRestrictValue(e.target.value);
            setCommentRestrictValue({
                ...commentRestrictValue,
                [name]: e.target.checked,
            });

            if (!commentRestrictValue.subscriber_level) {
                setCommentMainSelected({
                    ...commentMainSelected,
                    selectedSubLevel: null,
                });
            }

            if (!commentRestrictValue.subscriber_list) {
                setCommentMainSelected({
                    ...commentMainSelected,
                    selectedSubList: null,
                });
            }
            // setCommentMainSelected({ selectedSubList: null, selectedSubLevel: null });
        }
    };

    useEffect(() => {
        props.dispatch(getSubscriptionLevelsStart({setSubLevelOptions}));
    }, []);

    useEffect(() => {
        if (
            accessRestrictValue.subscriber_list ||
            downloadRestrictValue.subscriber_list ||
            forwardRestrictValue.subscriber_list ||
            commentRestrictValue.subscriber_list
        ) {
            props.dispatch(
                getUserSubscribersStart({
                    setSubListsOptions,
                    listGroupedOp,
                    setListGroupedOp,
                    accessRestrictValue,
                    setAccessRestrictValue,
                    downloadRestrictValue,
                    setDownloadRestrictValue,
                    forwardRestrictValue,
                    setFileUploadStatus,
                })
            );
        }
    }, [
        accessRestrictValue,
        downloadRestrictValue,
        forwardRestrictValue,
        commentRestrictValue,
    ]);

    // for subscriber level select list

    const Option = (props) => {
        return (
            <div className="post-level-list-option-wrpapper">
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                    <div className="post-level-list-option-img-label-wrapper">
                        {props.data.avatar && (
                            <img
                                src={props.data.avatar || props.avatar}
                                alt={props?.label}
                                style={{
                                    width: 26,
                                    height: 26,
                                    borderRadius: "50%",
                                    marginRight: 8,
                                }}
                            />
                        )}
                        <label style={{marginBottom: "0px"}}>{props.label}</label>
                    </div>
                </components.Option>
            </div>
        );
    };

    const MoreSelectedBadge = ({items}) => {
        const style = {
            marginLeft: "auto",
            // background: "#d4eefa",
            borderRadius: "4px",
            fontSize: "12px",
            fontWeight: "400",
            padding: "3px",
            // order: 99,           <-- if you want this after input serach right side then uncomment
            order: 0,
        };

        const title = items.join(", ");
        const length = items.length;
        // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
        const label = `+ ${length} more...`;

        return (
            <div style={style} title={title}>
                {label}
            </div>
        );
    };

    const MultiValue = ({index, getValue, ...props}) => {
        // debugger;
        console.log(
            "🚀 ~ MultiValue ~ index:",
            index,
            props.data.label,
            getValue()
        );
        const maxToShow = 3;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);

        return index < maxToShow ? (
            <components.MultiValue {...props}>
        <span style={{fontSize: "12px"}}>
          {props.data.avatar && (
              <img
                  style={{
                      width: 26,
                      height: 26,
                      borderRadius: "50%",
                      marginRight: 5,
                  }}
                  src={props.data.avatar}
                  alt={props.data.label}
              />
          )}

            {props.data.label}
        </span>
            </components.MultiValue>
        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow}/>
        ) : null;
    };

    const handleSetSelectedSubLevel = (selected) => {
        setIsRestritError({
            ...isRestritError,
            accessRestrictError: {subLevelError: false, subListError: false},
            downloadRestrictError: {subLevelError: false, subListError: false},
            forwardRestrictError: {subLevelError: false, subListError: false},
            commentRestrictError: {subLevelError: false, subListError: false},
        });

        setAccessControlMainSelected({
            ...accessControlMainSelected,
            selectedSubLevel: selected,
        });

        setDownloadMainSelected({
            ...downloadMainSelected,
            selectedSubLevel: selected,
        });

        setForwardMainSelected({
            ...forwardMainSelected,
            selectedSubLevel: selected,
        });

        setCommentMainSelected({
            ...commentMainSelected,
            selectedSubLevel: selected,
        });
    };

    const handleSetSelectedSubList = (selected) => {
        debugger;
        setIsRestritError({
            ...isRestritError,
            accessRestrictError: {subLevelError: false, subListError: false},
            downloadRestrictError: {subLevelError: false, subListError: false},
            forwardRestrictError: {subLevelError: false, subListError: false},
            commentRestrictError: {subLevelError: false, subListError: false},
        });
        // setSelectedSubList(selected);
        setAccessControlMainSelected({
            ...accessControlMainSelected,
            selectedSubList: selected,
        });

        setDownloadMainSelected({
            ...downloadMainSelected,
            selectedSubList: selected,
        });

        setForwardMainSelected({
            ...forwardMainSelected,
            selectedSubList: selected,
        });

        setCommentMainSelected({
            ...commentMainSelected,
            selectedSubList: selected,
        });
    };

    const handleWhoCanDownloadLevelSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            downloadRestrictError: {subLevelError: false, subListError: false},
        });
        setDownloadMainSelected({
            ...downloadMainSelected,
            selectedSubLevel: selected,
        });
    };

    const handleWhoCanDownloadListSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            downloadRestrictError: {subLevelError: false, subListError: false},
        });
        setDownloadMainSelected({
            ...downloadMainSelected,
            selectedSubList: selected,
        });
    };

    const handleWhoCanForwardLevelSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            forwardRestrictError: {subLevelError: false, subListError: false},
        });
        setForwardMainSelected({
            ...forwardMainSelected,
            selectedSubLevel: selected,
        });
    };

    const handleWhoCanForwardListSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            forwardRestrictError: {subLevelError: false, subListError: false},
        });
        setForwardMainSelected({
            ...forwardMainSelected,
            selectedSubList: selected,
        });
    };

    const handleWhoCanCommentLevelSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            commentRestrictError: {subLevelError: false, subListError: false},
        });
        setCommentMainSelected({
            ...commentMainSelected,
            selectedSubLevel: selected,
        });
    };

    const handleWhoCanCommentListSelected = (selected) => {
        setIsRestritError({
            ...isRestritError,
            commentRestrictError: {subLevelError: false, subListError: false},
        });
        setCommentMainSelected({
            ...commentMainSelected,
            selectedSubList: selected,
        });
    };

    const handleMultiCheckbox = (e) => {
        setMultiCheckbox({...multiCheckbox, [e.target.name]: e.target.checked});
    };

    const filterImageUpdate = (filterData) => {
        props.dispatch(postFilterUploadStart(filterData));
    };

    // useEffect(()=>{

    //   const filtreAccessGroupData = ()=>{

    //     if(accessRestrictValue.follower){

    //       setListGroupedOp(
    //          {
    //           ...listGroupedOp,
    //           accessRestrictGropOpt : [
    //             {
    //               label: 'Subscribers',
    //               options: listGroupedOp.accessRestrictGropOpt[0]?.options,
    //             },

    //           ]
    //          }
    //      )

    //      }else if(accessRestrictValue.subscriber_only){

    //       setListGroupedOp(
    //         {
    //          ...listGroupedOp,
    //          accessRestrictGropOpt : [
    //           {
    //             label: 'Followers',
    //             options: listGroupedOp.accessRestrictGropOpt[1]?.options,
    //           },
    //         ]
    //         }
    //     )

    //      }else if(accessRestrictValue.follower && accessRestrictValue.subscriber_only){
    //       setListGroupedOp(
    //         {
    //          ...listGroupedOp,
    //          accessRestrictGropOpt : [ ]
    //         }
    //     )
    //      }else{

    //       setListGroupedOp(
    //         {
    //          ...listGroupedOp,
    //          accessRestrictGropOpt : [
    //           {
    //             label: 'Subscribers',
    //             options: listGroupedOp.accessRestrictGropOpt[0]?.options,
    //           },
    //           {
    //             label: 'Followers',
    //             options: listGroupedOp.accessRestrictGropOpt[1]?.options,
    //           },

    //         ]
    //         }
    //     )

    //      }

    //   }

    //   filtreAccessGroupData();
    // },[])

    console.log(
        "🚀 ~ handleAccessListGroupOptions ~ handleAccessListGroupOptions:",
        listGroupedOp
    );

    return (
        <div className="notification-page create-post" id="tabs">
            <Container>
                {localStorage.getItem("is_content_creator") == 2 ? (
                    <div className="create-post-box">
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="post-create-header">
                                        <div className="pull-left">
                                            <Link
                                                className="bookmarkes-list notify-title"
                                                to={
                                                    params?.id
                                                        ? "/draft-posts"
                                                        : params?.schedualId
                                                            ? "/save-schedule-posts"
                                                            : "/home"
                                                }
                                            >
                                                <Image
                                                    src={
                                                        window.location.origin +
                                                        "/assets/images/icons/back.svg"
                                                    }
                                                    className="svg-clone"
                                                />
                                                {params?.id
                                                    ? t("draft_post")
                                                    : params?.schedualId
                                                        ? "SCHEDUAL POST"
                                                        : t("new_post")}
                                            </Link>
                                        </div>
                                        <div
                                            className="pull-right"
                                            style={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                gap: "17px",
                                            }}
                                        >
                                            {/* {data?.is_content_creator === 2 &&
                        data?.is_certified_content_creator === 1 && (
                          <Form.Control
                            value={creatorAccessValue}
                            name="creatorType"
                            onChange={(e) =>
                              handleCreatorTypeChange(e, "creator")
                            }
                            size="sm"
                            as="select"
                            style={{
                              width: "fit-content",
                              border: "1px solid silver !important",
                            }}
                          >
                            {accessTypeCreator.map((type) => {
                              return (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              );
                            })}
                          </Form.Control>
                        )} */}

                                            {/* {data?.is_certified_content_creator === 2 && (
                        <Form.Control
                          value={certifiedCreatorAccessValue}
                          name="certifiedCreatorType"
                          onChange={(e) =>
                            handleCreatorTypeChange(e, "certified")
                          }
                          size="sm"
                          as="select"
                          style={{
                            width: "fit-content",
                            border: "1px solid silver !important",
                          }}
                        >
                          {accessTypeCertifiedCreator.map((type) => {
                            return (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            );
                          })}
                        </Form.Control>
                      )} */}

                                            {/* {localStorage.getItem("is_content_creator") == 2 ? (
                        <Button
                          type="submit"
                          className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                          onClick={handleSubmit}
                          disabled={
                            props.fileUpload.buttonDisable ||
                            props.savePost.buttonDisable
                          }
                        >
                          {props.fileUpload.loadingButtonContent !== null
                            ? props.fileUpload.loadingButtonContent
                            : props.savePost.loadingButtonContent !== null
                            ? props.savePost.loadingButtonContent
                            : t("post")}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                          disabled="true"
                        >
                          {t("post")}
                        </Button>
                      )} */}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="create-post-textarea"></div>
                                    <div className="searchMentions">
                                        <div className="PostEditor">
                                            {/* <PostEditor
                        className="PostEditor__input"
                        placeholder={t("new_post_placeholder")}
                        ref={mentionsRef}
                        getEditorRawContent={setEditorContentstate}
                        getEditorHtmlContent={setEditorHtmlContent}
                        dispatch={props.dispatch}
                        searchUser={props.searchUser}
                        singleDPost={singleDPost}

                      /> */}
                                            <PostEditorWithValue
                                                className="PostEditor__input"
                                                placeholder={t("new_post_placeholder")}
                                                ref={mentionsRef}
                                                getEditorRawContent={setEditorContentstate}
                                                getEditorHtmlContent={setEditorHtmlContent}
                                                dispatch={props.dispatch}
                                                searchUser={props.searchUser}
                                                singleDPost={singleDPost}
                                                editorState={editorState}
                                                setEditorState={setEditorState}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                                    {props.postCategories.data.post_categories &&
                                    props.postCategories.data.post_categories.length > 0 ? (
                                        <>
                                            <Form.Group className="mb-0">
                                                <Form.Label className="edit-input-label mb-3 mb-lg-3">
                                                    {t("category")} ({t("optional")})
                                                </Form.Label>
                                                {props.postCategories.data.post_categories ? (
                                                    <Multiselect
                                                        name="category_ids"
                                                        selectedValues={isCategoriesSelected}
                                                        options={props.postCategories.data.post_categories}
                                                        displayValue="name"
                                                        avoidHighlightFirstOption="true"
                                                        placeholder={t("choose_category")}
                                                        onSelect={(values) => setValues(values)}
                                                    />
                                                ) : null}
                                            </Form.Group>

                                            {/* <div style={{marginTop: "13px"}}>
                        <Form.Label className="edit-input-label mb-3 mb-lg-3">
                          Post Content
                        </Form.Label>
                        <div
                          className="notification-toggle-container"
                        >
                          <div className="toggle-switch" style={{justifyContent: "flex-start"}} >
                            <div>
                              <span style={{fontSize: "14px"}}>Mature</span>
                            </div>
                            <label
                              className="switchL switch-label"
                              style={{ border: "none" , fontSize: "11px" }}
                            >
                              <input
                                type="checkbox"
                                className="fullAccess"
                                name="post_content"
                                onChange={(e)=> {
                                  setPostContent(e.target.checked)
                                } }
                              />
                              <span className="sliderL"></span>
                            </label>
                          </div>
                        </div>

                      </div> */}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {/*data.is_certified_content_creator && data.is_certified_content_creator == 2 && paidPost == true ? (*/}
                                    {/* {paidPost == true ? (
                    <Form.Group className="md-mrg-btm mt-3 mt-lg-4">
                      <label className="text-muted m-1 mb-3 mb-lg-3">
                        {t("price")} ({t("optional")})
                      </label>
                      <Form.Control
                        type="number"
                        placeholder={t("price_placeholder")}
                        name="amount"
                        pattern="[0-9]*"
                        min="1"
                        inputmode="numeric"
                        value={inputData.amount}
                        width="50%"
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            amount: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )} */}

                                    {data.is_certified_content_creator &&
                                    data.is_certified_content_creator == 2 &&
                                    paidPost == true ? (
                                        paidPost == true ? (
                                            <Form.Group className="md-mrg-btm mt-3 mt-lg-4">
                                                <label className="text-muted m-1 mb-3 mb-lg-3">
                                                    {t("price")} ({t("optional")})
                                                </label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder={t("price_placeholder")}
                                                    name="amount"
                                                    pattern="[0-9]*"
                                                    min="1"
                                                    inputmode="numeric"
                                                    value={inputData.amount}
                                                    width="50%"
                                                    onChange={(event) =>
                                                        setInputData({
                                                            ...inputData,
                                                            amount: event.currentTarget.value,
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}

                                    {videoThumbnailStatus === true &&
                                    postFileData?.length > 0 &&
                                    disableAudio &&
                                    disableImage ? (
                                        <>
                                            <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                                                <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                                                    {t("upload_video_thumbnail")}
                                                </label>
                                                <Form.Control
                                                    style={{display: "block"}}
                                                    type="file"
                                                    placeholder={t("upload_video_thumbnail_placeholder")}
                                                    name="preview_file"
                                                    width="50%"
                                                    ref={inputFileVideoThumb}
                                                    className="form-control"
                                                    accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                                    onChange={(event) => {
                                                        handleVideopreviewImage(event);
                                                    }}
                                                />
                                            </Form.Group>

                                            {videoThumbnail.file !== "" ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <Link
                                                            to="#"
                                                            onClick={(event) =>
                                                                handleRemoveThubnail(
                                                                    event,
                                                                    videoThumbnail,
                                                                    "video"
                                                                )
                                                            }
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "19px",
                                                                zIndex: "999",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa-times-circle"
                                                                style={{color: "red"}}
                                                            ></i>
                                                        </Link>
                                                        <Link
                                                            to="#"
                                                            onClick={(event) =>
                                                                handleOpenFilterModal(event, videoThumbnail)
                                                            }
                                                            style={{
                                                                position: "absolute",
                                                                top: "26px",
                                                                zIndex: "999",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa fa-magic"
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "16px",
                                                                    top: "-3px",
                                                                }}
                                                            ></i>
                                                        </Link>
                                                        <div className="post-img-preview-sec m-0">
                                                            <Image
                                                                alt="#"
                                                                src={videoThumbnail.file}
                                                                className="post-video-preview"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {/* {postFileData?.[0]?.durationsArray?.length > 0 && (
                        <div className="durationDataWrapper">
                          {postFileData?.map((post, index) => {
                            return (
                              <React.Fragment key={index}>
                                {post?.durationsArray?.map(
                                  (duration, index) => {
                                    return (
                                      <div
                                        className="durationListItem"
                                        key={index}
                                      >
                                        <div className="durationListItemRadioSec">
                                          <span>{duration}</span>
                                          <input type="radio" name="duration" />
                                        </div>

                                        <video
                                          width={300}
                                          height={300}
                                          controls={false}
                                        >
                                          <source
                                            src={`${post?.file}#t=${duration}`}
                                          />
                                        </video>
                                      </div>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )} */}

                                            {videoPreviewUrl &&
                                            disableAudio === true &&
                                            disableImage === true &&
                                            postFileData ? (
                                                <Row>
                                                    {
                                                        <Col
                                                            sm={12}
                                                            md={12}
                                                            key={postFileData[0].post_file_id}
                                                            style={{marginTop: "10px"}}
                                                        >
                                                            {/* <label
                                className="thubmnailHeading"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#6c757d",
                                  marginTop: "20px",
                                }}
                              >Default video thumbnail</label> */}

                                                            {postFileData?.[0]?.durationsArray?.length >
                                                                0 && (
                                                                    <div
                                                                        className="durationDataWrapper"
                                                                        style={{
                                                                            display: "flex",
                                                                            flexWrap: "wrap",
                                                                            gap: "15px",
                                                                        }}
                                                                    >
                                                                        {postFileData?.[0].durationsArray?.map(
                                                                            (duration, index) => {
                                                                                return (
                                                                                    <div
                                                                                        className="durationListItem"
                                                                                        key={index}
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column-reverse",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="durationListItemRadioSec"
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                gap: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <input
                                                                                                type="radio"
                                                                                                name="duration"
                                                                                                value={duration}
                                                                                                checked={
                                                                                                    durationT.durationValue ==
                                                                                                    duration
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    setDurationT({
                                                                                                        durationValue: e.target.value,
                                                                                                        videoId:
                                                                                                        postFileData?.[0]
                                                                                                            ?.post_file_id,
                                                                                                    })
                                                                                                }
                                                                                                style={{margin: "0px"}}
                                                                                            />
                                                                                            <span
                                                                                                style={{fontSize: "17px"}}>
                                              Default thumbnail
                                            </span>
                                                                                        </div>

                                                                                        <video
                                                                                            width={200}
                                                                                            height={200}
                                                                                            controls={false}
                                                                                            src={`${postFileData[0]?.file}#t=${duration}`}
                                                                                            preload={"metadata"}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </Col>
                                                    }

                                                    {/* {postFileData.map((video, index) => {
                            return (
                              <Col
                                sm={12}
                                md={12}
                                key={video?.post_file_id}
                                style={{ marginTop: "10px" }}
                              >
                                <label
                                  className="thubmnailHeading"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#6c757d",
                                    marginTop: "20px",
                                  }}
                                >{`${
                                  postFileData.length < 2
                                    ? `Default video thumbnail`
                                    : `Video ${index + 1} thumbnail`
                                } `}</label>


                                {postFileData?.[0]?.durationsArray?.length >
                                  0 && (
                                  <div
                                    className="durationDataWrapper"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "15px",
                                    }}
                                  >
                                    {video?.durationsArray?.map(
                                      (duration, index) => {
                                        return (
                                          <div
                                            className="durationListItem"
                                            key={index}
                                            style={{
                                              display: "flex",
                                              flexDirection: "column-reverse",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              className="durationListItemRadioSec"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="duration"
                                                value={duration}
                                                checked={
                                                  durationT.durationValue ==
                                                  duration
                                                }
                                                onChange={(e) =>
                                                  setDurationT({
                                                    durationValue:
                                                      e.target.value,
                                                    videoId:
                                                      video?.post_file_id,
                                                  })
                                                }
                                                style={{ margin: "0px" }}
                                              />
                                              <span
                                                style={{ fontSize: "17px" }}
                                              >
                                                Default thumbnail
                                              </span>
                                            </div>

                                            <video
                                              width={200}
                                              height={200}
                                              controls={false}
                                              src={`${video?.file}#t=${duration}`}
                                              preload={"metadata"}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Col>
                            );
                          })} */}
                                                </Row>
                                            ) : null}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {/* {videoThumbnail?.file && (
                    <div className="videoPreviewWrapper">
                      <div>
                        <input type="radio" name="video-sec" />
                        <video src="http://192.168.1.25/storage/uploads/posts/11/770735-post-video.mp4"></video>
                      </div>
                    </div>
                  )} */}

                                    {videoThumbnailStatus === true && inputData.amount > 0 ? (
                                        <>
                                            <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                                                <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                                                    {t("upload_video_post_preview")}
                                                </label>
                                                <Form.Control
                                                    style={{display: "block"}}
                                                    type="file"
                                                    placeholder={t(
                                                        "upload_video_post_preview_placeholder"
                                                    )}
                                                    name="preview_file"
                                                    width="50%"
                                                    className="form-control"
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={(event) => handleVideoPreview(event)}
                                                />
                                            </Form.Group>
                                            {videoPreview.previewVideo !== "" ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <div className="post-img-preview-sec m-0">
                                                            <div className="post-img-preview-sec my-3 my-lg-4">
                                                                <video
                                                                    autoplay
                                                                    controls
                                                                    id="myVideo"
                                                                    className="user-profile1 w-100"
                                                                >
                                                                    <source
                                                                        src={videoPreview.previewVideo}
                                                                        type="video/mp4"
                                                                    />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {audioThumbnailStatus === true &&
                                    postFileData.length > 0 &&
                                    disableImage &&
                                    disableVideo ? (
                                        <>
                                            <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                                                <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                                                    {t("upload_audio_thumbnail")}:({t("optional")})
                                                </label>
                                                <Form.Control
                                                    style={{display: "block"}}
                                                    type="file"
                                                    placeholder={t("upload_audio_thumbnail_placeholder")}
                                                    name="preview_file"
                                                    width="50%"
                                                    className="form-control"
                                                    accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                                    onChange={(event) => handleAudiopreviewImage(event)}
                                                    ref={audioThumbRef}
                                                />
                                            </Form.Group>

                                            {audioThumbnail.file !== "" ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <Link
                                                            to="#"
                                                            onClick={(event) =>
                                                                handleRemoveThubnail(
                                                                    event,
                                                                    audioThumbnail,
                                                                    "audio"
                                                                )
                                                            }
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "19px",
                                                                zIndex: "999",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa-times-circle"
                                                                style={{color: "red"}}
                                                            ></i>
                                                        </Link>
                                                        <Link
                                                            to="#"
                                                            onClick={(event) =>
                                                                handleOpenFilterModal(event, audioThumbnail)
                                                            }
                                                            style={{
                                                                position: "absolute",
                                                                top: "26px",
                                                                zIndex: "999",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa fa-magic"
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "16px",
                                                                    top: "-3px",
                                                                }}
                                                            ></i>
                                                        </Link>
                                                        <div className="post-img-preview-sec m-0">
                                                            <Image
                                                                alt="#"
                                                                src={audioThumbnail.file}
                                                                className="post-video-preview"
                                                            />

                                                            {checkImageFilerLoading(audioThumbnail?.file) &&
                                                                isLoading && (
                                                                    <div className="ffxpreview">
                                                                        <div className="ffxloader"></div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {audioThumbnailStatus === true && inputData.amount > 0 ? (
                                        <>
                                            <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                                                <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                                                    {t("upload_audio_post_preview")}
                                                </label>
                                                <Form.Control
                                                    style={{display: "block"}}
                                                    type="file"
                                                    placeholder={t(
                                                        "upload_audio_post_preview_placeholder"
                                                    )}
                                                    name="preview_file"
                                                    width="50%"
                                                    className="form-control"
                                                    accept="audio/mp3,audio/*"
                                                    onChange={(event) => handleAudioPreview(event)}
                                                />
                                            </Form.Group>
                                            {audioPreview.previewAudio !== "" ? (
                                                <Row>
                                                    <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                        <div className="post-img-preview-sec m-0">
                                                            <div className="post-img-preview-sec my-3 my-lg-4">
                                                                <audio
                                                                    autoplay
                                                                    controls
                                                                    id="myAudio"
                                                                    className="user-profile1 w-100"
                                                                >
                                                                    <source
                                                                        src={audioPreview.previewAudio}
                                                                        type="audio/mp3"
                                                                    />
                                                                </audio>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <Col
                                        md={12}
                                        sm={12}
                                        className="mt-5 mt-lg-4 col-md-6 col-sm-12"
                                    >
                                        <Row style={{display: "flex", flexDirection: "column"}}>
                                            <h3 style={{color: "#000", paddingTop: "22px"}}>
                                                Post Settings
                                            </h3>
                                            <div
                                                className="accessControlContainer mt-3"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "5px",
                                                }}
                                            >
                                                <h4 style={{color: "#8a96a3"}}>Access control</h4>

                                                {/* <div
                          className="accessControlContainer-radio-wrapper"
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <label
                              className="radio-btn"
                              htmlFor="no-limit-1"
                              style={{ marginBottom: "0px", fontSize: "13px" }}
                            >
                              Public
                            </label>
                            <input
                              style={{ margin: "0px" }}
                              type="radio"
                              id="no-limit-1"
                              name="accessControl"
                              onChange={handleAccessChange}
                              value="public"
                              checked={accessMainValue === "public"}
                              // disabled={
                              //   values?.accessContentData[0].mainToogle ===
                              //     true &&
                              //   accessT?.name?.mainToogle !== "fullAccess"
                              // }
                            />
                          </div>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <label
                              className="radio-btn"
                              htmlFor="no-limit-2"
                              style={{ marginBottom: "0px", fontSize: "13px" }}
                            >
                              Restrict user
                            </label>
                            <input
                              style={{ margin: "0px" }}
                              type="radio"
                              id="no-limit-2"
                              name="accessControl"
                              onChange={handleAccessChange}
                              value="restrict_user"
                              checked={accessMainValue === "restrict_user"}
                              // disabled={
                              //   values?.accessContentData[0].mainToogle ===
                              //     true &&
                              //   accessT?.name?.mainToogle !== "fullAccess"
                              // }
                            />
                          </div>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <label
                              className="radio-btn"
                              htmlFor="no-limit-3"
                              style={{ marginBottom: "0px", fontSize: "13px" }}
                            >
                              No one
                            </label>
                            <input
                              style={{ margin: "0px" }}
                              type="radio"
                              id="no-limit-3"
                              name="accessControl"
                              onChange={handleAccessChange}
                              value="no_one"
                              checked={accessMainValue === "no_one"}
                              // disabled={
                              //   values?.accessContentData[0].mainToogle ===
                              //     true &&
                              //   accessT?.name?.mainToogle !== "fullAccess"
                              // }
                            />
                          </div>
                        </div> */}

                                                <AccessControlMain
                                                    handleAccessChange={handleAccessChange}
                                                    accessMainValue={accessMainValue}
                                                    type={"mainAccessControl"}
                                                />

                                                {accessMainValue === "restrict_user" && (
                                                    // <div
                                                    //   className="accessControlContainer-radio-wrapper"
                                                    //   style={{
                                                    //     display: "flex",
                                                    //     width: "100%",
                                                    //     justifyContent: "space-between",
                                                    //     marginTop: "7px",
                                                    //   }}
                                                    // >
                                                    //   <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
                                                    //     <label
                                                    //       className="radio-btn"
                                                    //       htmlFor="sub-no-limit-1"
                                                    //       style={{
                                                    //         marginBottom: "0px",
                                                    //         fontSize: "13px",
                                                    //       }}
                                                    //     >
                                                    //       Subscriber only
                                                    //     </label>
                                                    //     <input
                                                    //       style={{ margin: "0px" }}
                                                    //       type="radio"
                                                    //       id="sub-no-limit-1"
                                                    //       name="accessControlRestrict"
                                                    //       onChange={handleAccessRestrictChange}
                                                    //       value="subscriber_only"
                                                    //       checked={
                                                    //         accessRestrictValue === "subscriber_only"
                                                    //       }
                                                    //       // disabled={
                                                    //       //   values?.accessContentData[0].mainToogle ===
                                                    //       //     true &&
                                                    //       //   accessT?.name?.mainToogle !== "fullAccess"
                                                    //       // }
                                                    //     />
                                                    //   </div>
                                                    //   <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
                                                    //     <label
                                                    //       className="radio-btn"
                                                    //       htmlFor="sub-no-limit-2"
                                                    //       style={{
                                                    //         marginBottom: "0px",
                                                    //         fontSize: "13px",
                                                    //       }}
                                                    //     >
                                                    //       Subscriber Level
                                                    //     </label>
                                                    //     <input
                                                    //       style={{ margin: "0px" }}
                                                    //       type="radio"
                                                    //       id="sub-no-limit-2"
                                                    //       name="accessControlRestrict"
                                                    //       onChange={handleAccessRestrictChange}
                                                    //       value="subscriber_level"
                                                    //       checked={
                                                    //         accessRestrictValue === "subscriber_level"
                                                    //       }
                                                    //       // disabled={
                                                    //       //   values?.accessContentData[0].mainToogle ===
                                                    //       //     true &&
                                                    //       //   accessT?.name?.mainToogle !== "fullAccess"
                                                    //       // }
                                                    //     />
                                                    //   </div>
                                                    //   <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
                                                    //     <label
                                                    //       className="radio-btn"
                                                    //       htmlFor="sub-no-limit-3"
                                                    //       style={{
                                                    //         marginBottom: "0px",
                                                    //         fontSize: "13px",
                                                    //       }}
                                                    //     >
                                                    //       Subscriber list
                                                    //     </label>
                                                    //     <input
                                                    //       style={{ margin: "0px" }}
                                                    //       type="radio"
                                                    //       id="sub-no-limit-3"
                                                    //       name="accessControlRestrict"
                                                    //       onChange={handleAccessRestrictChange}
                                                    //       value="select_from_list"
                                                    //       checked={
                                                    //         accessRestrictValue === "select_from_list"
                                                    //       }
                                                    //       // disabled={
                                                    //       //   values?.accessContentData[0].mainToogle ===
                                                    //       //     true &&
                                                    //       //   accessT?.name?.mainToogle !== "fullAccess"
                                                    //       // }
                                                    //     />
                                                    //   </div>
                                                    // </div>

                                                    <AccessControlSubMain
                                                        handleAccessRestrictChange={
                                                            handleAccessRestrictChange
                                                        }
                                                        accessRestrictValue={accessRestrictValue}
                                                        type={"mainAccessSubControl"}
                                                        isDisble={
                                                            accessRestrictValue.follower &&
                                                            accessRestrictValue.subscriber_only &&
                                                            accessRestrictValue.friends
                                                        }
                                                    />
                                                )}

                                                {accessMainValue === "restrict_user" &&
                                                    accessRestrictValue.subscriber_level === true && (
                                                        <AccessControlRestrict
                                                            optionsValue={subLevelOptions}
                                                            value={accessControlMainSelected.selectedSubLevel}
                                                            handleChangeValue={handleSetSelectedSubLevel}
                                                            Option={Option}
                                                            MultiValue={MultiValue}
                                                            labelType={"Subscriber levels"}
                                                        />
                                                    )}

                                                {accessMainValue === "restrict_user" ? (
                                                    accessRestrictValue.follower &&
                                                    accessRestrictValue.subscriber_only &&
                                                    accessRestrictValue.friends &&
                                                    accessRestrictValue.subscriber_list ? (
                                                        ""
                                                    ) : accessRestrictValue.subscriber_list ? (
                                                        <AccessControlRestrict
                                                            optionsValue={subListsOptions}
                                                            value={accessControlMainSelected.selectedSubList}
                                                            handleChangeValue={handleSetSelectedSubList}
                                                            Option={Option}
                                                            MultiValue={MultiValue}
                                                            labelType={"From list"}
                                                            groupOptions={
                                                                accessRestrictValue.follower &&
                                                                accessRestrictValue.subscriber_only &&
                                                                accessRestrictValue.friends
                                                                    ? []
                                                                    : accessRestrictValue.follower &&
                                                                    accessRestrictValue.friends
                                                                        ? [
                                                                            {
                                                                                label: "Subscribers",
                                                                                options:
                                                                                listGroupedOp.accessRestrictGropOpt[0]
                                                                                    ?.options,
                                                                            },

                                                                            //  {
                                                                            //   label: "Friends",
                                                                            //   options:
                                                                            //     listGroupedOp.accessRestrictGropOpt[1]
                                                                            //       ?.options,
                                                                            // },
                                                                        ]
                                                                        : accessRestrictValue.subscriber_only &&
                                                                        accessRestrictValue.follower
                                                                            ? [
                                                                                {
                                                                                    label: "Friends",
                                                                                    options:
                                                                                    listGroupedOp.accessRestrictGropOpt[1]
                                                                                        ?.options,
                                                                                },
                                                                                //  {
                                                                                //    label: "Followers",
                                                                                //    options:
                                                                                //      listGroupedOp.accessRestrictGropOpt[2]
                                                                                //        ?.options,
                                                                                //  },
                                                                            ]
                                                                            : accessRestrictValue.friends &&
                                                                            accessRestrictValue.subscriber_only
                                                                                ? [
                                                                                    // {
                                                                                    //   label: "Subscribers",
                                                                                    //   options:
                                                                                    //     listGroupedOp.accessRestrictGropOpt[0]
                                                                                    //       ?.options,
                                                                                    // },

                                                                                    {
                                                                                        label: "Followers",
                                                                                        options:
                                                                                        listGroupedOp.accessRestrictGropOpt[2]
                                                                                            ?.options,
                                                                                    },
                                                                                ]
                                                                                : accessRestrictValue.follower &&
                                                                                accessRestrictValue.friends
                                                                                    ? [
                                                                                        {
                                                                                            label: "Subscribers",
                                                                                            options:
                                                                                            listGroupedOp.accessRestrictGropOpt[0]
                                                                                                ?.options,
                                                                                        },
                                                                                    ]
                                                                                    : accessRestrictValue.follower
                                                                                        ? [
                                                                                            {
                                                                                                label: "Subscribers",
                                                                                                options:
                                                                                                listGroupedOp.accessRestrictGropOpt[0]
                                                                                                    ?.options,
                                                                                            },

                                                                                            {
                                                                                                label: "Friends",
                                                                                                options:
                                                                                                listGroupedOp.accessRestrictGropOpt[1]
                                                                                                    ?.options,
                                                                                            },
                                                                                        ]
                                                                                        : accessRestrictValue.friends
                                                                                            ? [
                                                                                                {
                                                                                                    label: "Subscribers",
                                                                                                    options:
                                                                                                    listGroupedOp.accessRestrictGropOpt[0]
                                                                                                        ?.options,
                                                                                                },

                                                                                                {
                                                                                                    label: "Followers",
                                                                                                    options:
                                                                                                    listGroupedOp.accessRestrictGropOpt[2]
                                                                                                        ?.options,
                                                                                                },
                                                                                            ]
                                                                                            : accessRestrictValue.subscriber_only
                                                                                                ? [
                                                                                                    {
                                                                                                        label: "Friends",
                                                                                                        options:
                                                                                                        listGroupedOp.accessRestrictGropOpt[1]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                    {
                                                                                                        label: "Followers",
                                                                                                        options:
                                                                                                        listGroupedOp.accessRestrictGropOpt[2]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                ]
                                                                                                : [
                                                                                                    {
                                                                                                        label: "Subscribers",
                                                                                                        options:
                                                                                                        listGroupedOp.accessRestrictGropOpt[0]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                    {
                                                                                                        label: "Friends",
                                                                                                        options:
                                                                                                        listGroupedOp.accessRestrictGropOpt[1]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                    {
                                                                                                        label: "Followers",
                                                                                                        options:
                                                                                                        listGroupedOp.accessRestrictGropOpt[2]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                ]
                                                            }
                                                        />
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                {/* {accessMainValue === "restrict_user" &&
                          accessRestrictValue.subscriber_list === true && (
                            <AccessControlRestrict
                              optionsValue={subListsOptions}
                              value={accessControlMainSelected.selectedSubList}
                              handleChangeValue={handleSetSelectedSubList}
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                accessRestrictValue.follower &&
                                accessRestrictValue.subscriber_only
                                  ? []
                                  : accessRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp.accessRestrictGropOpt[0]
                                            ?.options,
                                      },
                                    ]
                                  : accessRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp.accessRestrictGropOpt[1]
                                            ?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp.accessRestrictGropOpt[0]
                                            ?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp.accessRestrictGropOpt[1]
                                            ?.options,
                                      },
                                    ]
                              }
                            />
                          )} */}

                                                {isRestritError.accessRestrictError.subLevelError ===
                                                    true &&
                                                    accessMainValue === "restrict_user" &&
                                                    accessRestrictValue.subscriber_level === true && (
                                                        <p style={{color: "red", fontSize: "10px"}}>
                                                            Select atleast one
                                                        </p>
                                                    )}

                                                {isRestritError.accessRestrictError.subListError ===
                                                    true &&
                                                    accessMainValue === "restrict_user" &&
                                                    accessRestrictValue.subscriber_list === true && (
                                                        <p style={{color: "red", fontSize: "10px"}}>
                                                            Select atleast one
                                                        </p>
                                                    )}
                                            </div>

                                            <div className="accessControlContainer mt-5">
                                                <h4 style={{color: "rgb(138, 150, 163)"}}>
                                                    Advanced settings
                                                </h4>

                                                <div
                                                    className="who-download-container"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "19px",
                                                        marginTop: "11px",
                                                    }}
                                                >
                                                    <div
                                                        className="who-download-wrapper"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Who can download
                                                        </p>

                                                        <AccessControlMain
                                                            handleAccessChange={handleAccessChange}
                                                            accessMainValue={downloadAccessMainValue}
                                                            type={"whoCanDownload"}
                                                        />

                                                        {downloadAccessMainValue === "restrict_user" && (
                                                            <AccessControlSubMain
                                                                handleAccessRestrictChange={
                                                                    handleAccessRestrictChange
                                                                }
                                                                accessRestrictValue={downloadRestrictValue}
                                                                type={"whoCanDownloadSub"}
                                                                isDisble={
                                                                    downloadRestrictValue.follower &&
                                                                    downloadRestrictValue.subscriber_only
                                                                }
                                                            />
                                                        )}

                                                        {downloadAccessMainValue === "restrict_user" &&
                                                            downloadRestrictValue.subscriber_level ===
                                                            true && (
                                                                <AccessControlRestrict
                                                                    optionsValue={subLevelOptions}
                                                                    value={downloadMainSelected.selectedSubLevel}
                                                                    handleChangeValue={
                                                                        handleWhoCanDownloadLevelSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"Subscriber levels"}
                                                                />
                                                            )}

                                                        {downloadAccessMainValue === "restrict_user" ? (
                                                            downloadRestrictValue.follower &&
                                                            downloadRestrictValue.subscriber_only &&
                                                            downloadRestrictValue.subscriber_list ? (
                                                                ""
                                                            ) : downloadRestrictValue.subscriber_list ? (
                                                                <AccessControlRestrict
                                                                    optionsValue={subListsOptions}
                                                                    value={downloadMainSelected.selectedSubList}
                                                                    handleChangeValue={
                                                                        handleWhoCanDownloadListSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"From list"}
                                                                    groupOptions={
                                                                        downloadRestrictValue.follower &&
                                                                        downloadRestrictValue.subscriber_only &&
                                                                        downloadRestrictValue.friends
                                                                            ? []
                                                                            : downloadRestrictValue.follower &&
                                                                            downloadRestrictValue.friends
                                                                                ? [
                                                                                    {
                                                                                        label: "Subscribers",
                                                                                        options:
                                                                                        listGroupedOp
                                                                                            .downloadRestrictGropOpt[0]
                                                                                            ?.options,
                                                                                    },
                                                                                ]
                                                                                : downloadRestrictValue.subscriber_only &&
                                                                                downloadRestrictValue.follower
                                                                                    ? [
                                                                                        {
                                                                                            label: "Friends",
                                                                                            options:
                                                                                            listGroupedOp
                                                                                                .downloadRestrictGropOpt[1]
                                                                                                ?.options,
                                                                                        },
                                                                                    ]
                                                                                    : downloadRestrictValue.friends &&
                                                                                    downloadRestrictValue.subscriber_only
                                                                                        ? [
                                                                                            {
                                                                                                label: "Followers",
                                                                                                options:
                                                                                                listGroupedOp
                                                                                                    .downloadRestrictGropOpt[2]
                                                                                                    ?.options,
                                                                                            },
                                                                                        ]
                                                                                        : downloadRestrictValue.follower &&
                                                                                        downloadRestrictValue.friends
                                                                                            ? [
                                                                                                {
                                                                                                    label: "Subscribers",
                                                                                                    options:
                                                                                                    listGroupedOp
                                                                                                        .downloadRestrictGropOpt[0]
                                                                                                        ?.options,
                                                                                                },
                                                                                            ]
                                                                                            : downloadRestrictValue.follower
                                                                                                ? [
                                                                                                    {
                                                                                                        label: "Subscribers",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .downloadRestrictGropOpt[0]
                                                                                                            ?.options,
                                                                                                    },

                                                                                                    {
                                                                                                        label: "Friends",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .downloadRestrictGropOpt[1]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                ]
                                                                                                : downloadRestrictValue.friends
                                                                                                    ? [
                                                                                                        {
                                                                                                            label: "Subscribers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .downloadRestrictGropOpt[0]
                                                                                                                ?.options,
                                                                                                        },

                                                                                                        {
                                                                                                            label: "Followers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .downloadRestrictGropOpt[2]
                                                                                                                ?.options,
                                                                                                        },
                                                                                                    ]
                                                                                                    : downloadRestrictValue.subscriber_only
                                                                                                        ? [
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .downloadRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .downloadRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                                                        : [
                                                                                                            {
                                                                                                                label: "Subscribers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .downloadRestrictGropOpt[0]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .downloadRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .downloadRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* {downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <AccessControlRestrict
                                  optionsValue={subListsOptions}
                                  value={downloadMainSelected.selectedSubList}
                                  handleChangeValue={
                                    handleWhoCanDownloadListSelected
                                  }
                                  Option={Option}
                                  MultiValue={MultiValue}
                                  labelType={"Subscriber list"}
                                  groupOptions={
                                    downloadRestrictValue.follower &&
                                    downloadRestrictValue.subscriber_only
                                      ? []
                                      : downloadRestrictValue.follower
                                      ? [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .downloadRestrictGropOpt[0]
                                                ?.options,
                                          },
                                        ]
                                      : downloadRestrictValue.subscriber_only
                                      ? [
                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .downloadRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                      : [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .downloadRestrictGropOpt[0]
                                                ?.options,
                                          },
                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .downloadRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                  }
                                />
                              )} */}

                                                        {isRestritError.downloadRestrictError
                                                                .subLevelError === true &&
                                                            downloadAccessMainValue === "restrict_user" &&
                                                            downloadRestrictValue.subscriber_level ===
                                                            true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}

                                                        {isRestritError.downloadRestrictError
                                                                .subListError === true &&
                                                            downloadAccessMainValue === "restrict_user" &&
                                                            downloadRestrictValue.subscriber_list ===
                                                            true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}
                                                    </div>

                                                    <div
                                                        className="who-download-wrapper"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Who can forward
                                                        </p>

                                                        <AccessControlMain
                                                            handleAccessChange={handleAccessChange}
                                                            accessMainValue={forwardAccessMainValue}
                                                            type={"whoCanForward"}
                                                        />

                                                        {forwardAccessMainValue === "restrict_user" && (
                                                            <AccessControlSubMain
                                                                handleAccessRestrictChange={
                                                                    handleAccessRestrictChange
                                                                }
                                                                accessRestrictValue={forwardRestrictValue}
                                                                type={"whoCanForwardSub"}
                                                                isDisble={
                                                                    forwardRestrictValue.follower &&
                                                                    forwardRestrictValue.subscriber_only
                                                                }
                                                            />
                                                        )}

                                                        {forwardAccessMainValue === "restrict_user" &&
                                                            forwardRestrictValue.subscriber_level ===
                                                            true && (
                                                                <AccessControlRestrict
                                                                    optionsValue={subLevelOptions}
                                                                    value={forwardMainSelected.selectedSubLevel}
                                                                    handleChangeValue={
                                                                        handleWhoCanForwardLevelSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"Subscriber levels"}
                                                                />
                                                            )}

                                                        {forwardAccessMainValue === "restrict_user" ? (
                                                            forwardRestrictValue.follower &&
                                                            forwardRestrictValue.subscriber_only &&
                                                            forwardRestrictValue.subscriber_list ? (
                                                                ""
                                                            ) : forwardRestrictValue.subscriber_list ? (
                                                                <AccessControlRestrict
                                                                    optionsValue={subListsOptions}
                                                                    value={forwardMainSelected.selectedSubList}
                                                                    handleChangeValue={
                                                                        handleWhoCanForwardListSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"From list"}
                                                                    groupOptions={
                                                                        forwardRestrictValue.follower &&
                                                                        forwardRestrictValue.subscriber_only &&
                                                                        forwardRestrictValue.friends
                                                                            ? []
                                                                            : forwardRestrictValue.follower &&
                                                                            forwardRestrictValue.friends
                                                                                ? [
                                                                                    {
                                                                                        label: "Subscribers",
                                                                                        options:
                                                                                        listGroupedOp
                                                                                            .forwardRestrictGropOpt[0]
                                                                                            ?.options,
                                                                                    },
                                                                                ]
                                                                                : forwardRestrictValue.subscriber_only &&
                                                                                forwardRestrictValue.follower
                                                                                    ? [
                                                                                        {
                                                                                            label: "Friends",
                                                                                            options:
                                                                                            listGroupedOp
                                                                                                .forwardRestrictGropOpt[1]
                                                                                                ?.options,
                                                                                        },
                                                                                    ]
                                                                                    : forwardRestrictValue.friends &&
                                                                                    forwardRestrictValue.subscriber_only
                                                                                        ? [
                                                                                            {
                                                                                                label: "Followers",
                                                                                                options:
                                                                                                listGroupedOp
                                                                                                    .forwardRestrictGropOpt[2]
                                                                                                    ?.options,
                                                                                            },
                                                                                        ]
                                                                                        : forwardRestrictValue.follower &&
                                                                                        forwardRestrictValue.friends
                                                                                            ? [
                                                                                                {
                                                                                                    label: "Subscribers",
                                                                                                    options:
                                                                                                    listGroupedOp
                                                                                                        .forwardRestrictGropOpt[0]
                                                                                                        ?.options,
                                                                                                },
                                                                                            ]
                                                                                            : forwardRestrictValue.follower
                                                                                                ? [
                                                                                                    {
                                                                                                        label: "Subscribers",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .forwardRestrictGropOpt[0]
                                                                                                            ?.options,
                                                                                                    },

                                                                                                    {
                                                                                                        label: "Friends",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .forwardRestrictGropOpt[1]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                ]
                                                                                                : forwardRestrictValue.friends
                                                                                                    ? [
                                                                                                        {
                                                                                                            label: "Subscribers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .forwardRestrictGropOpt[0]
                                                                                                                ?.options,
                                                                                                        },

                                                                                                        {
                                                                                                            label: "Followers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .forwardRestrictGropOpt[2]
                                                                                                                ?.options,
                                                                                                        },
                                                                                                    ]
                                                                                                    : forwardRestrictValue.subscriber_only
                                                                                                        ? [
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .forwardRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .forwardRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                                                        : [
                                                                                                            {
                                                                                                                label: "Subscribers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .forwardRestrictGropOpt[0]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .forwardRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .forwardRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* {forwardAccessMainValue === "restrict_user" &&
                              forwardRestrictValue.subscriber_list === true && (
                                <AccessControlRestrict
                                  optionsValue={subListsOptions}
                                  value={forwardMainSelected.selectedSubList}
                                  handleChangeValue={
                                    handleWhoCanForwardListSelected
                                  }
                                  Option={Option}
                                  MultiValue={MultiValue}
                                  labelType={"Subscriber list"}
                                  groupOptions={
                                    forwardRestrictValue.follower &&
                                    forwardRestrictValue.subscriber_only
                                      ? []
                                      : forwardRestrictValue.follower
                                      ? [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .forwardRestrictGropOpt[0]
                                                ?.options,
                                          },
                                        ]
                                      : forwardRestrictValue.subscriber_only
                                      ? [
                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .forwardRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                      : [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .forwardRestrictGropOpt[0]
                                                ?.options,
                                          },
                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .forwardRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                  }
                                />
                              )} */}

                                                        {isRestritError.forwardRestrictError
                                                                .subLevelError === true &&
                                                            forwardAccessMainValue === "restrict_user" &&
                                                            forwardRestrictValue.subscriber_level ===
                                                            true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}

                                                        {isRestritError.forwardRestrictError
                                                                .subListError === true &&
                                                            forwardAccessMainValue === "restrict_user" &&
                                                            forwardRestrictValue.subscriber_list === true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}
                                                    </div>

                                                    <div
                                                        className="who-download-wrapper"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Who can comment
                                                        </p>

                                                        <AccessControlMain
                                                            handleAccessChange={handleAccessChange}
                                                            accessMainValue={commentAccessMainValue}
                                                            type={"whoCanComment"}
                                                        />

                                                        {commentAccessMainValue === "restrict_user" && (
                                                            <AccessControlSubMain
                                                                handleAccessRestrictChange={
                                                                    handleAccessRestrictChange
                                                                }
                                                                accessRestrictValue={commentRestrictValue}
                                                                type={"whoCanCommentSub"}
                                                                isDisble={
                                                                    commentRestrictValue.follower &&
                                                                    commentRestrictValue.subscriber_only
                                                                }
                                                            />
                                                        )}

                                                        {commentAccessMainValue === "restrict_user" &&
                                                            commentRestrictValue.subscriber_level ===
                                                            true && (
                                                                <AccessControlRestrict
                                                                    optionsValue={subLevelOptions}
                                                                    value={commentMainSelected.selectedSubLevel}
                                                                    handleChangeValue={
                                                                        handleWhoCanCommentLevelSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"Subscriber levels"}
                                                                />
                                                            )}

                                                        {commentAccessMainValue === "restrict_user" ? (
                                                            commentRestrictValue.follower &&
                                                            commentRestrictValue.subscriber_only &&
                                                            commentRestrictValue.subscriber_list ? (
                                                                ""
                                                            ) : commentRestrictValue.subscriber_list ? (
                                                                <AccessControlRestrict
                                                                    optionsValue={subListsOptions}
                                                                    value={commentMainSelected.selectedSubList}
                                                                    handleChangeValue={
                                                                        handleWhoCanCommentListSelected
                                                                    }
                                                                    Option={Option}
                                                                    MultiValue={MultiValue}
                                                                    labelType={"From list"}
                                                                    groupOptions={
                                                                        commentRestrictValue.follower &&
                                                                        commentRestrictValue.subscriber_only &&
                                                                        commentRestrictValue.friends
                                                                            ? []
                                                                            : commentRestrictValue.follower &&
                                                                            commentRestrictValue.friends
                                                                                ? [
                                                                                    {
                                                                                        label: "Subscribers",
                                                                                        options:
                                                                                        listGroupedOp
                                                                                            .commentRestrictGropOpt[0]
                                                                                            ?.options,
                                                                                    },
                                                                                ]
                                                                                : commentRestrictValue.subscriber_only &&
                                                                                commentRestrictValue.follower
                                                                                    ? [
                                                                                        {
                                                                                            label: "Friends",
                                                                                            options:
                                                                                            listGroupedOp
                                                                                                .commentRestrictGropOpt[1]
                                                                                                ?.options,
                                                                                        },
                                                                                    ]
                                                                                    : commentRestrictValue.friends &&
                                                                                    commentRestrictValue.subscriber_only
                                                                                        ? [
                                                                                            {
                                                                                                label: "Followers",
                                                                                                options:
                                                                                                listGroupedOp
                                                                                                    .commentRestrictGropOpt[2]
                                                                                                    ?.options,
                                                                                            },
                                                                                        ]
                                                                                        : commentRestrictValue.follower &&
                                                                                        commentRestrictValue.friends
                                                                                            ? [
                                                                                                {
                                                                                                    label: "Subscribers",
                                                                                                    options:
                                                                                                    listGroupedOp
                                                                                                        .commentRestrictGropOpt[0]
                                                                                                        ?.options,
                                                                                                },
                                                                                            ]
                                                                                            : commentRestrictValue.follower
                                                                                                ? [
                                                                                                    {
                                                                                                        label: "Subscribers",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .commentRestrictGropOpt[0]
                                                                                                            ?.options,
                                                                                                    },

                                                                                                    {
                                                                                                        label: "Friends",
                                                                                                        options:
                                                                                                        listGroupedOp
                                                                                                            .commentRestrictGropOpt[1]
                                                                                                            ?.options,
                                                                                                    },
                                                                                                ]
                                                                                                : commentRestrictValue.friends
                                                                                                    ? [
                                                                                                        {
                                                                                                            label: "Subscribers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .commentRestrictGropOpt[0]
                                                                                                                ?.options,
                                                                                                        },

                                                                                                        {
                                                                                                            label: "Followers",
                                                                                                            options:
                                                                                                            listGroupedOp
                                                                                                                .commentRestrictGropOpt[2]
                                                                                                                ?.options,
                                                                                                        },
                                                                                                    ]
                                                                                                    : commentRestrictValue.subscriber_only
                                                                                                        ? [
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .commentRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .commentRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                                                        : [
                                                                                                            {
                                                                                                                label: "Subscribers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .commentRestrictGropOpt[0]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Friends",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .commentRestrictGropOpt[1]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                            {
                                                                                                                label: "Followers",
                                                                                                                options:
                                                                                                                listGroupedOp
                                                                                                                    .commentRestrictGropOpt[2]
                                                                                                                    ?.options,
                                                                                                            },
                                                                                                        ]
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* {commentAccessMainValue === "restrict_user" &&
                              commentRestrictValue.subscriber_list === true && (
                                <AccessControlRestrict
                                  optionsValue={subListsOptions}
                                  value={commentMainSelected.selectedSubList}
                                  handleChangeValue={
                                    handleWhoCanCommentListSelected
                                  }
                                  Option={Option}
                                  MultiValue={MultiValue}
                                  labelType={"Subscriber list"}
                                  groupOptions={
                                    commentRestrictValue.follower &&
                                    commentRestrictValue.subscriber_only
                                      ? []
                                      : commentRestrictValue.follower
                                      ? [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .commentRestrictGropOpt[0]
                                                ?.options,
                                          },
                                        ]
                                      : commentRestrictValue.subscriber_only
                                      ? [
                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .commentRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                      : [
                                          {
                                            label: "Subscribers",
                                            options:
                                              listGroupedOp
                                                .commentRestrictGropOpt[0]
                                                ?.options,
                                          },

                                          {
                                            label: "Followers",
                                            options:
                                              listGroupedOp
                                                .commentRestrictGropOpt[1]
                                                ?.options,
                                          },
                                        ]
                                  }
                                />
                              )} */}

                                                        {isRestritError.commentRestrictError
                                                                .subLevelError === true &&
                                                            commentAccessMainValue === "restrict_user" &&
                                                            commentRestrictValue.subscriber_level ===
                                                            true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}

                                                        {isRestritError.commentRestrictError
                                                                .subListError === true &&
                                                            commentAccessMainValue === "restrict_user" &&
                                                            commentRestrictValue.subscriber_list === true && (
                                                                <p style={{color: "red", fontSize: "10px"}}>
                                                                    Select atleast one
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="advanced-setting-checkbox-container">
                                                    <div
                                                        className="advanced-setting-c-wrapper"
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            justifyContent: "space-between",
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        <div className="step-2-footer-sec d-flex align-items-center">
                                                            <input
                                                                className="mt-0"
                                                                type="checkbox"
                                                                onChange={handleMultiCheckbox}
                                                                id="tipsCheck"
                                                                name="tipsCheck"
                                                                checked={multiCheckbox.tipsCheck}
                                                            />
                                                            <label
                                                                className="ml-2 mb-0"
                                                                htmlFor="tipsCheck"
                                                                style={{color: "black"}}
                                                            >
                                                                Tips
                                                            </label>
                                                        </div>
                                                        <div className="step-2-footer-sec d-flex align-items-center">
                                                            <input
                                                                className="mt-0"
                                                                type="checkbox"
                                                                onChange={handleMultiCheckbox}
                                                                id="bookMarkCheck"
                                                                name="bookMarkCheck"
                                                                checked={multiCheckbox.bookMarkCheck}
                                                            />
                                                            <label
                                                                className="ml-2 mb-0"
                                                                htmlFor="bookMarkCheck"
                                                                style={{color: "black"}}
                                                            >
                                                                Bookmark
                                                            </label>
                                                        </div>
                                                        <div className="step-2-footer-sec d-flex align-items-center">
                                                            <input
                                                                className="mt-0"
                                                                type="checkbox"
                                                                onChange={handleMultiCheckbox}
                                                                id="LikeCheck"
                                                                name="LikeCheck"
                                                                checked={multiCheckbox.LikeCheck}
                                                            />
                                                            <label
                                                                className="ml-2 mb-0"
                                                                htmlFor="LikeCheck"
                                                                style={{color: "black"}}
                                                            >
                                                                Like
                                                            </label>
                                                        </div>
                                                        <div className="step-2-footer-sec d-flex align-items-center">
                                                            <input
                                                                className="mt-0"
                                                                type="checkbox"
                                                                onChange={handleMultiCheckbox}
                                                                id="reportCheck"
                                                                checked={multiCheckbox.reportCheck}
                                                                name="reportCheck"
                                                            />
                                                            <label
                                                                className="ml-2 mb-0"
                                                                htmlFor="reportCheck"
                                                                style={{color: "black"}}
                                                            >
                                                                Report
                                                            </label>
                                                        </div>
                                                        <div className="step-2-footer-sec d-flex align-items-center">
                                                            <input
                                                                className="mt-0"
                                                                type="checkbox"
                                                                onChange={handleMultiCheckbox}
                                                                checked={multiCheckbox.commentCheck}
                                                                id="commentCheck"
                                                                name="commentCheck"
                                                            />
                                                            <label
                                                                className="ml-2 mb-0"
                                                                htmlFor="commentCheck"
                                                                style={{color: "black"}}
                                                            >
                                                                Comment
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Col>

                                {/* video/audio/image button */}

                                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                                    {localStorage.getItem("is_content_creator") == 2 ? (
                                        <div className="left-half post-write">
                                            <Button>
                                                <Form.Group className="mb-0">
                                                    <Form.Control
                                                        id="fileupload_photo"
                                                        type="file"
                                                        multiple="multiple"
                                                        // disabled={disableImage ? true : false}
                                                        accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                                        onChange={(event) =>
                                                            handleChangeImage(event, "image")
                                                        }
                                                        name="post_files"
                                                    />
                                                    <Form.Label
                                                        id="attach_file_photo"
                                                        for="fileupload_photo"
                                                        className="chat-attach_file"
                                                        data-original-title="null"
                                                    >
                                                        <Image
                                                            src={`${window.location.origin}/assets/images/post/post-image-upload.svg`}
                                                            className="svg-clone"
                                                        />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Button>

                                            <Button>
                                                <Form.Group
                                                    className="mb-0"
                                                    controlId="formFileDisabled"
                                                >
                                                    <Form.Control
                                                        id="fileupload_video"
                                                        type="file"
                                                        multiple="multiple"
                                                        // disabled={disableVideo ? true : false}
                                                        accept="video/mp4,video/x-m4v,video/*"
                                                        onChange={(event) =>
                                                            handleChangeVideo(event, "video")
                                                        }
                                                        name="post_files"
                                                    />
                                                    <Form.Label
                                                        id="attach_file_video"
                                                        for="fileupload_video"
                                                        className="chat-attach_file"
                                                        data-original-title="null"
                                                    >

                                                        <Image
                                                            src={`${window.location.origin}/assets/images/post/post-video-upload.svg`}
                                                            className="svg-clone video-add-icon"
                                                        />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Button>
                                            {videoTitle !== "" ? (
                                                <div className="post-title-content create-post-video-title">
                                                    <h4>{videoTitle}</h4>
                                                </div>
                                            ) : null}

                                            <Button>
                                                <Form.Group
                                                    className="mb-0"
                                                    controlId="formFileDisabled"
                                                >
                                                    <Form.Control
                                                        id="fileupload_audio"
                                                        type="file"
                                                        multiple="multiple"
                                                        // disabled={disableAudio ? true : false}
                                                        accept="audio/mp3,audio/*"
                                                        onChange={(event) =>
                                                            handleChangeAudio(event, "audio")
                                                        }
                                                        name="post_files"
                                                    />
                                                    <Form.Label
                                                        id="attach_file_audio"
                                                        for="fileupload_audio"
                                                        className="chat-attach_file"
                                                        data-original-title="null"
                                                    >
                                                        {/* <Image
                          src="assets/images/icons/audio.svg"
                          className="svg-clone"
                        /> */}
                                                        <Image
                                                            src={`${window.location.origin}/assets/images/post/post-audio-upload.svg`}
                                                            className="svg-clone"
                                                        />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Button>
                                            {audioTitle !== "" ? (
                                                <div className="post-title-content create-post-video-title">
                                                    <h4>{audioTitle}</h4>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                  {((previewImage && disableAudio && disableVideo) ||
                                      (audioPreviewUrl && disableImage && disableVideo) ||
                                      (videoPreviewUrl && disableAudio && disableImage)) && postFileData ? (
                                      <GridContextProvider onChange={(videoPreviewUrl && disableAudio === true && disableImage === true) ? (sourceId, sourceIndex, targetIndex) =>
                                          onChange(sourceId, sourceIndex, targetIndex, "video") : onChange}>
                                        <GridDropZone
                                            id="items"
                                            className="row"
                                            boxesPerRow={
                                              postFileData.length === 1
                                                  ? 3
                                                  : postFileData.length === 2
                                                      ? 3
                                                      : postFileData.length === 3
                                                          ? 3
                                                          : 3  // Set default 3 grid items
                                            }

                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              width: "100%",
                                              padding: '10px',
                                              gap: '10px',
                                              position: "relative",
                                              justifyContent: "flex-start",
                                            }}
                                        >
                                          {postFileData.map((item, index) => (
                                              <GridItem key={item?.post_file_id} style={{
                                                width: 'calc(33.33% - 10px)',
                                                boxSizing: 'border-box',
                                                padding: "5px",
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                position: 'relative'
                                              }}>

                                                <div style={{position: 'absolute', top: '-10px', right: '20px', zIndex: 2, cursor: "grab"}}>
                                                  <i className="fas fa-bars"></i>
                                                </div>


                                                <Link
                                                    to="#"
                                                    onClick={(event) => handleClose(event, item)}
                                                    style={{
                                                      position: "absolute",
                                                      top: "-14px",
                                                      right: "-10px",
                                                      zIndex: 2,
                                                    }}
                                                >
                                                  <i className="far fa-times-circle" style={{color: "red"}} ></i>
                                                </Link>

                                                {/* Dynamic Media Rendering */}
                                                {item.fileType === "image" && (
                                                    <img
                                                        src={item?.file}
                                                        alt={`Image ${index}`}
                                                        style={{
                                                          width: '100%',
                                                          height: 'auto',
                                                          maxHeight: '150px', // Example constraint
                                                          objectFit: 'cover',
                                                          display: 'block',
                                                          border: '1px solid #eee',
                                                          borderRadius: '5px',
                                                        }}
                                                    />
                                                )}

                                                {item.fileType === "audio" && (
                                                    <audio
                                                        controls
                                                        id="myAudio"
                                                        className="user-profile1"
                                                        style={{width: '150px'}} // Fill without stretching
                                                    >
                                                      <source src={item?.file} type="audio/mp3"/>
                                                    </audio>
                                                )}

                                                {item.fileType === "video" && (
                                                    <video
                                                        ref={videoEl}
                                                        onLoadedMetadata={(e) =>
                                                            handleLoadedMetadata(
                                                                index,
                                                                e.target.duration,
                                                                item
                                                            )
                                                        }
                                                        autoPlay
                                                        controls
                                                        id="myVideo"
                                                        className="user-profile1 create-post-video"
                                                        style={{
                                                          width: "100%",
                                                          height: "auto",
                                                          maxHeight: '150px', //Example Constraint
                                                          objectFit: 'contain'     // Or 'cover'
                                                        }}
                                                    >
                                                      <source src={item?.file} type="video/mp4"/>
                                                    </video>
                                                )}
                                              </GridItem>
                                          ))}
                                        </GridDropZone>
                                      </GridContextProvider>
                                  ) : null}


                                </Col>
                            </Row>

                          <Row>
                            <Col sm={12} md={12}>
                              <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "7px",
                                  }}
                              >
                                <Button
                                    onClick={handleOpenSchdualModal}
                                    className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                    style={{width: "auto"}}
                                    disabled={
                                        props.fileUpload.buttonDisable ||
                                        props.savePost.buttonDisable
                                    }
                                >
                                  Save Schedule
                                </Button>
                                <Button
                                    onClick={handleSubmitDraft}
                                    className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                    style={{width: "auto"}}
                                    disabled={
                                        props.fileUpload.buttonDisable ||
                                        props.savePost.buttonDisable
                                            }
                                        >
                                            Save Draft
                                        </Button>

                                        {localStorage.getItem("is_content_creator") == 2 ? (
                                            <Button
                                                type="submit"
                                                className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                style={{width: "auto"}}
                                                onClick={handleSubmit}
                                                disabled={
                                                    props.fileUpload.buttonDisable ||
                                                    props.savePost.buttonDisable
                                                }
                                            >
                                                {props.fileUpload.loadingButtonContent !== null
                                                    ? props.fileUpload.loadingButtonContent
                                                    : props.savePost.loadingButtonContent !== null
                                                        ? props.savePost.loadingButtonContent
                                                        : t("post")}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                style={{width: "auto"}}
                                                className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                disabled="true"
                                            >
                                                {t("post")}
                                            </Button>
                                        )}

                                        <PostSchedualDateTHomeModal
                                            setIsStartDateError={setIsStartDateError}
                                            IsStartDateError={IsStartDateError}
                                            isScheduleModdalOpen={isScheduleModdalOpen}
                                            handleCloseModal={handleCloseSchdualModal}
                                            setStartDate={setStartDate}
                                            startDate={startDate}
                                            descriptionNote={descriptionNoteS}
                                            handleChangeNote={handleChangeDescriptionNote}
                                            isSingleSchedualData={isSingleSchedualData}
                                            handleSubmitSchadual={handleSubmitSchadual}
                                        />

                                        {/* <PostSchedualDateTModal setIsStartDateError={setIsStartDateError} IsStartDateError={IsStartDateError} isScheduleModdalOpen={isScheduleModdalOpen} handleCloseModal={handleCloseSchdualModal}  setStartDate={setStartDate} startDate={startDate} descriptionNote={descriptionNoteS} handleChangeNote={handleChangeDescriptionNote} handleSubmitSchadual={handleSubmitSchadual} /> */}
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                        {showFilterModalAnimation && (
                            <PostFilterModal
                                getAllFile={getAllFile}
                                getAllId={getAllId}
                                videoThumbnail={videoThumbnail}
                                setVideoThumbnail={setVideoThumbnail}
                                setPostFileData={setPostFileData}
                                allPostFileData={postFileData}
                                setIsLoading={setIsLoading}
                                show={showFilterModal}
                                handleClose={handleCloseFilterModal}
                                filterImageUpdate={filterImageUpdate}
                                setAudioThumbnail={setAudioThumbnail}
                                audioThumbnail={audioThumbnail}
                                postFileData={currentPostFileData}
                            />
                        )}
                    </div>
                    ) : (
                    ""
                    )}{" "}
            </Container>
            {localStorage.getItem("is_content_creator") != 2 && (
            <ContentCreatorSteps/>
            )}
        </div>
);
};

const mapStateToPros = (state) => ({
    savePost: state.post.savePost,
        fileUpload
:
    state.post.fileUpload,
        fileFilterUpload
:
    state.post.fileFilterUpload,
        fileRemove
:
    state.post.fileRemove,
        searchUser
:
    state.home.searchUser,
        postCategories
:
    state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(translate(CreatePostIndex));
