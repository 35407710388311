import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import UserCard from "../FansFollowing/UserCard";
import { connect, useDispatch, useSelector } from "react-redux";
// import { fetchFavStart } from "../../../store/actions/FavAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import useInfiniteScroll from "../../helper/useInfiniteScroll";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import FollowingLoader from "../../Loader/FollowingLoader";
import SearchInput from "../Searchinput/SearchInput";
import { fetchActiveFollowersStart } from "../../../store/actions/FollowAction";
import { fetchSubscribingStart } from "../../../store/actions/SubscribingAction";
import { fetchSubscribersStart } from "../../../store/actions/SubscriberAction";
import SubscriberActiveSec from "./SubscriberActiveSec";
import SubscriberListView from "./SubscriberListView";
import { getSubscriptionLevelsStart } from "../../../store/actions/SubscriptionAction";
import AddUserCustomFavourite from "../AddUserCustomFavourite/AddUserCustomFavourite";
import ColorTagCustomizeModal from "../ColorTagCustomization/ColorTagCustomizeModal";
import FilterSortingListUsers from "../FilterListUser/FilterSortingListUsers";

const SubscriberIndex = (props) => {
  const [userData, setUserData] = useState(props.subscribers?.data?.subscriber);

  const [list, setList] = useState(true);
  const [activeSec, setActiveSec] = useState("active-sec");
  const dispatch = useDispatch();
  const [subLevelOp, setSubLevelOp] = useState([])
  const [selectedTip, setSelectedTip] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterLevel, setFilterLevel] = useState([])  

  const [isSelected, setIsSelected] = useState(false)
  const [levelFilter, setLevelFilter] = useState([])

  const [isSelectedSubscriber, setIsSelectedSubscriber] = useState(false);
  const [checkedUserId, setCheckedUserId] = useState([])
  const [isAddUser, setIsAddUser] = useState(false)
  const [isTagUser, setIsTagUser] = useState(false);

  const { activeSubscribers } = useSelector((state) => state.subscriber);
  const { allSubLevelList } = useSelector((state) => state.subscriptions);



  const [isSelectedFavourite, setIsSelectedFavourite] = useState(false);

  const [isRemoveUser, setIsRemoveUser] = useState(false);

  const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);
  const [selectedColorTags, setSelectedColorTags] = useState(null);
  const [sortUsers, setSortUsers] = useState("");
	const [sortingToogle, setSortingToogle] = useState({
    aToz : false,
    upToDown : false
  })
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    setUserData(props.subscribers?.data?.subscriber);
  }, [props.subscribers?.data?.subscriber]);

  useEffect(() => {
    props.dispatch(fetchSubscribersStart({
      filterSort: {
        filter: isSelectedUserCate?.map((elem) => elem.payload).toString() || "",
        sort: sortUsers || "",
        tag_title: selectedColorTags?.name || "",
        tag_colour: selectedColorTags?.color_tag_code || "",
        category:
        sortUsers === "username"
        ? sortingToogle.aToz
        ? "ASC"
        : "DESC"
        : sortingToogle.upToDown
        ? "ASC"
        : "DESC",
    }}));
    setTimeout(() => {
      props.dispatch(getSubscriptionLevelsStart({ setFiltreTip: setSubLevelOp}));
    }, 700)
  }, [shouldReload, isSelectedUserCate, sortingToogle, sortUsers, selectedColorTags]);

  const toggleReload = () => setShouldReload(!shouldReload);

  const handleFilterUserData = (searchItem) => {

    if (levelFilter.length > 0) {
      const userLevelFilter =  props.subscribers?.data?.subscriber.filter(item => {
        return levelFilter.find(elem => elem === item?.subscription_level)
      })  

      const newUserLevelFilter = userLevelFilter.filter((user) => 
        user?.from_user?.name
          .toLowerCase()
          .includes(searchItem.toLowerCase())
      )

      if (newUserLevelFilter.length > 0) {
        setLoading(true)
        setFilterLevel(newUserLevelFilter)
      }
    
      if (newUserLevelFilter.length == 0) {
        setLoading(false)
      }
      return;
    } else {
      const filterUserData = props.subscribers?.data?.subscriber.filter(
        (user) => {
          return user?.from_user?.name
            .toLowerCase()
            .includes(searchItem.toLowerCase());
        }
      );
      setUserData(filterUserData);
    }
  };

  const handleSelectTip = (data) => {
    setIsSelected(true)

    setLevelFilter((prev) => [...prev, data.alias_of_level])
    setSelectedTip([...selectedTip, data]);

      
    const filterSubscriberLevel = props.subscribers?.data?.subscriber.filter(
      (user) => {
        return [...selectedTip, data].filter((item) => 
          user?.subscription_level === item?.alias_of_level
        )?.length ? true : false;
      } 
    )

    if (selectedTip.length > 0 && filterSubscriberLevel.length == 0) {
      setLoading(true)
    }

    const loadingBoolean = filterSubscriberLevel.length == 1
     ?
      true
      :
     selectedTip.length > 0 && filterSubscriberLevel.length == 0
      ?
      true
      :
      filterSubscriberLevel.length == 0
        ? 
        false
        : true

    if (loadingBoolean || !loadingBoolean) {
      setLoading(loadingBoolean)
    }

      setFilterLevel(filterSubscriberLevel)
  };


  const handleRemoveTip = (data) => {
    setIsSelected(false)
    const filterSubLevel = levelFilter.filter(elem => elem !== data?.alias_of_level)
    setLevelFilter(filterSubLevel)
    let updatedUserList = selectedTip.filter((item) => 
      item.id !== data.id
    )
    setSelectedTip(updatedUserList);
    const filterSubscriberLevel = props.subscribers?.data?.subscriber.filter(
      (user) => {
        return updatedUserList.filter((item) => 
          user?.subscription_level === item?.alias_of_level
        )?.length ? true : false;
      } 
    )

    if (filterSubLevel.length === 0) {
      setLoading(true);
    }

    setTimeout(() => {
      setFilterLevel(filterSubscriberLevel)
    },500)
  };

  // const changeSection = (event, type) => {
  //   setActiveSec(type);
  //   if (type === "active-sec") props.dispatch(fetchActiveFollowersStart());
  //   if (type === "expired-sec") props.dispatch(fetchExpiredFollowersStart());
  //   if (type === "all-sec") props.dispatch(fetchFollowersStart());
  // };

  const handleList = () => {
    setList(false);
  };

  const handleSelectAllUser = (e) => { 
    const allSelectedSubscribersUser = userData?.map((item) => {
      return {
        ...item,
        isSelected: e.target.checked
      }
    })
    
    if (e.target.checked === true) {
      const allSelectedCheckedUser = userData?.map(elem => elem?.from_user_id)
      setCheckedUserId(allSelectedCheckedUser)
    }
    
    if (e.target.checked === false) {
      setCheckedUserId([])
    }

    setUserData(allSelectedSubscribersUser)
  }

  const handleIsSelected = () => {
    setIsSelectedSubscriber((prev) => !prev)

    const allSelectedSubscribersUser = userData?.map((item) => {
      return {
        ...item,
        isSelected: false
      }
    })
    setCheckedUserId([])
    setUserData(allSelectedSubscribersUser)
  }

  const handleOpenAddUserModal = () => setIsAddUser(true)
  const handleCloseAddUserModal = () => setIsAddUser(false)

  const handleOpenRemoveUserTagModal = () => setIsTagUser(true);
  const handleCloseRemoveUserTagModal = () => setIsTagUser(false);

  let usersFilters = [
    { name: "All users", id: 1, label: "All users" , payload : "all_users" },
    { name: "All creators", id: 2, label: "All creators" , payload : "all_creator" },
    { name: "Star creator", label: "Star creator", payload: "" },
    { name: "Certified creator", id: 4, label: "Certified creator", payload : "all_certified" },
    { name: "Creator", id: 5, label: "Creator", payload : "creator" },
    { name: "All Non-creator", id: 6, label: "All Non-creator" , payload: "all_non_creator" },
    { name: "Suscribers", id: 7, label: "Suscribers" , payload: "subscriber" },
    { name: "Followers", id: 8, label: "Followers" , payload : "followers" },
    { name: "By color tag", id: 9, label: "By color tag" , payload: "by_color_tag" },
  ];

  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left">
                  <h3>
                    <Link
                      className="bookmarkes-list"
                      to={"#"}
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("subscribers")}
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="search-component" style={{gap: "10px"}}>
                {/* <NewHomeSearch desktop/> */}
                <SearchInput handleFilterUserData={handleFilterUserData} />
                <FilterSortingListUsers
                  isSelectedUserCate={isSelectedUserCate}
                  setIsSelectedUserCate={setIsSelectedUserCate}
                  selectedColorTags={selectedColorTags}
                  setSelectedColorTags={setSelectedColorTags}
                  sortUsers={sortUsers}
                  setSortUsers={setSortUsers}
                  sortingToogle={sortingToogle}
                  setSortingToogle={setSortingToogle}
                  usersFilters={usersFilters}
                />
                <div className="list-icons" onClick={() => setList(true)}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-microsoft"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z"
                    />
                  </svg>
                </div>
                <div className="list-icons" onClick={handleList}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-list-task"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                    />
                    <path
                      fill="#6661e7"
                      d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"
                    />
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div 
              className="select-button-container"
              style={{
                paddingBottom: "10px"
              }}
            >
              {userData?.length > 0 ? (
                <button className="select-btn" onClick={handleIsSelected}>
                  {isSelectedSubscriber ? t("Unselect") : t("Select")}
                </button>
              ) : (
                ""
              )}
              
              {
                isSelectedSubscriber
                  &&
                (
                  <label htmlFor="select-all-input" className="select-all-user">
                    <input 
                      type="checkbox"
                      checked={userData?.every(elem => elem?.isSelected)}
                      onChange={(e) => handleSelectAllUser(e)} 
                      className="select-all-input"
                      id="select-all-input"
                    />
                    {t("Select_all")}
                  </label>
                )
              }

              {
                userData?.some(elem => elem?.isSelected)
                  &&
                (
                  <>
                  <button 
                    className="Add-to-custom-favourite-list"
                    onClick={handleOpenAddUserModal}  
                  >
                    Add to
                  </button>

                  <button
                    className="color__tag__user"
                    onClick={handleOpenRemoveUserTagModal}
                  >
                    Tag by
                  </button>
                  </>
                )
              }
                  
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
            <div
              className="chips-container-list"
              style={{ display: "flex", gap: "5px" }}
            >
              {/* {selectedTip?.length > 0 && (
                <div className="active-chips-list-wrapper">
                  {selectedTip?.map((userL) => {
                    return (
                      <div
                        className="active-chips-list selected-active-tips"
                        style={{
                          width: "auto",
                          maxWidth: "100%",
                          gap: "30px",
                          cursor: "default",
                        }}
                      >
                        <div className="active-list-icon-text-wrapper">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={userL?.badge}
                            alt=""
                          />
                          <p style={{ margin: "0", fontSize: "13px" }}>
                            {userL?.alias_of_level}
                          </p>
                        </div>
                        <div
                          className="active-list-count-delete-w"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <p style={{ margin: "0", fontSize: "13px" }}>
                            (100)
                          </p>
                          <button
                            className="active-list-count-delete-btn"
                            onClick={() => handleRemoveTip(userL)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )} */}

              {subLevelOp.length > 0 && (
                <div className="active-chips-list-wrapper">
                  {subLevelOp.map((userL) => {
                    return (
                      <div
                        className={levelFilter.includes(userL.alias_of_level) ? `active-chips-list selected-active-tips` : `active-chips-list`}
                        onClick={() => levelFilter.includes(userL.alias_of_level) ? '' : handleSelectTip(userL)}
                      >
                        <div className="active-list-icon-text-wrapper">

                          <p style={{margin: "0", fontSize: "13px"}}>
                            {userL?.alias_of_level ? (
                                <>
                                  {userL.alias_of_level}{userL.new_alias_level ? `: ${userL.new_alias_level}` : ''}
                                </>
                            ) : null}
                          </p>
                        </div>
                        {
                          levelFilter.includes(userL.alias_of_level)
                              ? (
                                  <div
                                      className="active-list-count-delete-w"
                                      style={{
                                        display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: "13px" }}>
                                  ({userL?.total_user})
                                  {/* (100) */}
                                </p>
                                <button
                                  className="active-list-count-delete-btn"
                                  onClick={() => handleRemoveTip(userL)}
                                >
                                  X
                                </button>
                              </div>
                            ) 
                            : (
                              <p style={{ margin: "0", fontSize: "13px" }}>
                                ({userL?.total_user})
                                {/* (100) */}
                              </p>
                            )
                        }
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

              {levelFilter?.length > 0 && (
                <button
                  onClick={() => {
                    setLevelFilter([]);
                    setSelectedTip([]);
                    setFilterLevel([]);
                    setLoading(true)
                    dispatch(
                      getSubscriptionLevelsStart({
                        setFiltreTip: setSubLevelOp,
                      })
                    );
                  }}
                  style={{
                    all: "unset",
                    color: "#6661e7",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Reset all
                </button>
              )}
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
                <div className="tab-content tabs">
                  {
                  loading ?
                  levelFilter.length > 0 
                      ?
                      list ? (
                        <SubscriberActiveSec
                          activeSec={activeSec}
                          setActiveSec={setActiveSec}
                          subscribers={props.subscribers}
                          userData={filterLevel}
                          pagePath={"subscriber"}
                          isSelected={isSelectedSubscriber}
                          setUserData={setUserData}
                          setCheckedUserId={setCheckedUserId}
                          checkedUserId={checkedUserId}
                        />
                      ) : (
                        <SubscriberListView
                          activeSec={activeSec}
                          setActiveSec={setActiveSec}
                          subscribers={props.subscribers}
                          userData={filterLevel}
                        />
                      )
                      :
                    list ? (
                      <SubscriberActiveSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        subscribers={props.subscribers}
                        userData={userData}
                        pagePath={"subscriber"}
                        isSelected={isSelectedSubscriber}
                        setUserData={setUserData}
                        setCheckedUserId={setCheckedUserId}
                        checkedUserId={checkedUserId}
                      />
                    ) : (
                      <SubscriberListView
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        subscribers={props.subscribers}
                        userData={userData}
                      />
                    )
                  : <NoDataFound></NoDataFound>
                }
                </div>
              </div>
            </div>
            {
              isAddUser
               &&
              <AddUserCustomFavourite
                isAddUser={isAddUser}
                handleCloseAddUserModal={handleCloseAddUserModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelectedSubscriber}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                subscribersType={"subscribersType"}
              />
            }
            {isTagUser && (
              <ColorTagCustomizeModal
                isTagUser={isTagUser}
                handleCloseRemoveUserTagModal={handleCloseRemoveUserTagModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelectedSubscriber}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                listType={"subscribers"}
                onActionSuccess={toggleReload}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  subscribers: state.subscriber.subscribers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SubscriberIndex));
