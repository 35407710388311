import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
  fetchMySubscriptionSuccess,
  fetchMySubscriptionFailure,
  fetchSingleSubscriptionSuccess,
  fetchSingleSubscriptionFailure,
  subscriptionAutoRenewalSuccess,
  subscriptionAutoRenewalFailure,
  subscriptionPaymentStripeFailure,
  subscriptionPaymentStripeSuccess,
  subscriptionPaymentWalletSuccess,
  subscriptionPaymentWalletFailure,
  subscriptionPaymentPaypalSuccess,
  subscriptionPaymentPaypalFailure,
  subscriptionPaymentCCBillSuccess,
  subscriptionPaymentCCBillFailure,
  subscriptionPaymentCoinPaymentSuccess,
  subscriptionPaymentCoinPaymentFailure,
  subscriptionBadgeSuccess,
  subscriptionBadgeFailure,
  addSubscriptionLevelSuccess,
  addSubscriptionLevelFailure,
  getSubscriptionLevelsFailure,
  getSubscriptionLevelsSuccess,
  deleteSubscriptionLevelSuccess,
  deleteSubscriptionLevelFailure,
} from "../actions/SubscriptionAction";

import api from "../../Environment";
import {
  FETCH_SUBSCRIPTION_START,
  FETCH_MY_SUBSCRIPTION_START,
  FETCH_SINGLE_SUBSCRIPTION_START,
  SUBSCRIPTION_PAYMENT_STRIPE_START,
  SUBSCRIPTION_AUTO_RENEWAL_START,
  SUBSCRIPTION_PAYMENT_WALLET_START,
  SUBSCRIPTION_PAYMENT_PAYPAL_START,
  SUBSCRIPTION_PAYMENT_CCBILL_START,
  SUBSCRIPTION_PAYMENT_COINPAYMENT_START,
  SUBSCRIPTION_BADGE_START,
  ADD_SUBSCRIPTION_LEVEL_START,
  GET_SUBSCRIPTION_LEVELS_START,
  DELETE_SUBSCRIPTION_LEVEL_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { fetchHomePostsSuccess } from "../actions/HomeAction";

function* getSubscriptionAPI() {
  try {
    const response = yield api.postMethod("subscriptions_index");
    yield put(fetchSubscriptionSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* uploadSubscriptionBadge({data}) {
  
  try { 

    const response = data?.level_id !== null ?  yield api.postMethod("level_perk_upload",{file : data.file, upload_type : "badge" , level_id : data.level_id}) : yield api.postMethod("level_perk_upload",{file : data.file, upload_type : "badge"})

    if (response.data?.success) {
      yield put(subscriptionBadgeSuccess(response.data.data));
      data.setFieldValue("badgeVal", response.data?.data?.file);
    }   
  } catch (error) {
    yield put(subscriptionBadgeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* uploadSubscriptionLevel({payload}) {


  try { 
    const response = yield api.postMethod(
      "save_user_level",
      payload.subLevelData
    );

    if (response.data?.success) {
      yield put(addSubscriptionLevelSuccess(response.data?.data?.perk_data));
      if(payload.isLevelEditData?.id){
        const modifiedData = payload.allSubLevelList?.data?.data?.map((ele) => {
           
          if(ele?.id.toString() === response.data?.data?.perk_data.id.toString()){
         
             return {
              ...response.data?.data?.perk_data
             }

          }else{
              return ele
          }

        });

        yield put(getSubscriptionLevelsSuccess({count : response.data?.data?.count , data : modifiedData}));


      }else{
        yield put(getSubscriptionLevelsSuccess({count : response.data?.data?.count , data : [...payload.allSubLevelList?.data?.data, response.data?.data?.perk_data]}));
      }
     

      const notificationMessage = getSuccessNotificationMessage(response.data?.message);
      yield put(createNotification(notificationMessage));

    } 
   
     payload.handleClose();

  } catch (error) {
    payload.handleClose();
    yield put(addSubscriptionLevelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getSubscriptionLevels({payload}) {
  try { 
    const response = yield api.postMethod("list_user_level");

    if (response.data?.success) {
      yield put(getSubscriptionLevelsSuccess(response.data?.data));

      if(payload?.setFiltreTip !== undefined){

        payload.setFiltreTip(response.data?.data?.data);
      }

      if(payload?.setSubLevelOptions !== undefined){

        const filtredData = response.data?.data?.data.map((ele) => {
        return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id}
        
      })
        payload.setSubLevelOptions(filtredData);
      }
    } 
    
  } catch (error) {
    yield put(getSubscriptionLevelsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* deleteSubscriptionLevel({payload}) {
  try { 
    const response = yield api.postMethod("delete_user_level", { level_id :  payload.level_id });

    if (response.data?.success) {
      yield put(deleteSubscriptionLevelSuccess(response.data?.data));
      
      const filtredData = payload.allsubLevelData?.data?.data?.filter((ele)=>{
        
        return ele.id.toString() !== response.data?.data?.level_id
        
      });
    
      yield put(getSubscriptionLevelsSuccess({count:response.data?.data?.count , data : filtredData}));

      const notificationMessage = getSuccessNotificationMessage(response.data?.message);
      yield put(createNotification(notificationMessage));
    } 
   
     payload.handleClose();

  } catch (error) {
    payload.handleClose();
    yield put(deleteSubscriptionLevelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}



function* getMySubscriptionAPI() {
  try {
    const response = yield api.postMethod("subscriptions_history");
    yield put(fetchMySubscriptionSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchMySubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getSingleSubscriptionAPI() {
  try {
    const subscriptionInputData = yield select(
      (state) => state.subscriptions.singleSubInputData.data
    );
    console.log("subsc", subscriptionInputData);
    const response = yield api.postMethod(
      "subscriptions_view",
      subscriptionInputData
    );
    yield put(fetchSingleSubscriptionSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleSubscriptionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionPaymentStripeAPI({data}) {
  try {
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subPayStripe.inputData
    );

    const homePagePosts = yield select((state) => state.home.homePost.data)

    const newSubscriptionDetails = data?.pageType === "userCard" ? {user_unique_id: data?.user_unique_id, plan_type: data?.plan_type, is_free: data?.is_free} : subscriptioDetails
    const response = yield api.postMethod(
      "user_subscriptions_payment_by_stripe",
      newSubscriptionDetails
    );
    if (response.data.success) {
      yield put(subscriptionPaymentStripeSuccess(response.data.data));

      const updatedHomePagePost = homePagePosts?.posts?.map(item => {
        if (item?.user_id === response.data.data.user_id) {
          return {
            ...item,
            show_follow: 0,
            show_unfollow: 1
          }
        } else {
          return item
        }
      });

      yield put(fetchHomePostsSuccess(updatedHomePagePost))
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.setItem(
        "total_followers",
        JSON.stringify(response.data.data.total_followers)
      );
      localStorage.setItem(
        "total_followings",
        JSON.stringify(response.data.data.total_followings)
      );

      if (data?.page === "homepage") {
        return;
      }
      if (data?.pageType === "userCard") {
        window.location.reload()
        return;
      }
      window.location.assign(`${subscriptioDetails.user_unique_id}`);
    } else {
      yield put(subscriptionPaymentStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      // yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionPaymentStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionPaymentPaypalAPI() {
  try {
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subPayPaypal.inputData
    );
    const response = yield api.postMethod(
      "user_subscriptions_payment_by_paypal",
      subscriptioDetails
    );
    if (response.data.success) {
      yield put(subscriptionPaymentPaypalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      localStorage.setItem(
        "total_followers",
        JSON.stringify(response.data.data.total_followers)
      );
      localStorage.setItem(
        "total_followings",
        JSON.stringify(response.data.data.total_followings)
      );
      window.location.assign(`${subscriptioDetails.user_unique_id}`);
    } else {
      yield put(subscriptionPaymentPaypalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionPaymentPaypalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionPaymentWalletAPI({data}) {
  try {
    // console.log(payload)
    console.log(data)
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subPayWallet.inputData
    );

    const homePagePosts = yield select((state) => state.home.homePost.data)

    const subscriptionNewDetails = data?.pageType === "userCard" 
                                      ? {is_free : data?.is_free, plan_type: data?.plan_type, user_unique_id: data?.user_unique_id}
                                      : subscriptioDetails
    const response = yield api.postMethod(
      "user_subscriptions_payment_by_wallet",
      subscriptionNewDetails
    );

    if (response.data.success) {
      yield put(subscriptionPaymentWalletSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );

      if (data?.page === "homepage") {
        const updatedHomePagePost = homePagePosts?.posts?.map(item => {
          if (item?.user_id === Number(response.data.data.to_user_id)) {
            return {
              ...item,
              payment_info: {
                ...item.payment_info,
                subscription_info: {
                  ...item.payment_info.subscription_info
                },
                unsubscribe_btn_status: 1
              }
            }
          } else {
            return item
          }
        });
        yield put(fetchHomePostsSuccess(updatedHomePagePost));
      }
      
      yield put(createNotification(notificationMessage));
      localStorage.setItem(
        "total_followers",
        JSON.stringify(response.data.data.total_followers)
      );
      localStorage.setItem(
        "total_followings",
        JSON.stringify(response.data.data.total_followings)
      );

      if (data?.page === "homepage") {
        return;
      }

      if (data?.pageType === "userCard") {
        window.location.reload()
        return
      }
      window.location.assign(`${subscriptioDetails.user_unique_id}`);
    } else {
      yield put(subscriptionPaymentWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionPaymentWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionAutoRenewalAPI() {
  try {
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subscriptionRenew.inputData
    );
    const response = yield api.postMethod(
      "subscriptions_autorenewal_status",
      subscriptioDetails
    );
    yield put(subscriptionAutoRenewalSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(subscriptionAutoRenewalFailure(response.data.error));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionAutoRenewalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionPaymentCCBillAPI() {
  try {
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subPayCCBill.inputData
    );
    const response = yield api.postMethod(
      "user_subscriptions_payment_by_ccbill",
      subscriptioDetails
    );
    if (response.data.success) {
      yield put(subscriptionPaymentCCBillSuccess(response.data.data));
      window.location.assign(`${response.data.data.redirect_web_url}`);
    } else {
      yield put(subscriptionPaymentCCBillFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionPaymentCCBillFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subscriptionPaymentCoinPaymentAPI() {
  try {
    const subscriptioDetails = yield select(
      (state) => state.subscriptions.subPayCoinPayment.inputData
    );
    const response = yield api.postMethod(
      "user_subscriptions_payment_by_coinpayment",
      subscriptioDetails
    );
    if (response.data.success) {
      yield put(subscriptionPaymentCoinPaymentSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      setTimeout(() => {
        window.location.assign(`${response.data.data.redirect_web_url}`);
      }, 3000);
    } else {
      yield put(subscriptionPaymentCoinPaymentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(subscriptionPaymentCoinPaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SUBSCRIPTION_START, getSubscriptionAPI)]);
  yield all([yield takeLatest(SUBSCRIPTION_BADGE_START, uploadSubscriptionBadge)]);
  yield all([yield takeLatest(ADD_SUBSCRIPTION_LEVEL_START, uploadSubscriptionLevel)]);
  yield all([yield takeLatest(GET_SUBSCRIPTION_LEVELS_START, getSubscriptionLevels)]);
  yield all([yield takeLatest(DELETE_SUBSCRIPTION_LEVEL_START, deleteSubscriptionLevel)]);
  yield all([
    yield takeLatest(FETCH_MY_SUBSCRIPTION_START, getMySubscriptionAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_SUBSCRIPTION_START, getSingleSubscriptionAPI),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_PAYMENT_STRIPE_START,
      subscriptionPaymentStripeAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_PAYMENT_WALLET_START,
      subscriptionPaymentWalletAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_AUTO_RENEWAL_START,
      subscriptionAutoRenewalAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_PAYMENT_PAYPAL_START,
      subscriptionPaymentPaypalAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_PAYMENT_CCBILL_START,
      subscriptionPaymentCCBillAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      SUBSCRIPTION_PAYMENT_COINPAYMENT_START,
      subscriptionPaymentCoinPaymentAPI
    ),
  ]);
}
