import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import {
  addMultiUserTagStart,
  saveTagUntagUserStart,
} from "../../../store/actions/UserAction";
import { components } from "react-select";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  getUserAllColorTagStart,
  getUserAllColorTagSuccess,
} from "../../../store/actions/CustomListAction";
import "./ColorTagCustomizeModal.css";
import * as Yup from "yup";
import Environment from "../../../Environment";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { t } from "react-multi-lang";

const Option = (props) => (
  <div className="post-level-list-option-wrapper">
    <components.Option {...props}>
      <div className="search-user-list-option-label-wrapper">
        <div
          style={{
            backgroundColor: `${props.data?.color_tag_code}`,
            height: "10px",
            width: "10px",
            borderRadius: "50%",
          }}
        ></div>
        <label style={{ marginBottom: "0px" }}>{props.label}</label>
      </div>
    </components.Option>
  </div>
);

const checkTagUntagUser = Yup.object().shape({
  tagName: Yup.string().required("Tag is required"),
});

const tags = [
  { id: 1, name: "Blue", color_tag_code: "#1489d7" },
  { id: 2, name: "Green", color_tag_code: "#2ecc71" },
  { id: 3, name: "Red", color_tag_code: "#e74c3c" },
  { id: 4, name: "Yellow", color_tag_code: "#fffa07" },
  { id: 5, name: "Orange", color_tag_code: "#edbc35" },
  { id: 6, name: "purple", color_tag_code: "#cd63f3" },
  { id: 7, name: "Gray", color_tag_code: "#a4a4a7" },
];

const ColorTagCustomizeModal = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedColorTags, setSelectedColorTags] = useState(null);
  const [isCustomize, setIsCustomize] = useState(false);
  const [isCustomColorOpen, setIsCustomColorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    isTagUser,
    handleCloseRemoveUserTagModal,
    checkedUserId = [], // Fallback as empty array to prevent errors
    listType,
    setIsSelected,
    setUserData,
    userData = [], // Fallback as empty array
    setCheckedUserId,
    onActionSuccess,
  } = props;

  const { data: colorTagData = [], loading: colorTagLoader } = useSelector(
    (state) => state.customList.userAllColorTag || {}
  ); // Ensure colorTagData is at least an empty array
  // const isLoading = useSelector((state) => state.users.tagMultiUser.loading);

  const initialTagUntagValues = {
    tagName: selectedColorTags?.name || "",
    colorTag: selectedColorTags?.color_tag_code || "#ae2929",
    master_tag_id: selectedColorTags?.value || "",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAllColorTagStart());
  }, [dispatch]);

  const handleDeleteTag = async () => {
    if (selectedColorTags) {
      const tag_colour_code = selectedColorTags.color_tag_code;
      const tag_title = selectedColorTags.label;
      const tag_id = selectedColorTags?.value;
      try {
        setIsLoading(true);
        const response = await Environment.postMethod(
          "deleteTag",
          { tag_title, tag_colour_code, tag_id },
          false
        );

        if (response.data?.success) {
          setSelectedColorTags(null);
          console.log("Tag deleted successfully:", response.data);

          const notificationMessage = getErrorNotificationMessage(
            response.data?.message
          );
          dispatch(createNotification(notificationMessage));

          setIsLoading(false);
          const filteredColorData = colorTagData?.filter(
            (item) => item.id !== response.data?.data.id
          );
          dispatch(getUserAllColorTagSuccess(filteredColorData));
          setIsDelete(false);
          // handleCloseRemoveUserTagModal(true);
          setIsEdit(false);
          setIsCustomColorOpen(true);
          setIsAdd(false);
          if (onActionSuccess) onActionSuccess();
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error deleting tag:", error);
      }
    }
  };

  return (
    <Modal
      show={isTagUser}
      onHide={handleCloseRemoveUserTagModal}
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") === "dark" ? "dark-theme-modal" : ""
      }`}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialTagUntagValues}
        validationSchema={checkTagUntagUser}
        onSubmit={(values) => {
          if (values.tagName !== "") {
            const payload = {
              user_id: checkedUserId.toString(),
              master_tag_id: values.master_tag_id,
              tag_title: values.tagName,
              tag_colour_code: values.colorTag,
              listType: listType,
              handleCloseRemoveUserTagModal: handleCloseRemoveUserTagModal,
              setIsSelected: setIsSelected,
              setUserData: setUserData,
              userData: userData,
              setCheckedUserId: setCheckedUserId,
              setIsCustomColorOpen: setIsCustomColorOpen,
              setIsEdit: setIsEdit,
              setIsDelete: setIsDelete,
              setSelectedColorTags: setSelectedColorTags,
              setIsAdd: setIsAdd,
              setIsLoading: setIsLoading,
            };

            setIsLoading(true);

            dispatch(addMultiUserTagStart(payload));

            // Trigger callback after Add/Edit
            if (onActionSuccess) {
              onActionSuccess();
              // handleCloseRemoveUserTagModal(true);
            }
          }
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              {/* <Modal.Title>{isEdit ? "Edit Tag" : "Add Tag"}</Modal.Title> */}
            </Modal.Header>

            <Modal.Body>
              <div className="tag-container">
                {colorTagData && colorTagData.length > 0 ? (
                  <>
                    <div
                      className="action-container"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >

                      {!isCustomize && (
                        <button
                          className="customize__btn"
                          onClick={() => {
                            setIsCustomize(true);
                            setIsCustomColorOpen(true);
                          }}
                        >
                          Customize
                        </button>
                      )}
                      {isCustomize && !isDelete && !isEdit && !isAdd && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                        <h4 style={{fontWeight: "700"}}>Customize tag</h4>
                        <button
                          style={{
                            backgroundColor: "#ede9e9",
                            color: "#000000",
                            border: "none",
                            padding: "7px 14px",
                            borderRadius: "5px",
                            fontWeight: 500,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setIsAdd(true);
                            setIsEdit(false);
                            setIsCustomColorOpen(false);
                            setIsDelete(false);
                          }}
                        >
                          <i
                            className="fa fa-plus pr-2"
                            aria-hidden="true"
                            style={{
                              color: "#8c2be2",
                              cursor: "pointer",
                            }}
                          />
                          Add new color tag
                        </button>
                        </div>
                      )}
                    </div>
                    <div className="field-container">
                      <div className="label-container">
                        {isDelete || isEdit
                          ? ""
                          : !isAdd && (
                              <label htmlFor="tagName">Select a tag</label>
                            )}
                      </div>
                      {isCustomColorOpen && isCustomize && (
                        <div className="custom__default__color">
                          {colorTagData.length > 0 &&
                            colorTagData.map((tag, i) => (
                              <div
                                key={i}
                                className="default__color_field"
                                style={
                                  i % 2 === 0
                                    ? { backgroundColor: "#f7f7f7" }
                                    : { backgroundColor: "#ede9e9" }
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: tag.tag_colour_code,
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                    }}
                                  ></div>
                                  <span
                                    style={{
                                      margin: "0",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {tag.tag_title}
                                  </span>
                                </div>

                                <div>
                                  <i
                                    className="fa fa-pencil-square-o pr-2"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#8c2be2",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIsEdit(true);
                                      setIsAdd(false);
                                      setIsCustomColorOpen(false);
                                      setIsDelete(false);
                                      setSelectedColorTags({
                                        name: tag.tag_title,
                                        label: tag.tag_title,
                                        value: tag.id,
                                        color_tag_code: tag.tag_colour_code,
                                      });
                                    }}
                                  />

                                  <i
                                    className="fa fa-trash pr-2"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#8c2be2",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIsEdit(false);
                                      setIsAdd(false);
                                      setIsCustomColorOpen(false);
                                      setIsDelete(true);
                                      setSelectedColorTags({
                                        name: tag.tag_title,
                                        label: tag.tag_title,
                                        value: tag.id,
                                        color_tag_code: tag.tag_colour_code,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      )}

                      {isDelete ? (
                        <>
                          <h5
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Delete this tag?
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              backgroundColor: "#ede9e9",
                              padding: "4px",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: initialTagUntagValues.colorTag,
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <span
                              style={{
                                margin: "0",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {initialTagUntagValues.tagName}
                            </span>
                          </div>
                        </>
                      ) : (
                        colorTagData.length > 0 &&
                        !isAdd &&
                        !isCustomColorOpen &&
                        !isEdit && (
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={colorTagLoader}
                            isLoading={colorTagLoader}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            components={{
                              Option,
                              animatedComponents: makeAnimated(),
                            }}
                            placeholder="Color tag"
                            name="color"
                            options={colorTagData?.map((ele) => ({
                              name: ele.tag_title,
                              label: ele.tag_title,
                              value: ele.id,
                              color_tag_code: ele.tag_colour_code,
                            }))}
                            onChange={(selected) =>
                              setSelectedColorTags(selected)
                            }
                          />
                        )
                      )}
                    </div>

                    {(isEdit || isAdd) && (
                      <div className="color__tag__input">
                        {isEdit ? (
                          <div
                            className="field-container"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4 style={{fontWeight: "700"}}>Edit</h4>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                backgroundColor: "#ede9e9",
                                padding: "4px",
                                borderRadius: "4px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    initialTagUntagValues.colorTag,
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                              <span
                                style={{
                                  margin: "0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {initialTagUntagValues.tagName}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isAdd ? <h4 style={{fontWeight: "700"}}>New Tag</h4> : ""}
                        <div className="field-container">
                          <label htmlFor="tagName">Tag name</label>
                          <Field
                            name="tagName"
                            id="tagName"
                            className="add-tag"
                          />
                          {errors.tagName && touched.tagName && (
                            <p
                              style={{
                                color: "rgb(255, 0, 0)",
                                fontSize: "13px",
                                fontWeight: "400",
                                marginTop: "4px",
                              }}
                            >
                              {errors.tagName}
                            </p>
                          )}
                        </div>
                        <div className="field-container">
                          <label htmlFor="colorTag">Choose color:</label>
                          <Field type="color" name="colorTag" id="colorTag" />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="color__tag__input">
                    <div className="field-container">
                      <label htmlFor="tagName">Tag name</label>
                      <Field name="tagName" id="tagName" className="add-tag" />
                      {errors.tagName && touched.tagName && (
                        <p
                          style={{
                            color: "rgb(255, 0, 0)",
                            fontSize: "13px",
                            fontWeight: "400",
                            marginTop: "4px",
                          }}
                        >
                          {errors.tagName}
                        </p>
                      )}
                    </div>
                    <div className="field-container">
                      <label htmlFor="colorTag">Choose color:</label>
                      <Field type="color" name="colorTag" id="colorTag" />
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                size="lg"
                onClick={() => {
                  if (isEdit || isDelete || isAdd) {
                    setIsEdit(false);
                    // setIsAdd(false);
                    setIsCustomColorOpen(true);
                    setSelectedColorTags(null);
                    setIsDelete(false);
                    setIsAdd(false);
                  } else {
                    props.handleCloseRemoveUserTagModal();
                  }
                }}
              >
                {isEdit || isDelete || isAdd ? "Cancel" : "Close"}
              </Button>
              {isDelete ? (
                <Button
                  variant="danger"
                  disabled={isLoading}
                  size="lg"
                  onClick={handleDeleteTag}
                >
                  {isLoading ? "Loading..." : "Delete"}
                </Button>
              ) : isCustomColorOpen ? (
                <Button
                  size="lg"
                  className="ftr-btn"
                  type="submit"
                  onClick={() => {
                    setIsCustomize(false);
                    setIsCustomColorOpen(false);
                  }}
                  // disabled={isLoading}
                >
                  {"Back"}
                </Button>
              ) : (
                <Button
                  size="lg"
                  className="ftr-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : isEdit ? "Save" : !isCustomize ? "Confirm" : "Add"}
                </Button>
              )}
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ColorTagCustomizeModal;
