import React from "react";
import { Image } from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";

const NotificationRefferalSec = (props) => {
  const { notifications, totalNotifications, notificatoin } = props;

  return (
    <>

      <div
        role="tabpanel"
        className={
          props.activeSec === "refferal"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="Section8"
      >
        <div className="notification-list">
          {notifications?.length > 0 ? (
            <InfiniteScroll
              dataLength={notifications.length}
              next={props.fetchMoreData}
              hasMore={
                notifications.length < totalNotifications &&
                notificatoin.errorCount < 2
              }
              loader={<h4>{t("loading")}</h4>}
            >
              {notifications.map((notification) => (
                <div
                  key={notification?.bell_notification_id}
                  className="notify-item"
                >
                  <div className="post-header">
                    <div className="alignleft">
                      <a
                        className="title-container"
                        href={notification.action_url}
                        target="_blank"
                      >
                        <Image
                          src={notification.from_userpicture}
                          className="user-image img-responsive notification-user-img "
                        />
                        <div className="user-name">
                          <span className="post-user-name">
                            {/* {notification.from_displayname}{" "} */}
                            <span style={{ display: "flex", gap: "7px" }}>
                              {notification.from_username}
                              {notification.from_user
                                .is_certified_content_creator === 2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}
                            </span>
                            {notification.from_user.is_verified_badge == 1 ? (
                              <div className="pl-2">
                                <VerifiedBadgeNoShadow />
                              </div>
                            ) : null}
                            {/* <span className="user-id">
																	<Link
																		target="_blank"
																		to={notification.from_username}
																	>
																		@{notification.from_username}
																	</Link>
																</span> */}
                          </span>
                          <span className="post-user-notify">
                            {notification.message}
                          </span>
                          <span className="post-user-notify-date">
                            {notification.updated_formatted}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <NoDataFound></NoDataFound>
          )}
        </div>
      </div>
    </>
  );
};

export default translate(NotificationRefferalSec);
