import React from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { useSelector } from "react-redux";

const NotificationTabSec = (props) => {

  const getFriendNotification = useSelector(state => state.customList.customeListUtils)

  const { notifications } = props;
  const hasUnreadNotifications = (type) => {
    if (!Array.isArray(notifications)) return false;
    return notifications.some(
      (notification) =>
        notification.notification_type === type && notification.is_read === 1
    );
  };
  console.log("notification", notifications)
  return (
    <div className="tabbable-line notify-sec">
      <ul className="nav nav-tabs" role="tablist">
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "notify-all" ? "active" : ""}
        >
          <Link
            to="#Section1"
            aria-controls="home"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "notify-all")}
          >
            <span>
              <Image src="assets/images/icons/all.svg" className="svg-clone" />
            </span>
            {t("all")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "comment" ? "active" : ""}
        >
          <Link
            to="#Section2"
            aria-controls="profile"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "comment")}
          >
            <span>
            {hasUnreadNotifications("comment") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image
                src="assets/images/icons/comment.svg"
                className="svg-clone"
              />
            </span>
            {t("comments")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "liked-sec" ? "active" : ""}
        >
          <Link
            to="#Section3"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "like")}
          >
            <span>
            {hasUnreadNotifications("like") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image
                src="assets/images/icons/heart.svg"
                className="svg-clone"
              />
            </span>
            {t("liked")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "subscribed-sec" ? "active" : ""}
        >
          <Link
            to="#Section4"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "follow")}
          >
            <span>
            {hasUnreadNotifications("follow") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image
                src="assets/images/icons/unlock.svg"
                className="svg-clone"
              />
            </span>
            {t("followed")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "tips" ? "active" : ""}
        >
          <Link
            to="#Section5"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "tips")}
          >
            <span>
            {hasUnreadNotifications("tips") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image src="assets/images/icons/tips-theme.svg" className="svg-clone" />
            </span>
            {t("tipped")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "subscription" ? "active" : ""}
        >
          <Link
            to="#Section6"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "subscription")}
          >
            <span>
            {hasUnreadNotifications("subscription") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image src="assets/images/icons/subscribe.svg" className="svg-clone" />
            </span>
            {t("subscribed")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "friends" ? "active" : ""}
        >
          <Link
            to="#Section7"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "friends")}
          >
            <span>
            {hasUnreadNotifications("friends") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image src="assets/images/icons/friends.png" className="svg-clone" />
            </span>
            {t("friends")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "refferal" ? "active" : ""}
        >
          <Link
            to="#Section8"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "refferal")}
          >
            <span>
            {hasUnreadNotifications("refferal") && (
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "7px",
                    background: "red",
                    right: "2px",
                    top: "2px",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              <Image src="assets/images/icons/referral-friend.svg" className="svg-clone" />
            </span>
            {t("referral")}
          </Link>
        </Media>

        <Media
            as="li"
            role="presentation"
            className={props.activeSec === "subscription-price-adjustment" ? "active" : ""}
        >
          <Link
              to="#subscriptionSection"
              aria-controls="subscription-price-adjustment"
              role="tab"
              data-toggle="tab"
              onClick={(event) => props.changeSection(event, "subscription-price-adjustment")}
          >
            <span>
            {hasUnreadNotifications("subscription-price-adjustment") && (
                <div
                    style={{
                      position: "absolute",
                      height: "7px",
                      width: "7px",
                      background: "red",
                      right: "2px",
                      top: "2px",
                      borderRadius: "50%",
                    }}
                ></div>
            )}
              <Image src="assets/images/icons/subscription-changed.svg" className="svg-clone" />
            </span>
            {t("subscription_changed")}
          </Link>
        </Media>
        
        {/* <Media
          as="li"
          role="presentation"
          className={props.activeSec === "video-call" ? "active" : ""}
        >
          <Link
            to="#Section6"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "video-call")}
          >
            <span>
              <Image
                src={window.location.origin + "/assets/images/icons/video.svg"}
                className="svg-clone"
              />
            </span>
            {t("video_calls")}
          </Link>
        </Media>
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "audio-call" ? "active" : ""}
        >
          <Link
            to="#Section7"
            aria-controls="messages"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "audio-call")}
          >
            <span>
              <Image
                src={window.location.origin + "/assets/images/icons/audio.svg"}
                className="svg-clone"
              />
            </span>
            {t("audio_calls")}
          </Link>
        </Media> */}
      </ul>
    </div>
  );
};

export default translate(NotificationTabSec);
