import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import "./NewChat.css";
import { Link, useHistory } from "react-router-dom";
import NewChatUploadModal from "./NewChatUploadModal";
import AltraChatAudioPlayer from "../CustomComponents/AudioPlayer/AltraChatAudioPlayer";
import SendChat from "./SendChat";
import ReceivedChat from "./ReceivedChat";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import FancyBox from "../NewHome/NewSingleView/FancyBox";
import {
  changeChatAudio,
  fetchChatMessagesStart,
  fetchMoreChatMessagesStart,
  setRemoveRedDotForwardChatMessage,
  updateChatMessagesSuccess,
} from "../../store/actions/ChatAction";
import InfiniteScroll from "react-infinite-scroll-component";
import configuration from "react-global-configuration";
import io from "socket.io-client";
import dayjs from "dayjs";
import PrivateAudioCallModal from "../helper/PrivateAudioCallModal";
import PrivateCallModal from "../helper/PrivateCallModal";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import NewChatUserInfo from "./NewChatUserInfo";
import useWindowDimensions from "../helper/WindowHelper";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import SendTipPaymentModal from "../Model/PaymentModal/SendTipPaymentModal";
import NewppvChatRequest from "./NewppvChatRequest";
import { RejectppvRequest } from "./RejectppvRequest";

let chatSocket;

const NewChatRoom = (props) => {
  const history = useHistory();
  const { height, width } = useWindowDimensions();

  const userId = localStorage.getItem("userId");

  const chatSocketUrl = configuration.get("configData.chat_socket_url");
  const [skipRender, setSkipRender] = useState(true);
  const [requestVideoCall, setRequestVideoCall] = useState(false);
  const [requestAudioCall, setRequestAudioCall] = useState(false);
  const [newChatUpload, setNewChatUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [isChat, setIsChat] = useState(true);
  const [newMsg, setNewMsg] = useState(false);
  const [sendTip, setTipModal] = useState(false);

  const [newPpvModel, setNewPpvModel] = useState(false);
  const [rejectPpvModel, setRejectPpvModel] = useState(false);

  const [ppvRequest, setPpvRequest] = useState(false)
  const [chatMessageId, setChatMessageId] = useState(null)

  const [rejectPpvContent , setRejectPppvContent] = useState(false)

  const messageField = useRef();
  const latest = useRef();

  let chatDate = "";
  let index = 0;


  const closeSendTipModal = () => {
    setTipModal(false);
  };


  useEffect(() => {
    if (width >= 992) {
      setIsChat(true);
    }
  }, [width]);

  useEffect(() => {
    if (newMsg) {
      const rect = latest.current.getBoundingClientRect();
      const container = document
        .getElementById("scrollableDiv")
        .getBoundingClientRect();
      if (rect.bottom > container.bottom + 10) {
        // showNewMsg(true);
        // const notificationMessage = getSuccessNotificationMessage("New Message Received");
        // props.dispatch(createNotification(notificationMessage));
      }
    }
    setNewMsg(false);
  }, [newMsg]);

  useEffect(() => {
    props.dispatch(
      fetchChatMessagesStart({
        payload: {

          from_user_id: userId,
          to_user_id: props.selectedUser.user_id,
        },
        chatUsers : props.chatUsers.data
      })
    );

    if (chatSocket) {
      chatSocket.disconnect();
    }
    chatSocketConnect(props.selectedUser.user_id);
    setIsChat(true);
    return () => {
      chatSocket.disconnect();
    };
  }, [props.selectedUser.user_id]);

  useEffect(() =>{
    props.dispatch(setRemoveRedDotForwardChatMessage({
      from_user_id: userId,
      to_user_id: props.selectedUser.user_id,
      chatUsers : props.chatUsers.data
    }))
  }, [props.selectedUser])

  useEffect(() => {
    if (!skipRender) {
      messageField.current.selectionEnd = cursorPointer;
    }
  }, [cursorPointer]);

  const fetchMoreMessages = () => {
    props.dispatch(
      fetchMoreChatMessagesStart({
        skip: props.chatMessages.data.messages.length,
        take: 12, 
        from_user_id: userId,
        to_user_id: props.selectedUser.user_id,
      })
    );
  };

  useEffect(() => {
    if (!skipRender && !props.chatMessages.loading) {
      if (!Object.keys(props.chatMessages.data).length > 0) {
        props.setShowContent(false);
      }
    }
    setSkipRender(false);
  }, [props.chatMessages]);

  const closeNewChatUploadModal = () => {
    setNewChatUpload(false);
  };

  const closePpvRequest = () => {
    setPpvRequest(false)
    
    setChatMessageId(null)
  }

  const closeRejectPpvContent = () => {
    setRejectPppvContent(false)

    setChatMessageId(null)
  }

  const closePpvRequestModal = () => {
    setNewPpvModel(false);
  }

  const closeRejectPpvModal = () => {
    setRejectPpvModel(false)

    setChatMessageId(null)
  }

  const closePrivateCallModal = () => {
    setRequestVideoCall(false);
    setRequestAudioCall(false);
  };

  const handleBlockUser = () => {
    props.dispatch(saveBlockUserStart({ user_id: props.selectedUser.user_id }));
  };

  const chatSocketConnect = (to_user_id) => {
    // check the socket url is configured
    console.log("chatSocket", chatSocketUrl);
    console.log("Input ID", to_user_id);
    if (chatSocketUrl) {
      chatSocket = io(chatSocketUrl, {
        query: `commonid:'user_id_${userId}_to_user_id_${props.selectedUser.user_id}',myid:${userId}`,
      });
      console.log("chatSocket", chatSocket);
      chatSocket.emit("update sender", {
        commonid: `user_id_${userId}_to_user_id_${props.selectedUser.user_id}`,
        myid: userId,
      });
      chatSocket.on("message", (newData) => {
        console.log(newData);
        setNewMsg(true);
        props.dispatch(updateChatMessagesSuccess(newData));
      });
    }
  };

  // Message Send
  const handleMessageSubmit = ({
    msgAmount = 0,
    fileType = "text",
    chatAssets = [],  
    ppv_amount = 0,
    ppvRejectStatus = 0,
    ppvAcceptStatus = 0
  }) => {

    if ((message && message.trim()) || chatAssets.length > 0 || ppvRejectStatus) {
      const now = new Date();
      const date = `${("0" + now.getDate()).slice(-2)} ${now.toLocaleString(
        "default",
        { month: "short" }
      )} ${now.getFullYear()}`;
      const time = dayjs(now).format("hh:mm a");

      const chatData = {
        from_user_id: userId,
        to_user_id: props.selectedUser.user_id,
        message: ppvRejectStatus === 2 
                      ? message === ''
                          ? `PPV request has been rejected`
                          : `Rejected reason: ${message}`
                      : ppvRejectStatus === 5
                        ? `PPV content has been rejected`
                        : message,
        amount: msgAmount,
        ppv_amount: ppv_amount,
        ppv_status: ppvAcceptStatus === 1
                      ? 1 
                      : ppvRejectStatus === 2
                        ? 2
                        : ppvRejectStatus === 5
                          ? 5
                          : 0,
        send_type: ppvAcceptStatus === 1 
                    ? 'receiver'  
                    : ppv_amount > 0 ? 'sender' : '',
        is_user_needs_pay: msgAmount > 0 ? 1 : 0,
        file_type: (ppv_amount || ppvRejectStatus) > 0 ? 'ppv_content' : fileType,
        loggedin_user_id: userId,
        chat_asset_id: chatAssets
          .map((asset) => asset.chat_asset_id)
          .toString(),
        date_formatted: date,
        time_formatted: time,
        amount_formatted:
          msgAmount + " " + configuration.get("configData.token_symbol"),
      };

      chatSocket.emit("message", chatData);

      setMessage("");

      if (chatMessageId) {
        props.dispatch(
          updateChatMessagesSuccess({
            data: {
              ...chatData, 
              chat_assets: chatAssets
            },
            chatMessageId: chatMessageId
          })
        );

        setChatMessageId(null)
      } else if (ppv_amount) {
        props.dispatch(
          updateChatMessagesSuccess({ 
            ...chatData,
            is_ppv_btn_disable: ppv_amount > 0 ? 1 : 0, 
            chat_assets: chatAssets 
          })
        );
      }else {
        props.dispatch(
          updateChatMessagesSuccess({ 
            ...chatData,
            chat_assets: chatAssets 
          })
        );
      }
      setNewChatUpload(false);
      setShowEmojis(false);
      messageField.current.focus();
      latest.current.scrollIntoView();
    }
  };

  const handleToggleEmojis = () => {
    messageField.current.focus();
    setShowEmojis(!showEmojis);
  };

  const onEmojiPick = (data) => {
    const ref = messageField.current;
    ref.focus();
    const start = message.substring(0, ref.selectionStart);
    const end = message.substring(ref.selectionStart);
    const text = start + data.native + end;
    setMessage(text);
    setCursorPointer(start.length + data.native.length);
  };

  if (
    props.chatMessages.data.messages &&
    props.chatMessages.data.messages.length > 0
  ) {
    chatDate = props.chatMessages.data.messages[0].date_formatted;
    index = props.chatMessages.data.messages.length;
  }

  const updateChatDate = (newDate) => {
    chatDate = newDate;
    index--;
  };

  return (
    <>
      <div className="new-chat-room-header-sec">
        <div
          className="new-chat-room-user-details"
          onClick={(e) =>
            width < 992 ? setIsChat(!isChat) : e.preventDefault()
          }
        >
          <div
            className="back-btn-mobile-show"
            onClick={() => history.push("/inbox")}
          >
            <Image
              className="back-btn-mobile"
              src={
                window.location.origin + "/assets/images/new-chat/back-icon.svg"
              }
            />
            
          </div>
          <div className="new-chat-room-user-img-sec">
            <CustomLazyLoad
              src={props.selectedUser.picture}
              className={"new-chat-room-user-img"}
            />
          </div>
          <div className="new-chat-room-user-name">
          <span style={{display: "flex", gap: "7px"}}>
                <h4>{props.selectedUser.name}</h4>
                            {props.selectedUser.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  style={{ marginBottom: "2px" , fontSize: "16px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}

                            </span>
           
            {/* {props.chatMessages.loading ?
              <>
                {props.selectedUser.is_online_status == 1 ?
                  <p>{props.selectedUser.is_user_online == 1 ? "Online" : "Offline"}</p>
                  : ""}
              </>
              :  */}
            <>
              {props.chatMessages.data.user &&
                props.chatMessages.data.user.is_online_status == 1 ? (
                <p>
                  {props.chatMessages.data.user.is_user_online == 1
                    ? "Online"
                    : "Offline"}
                </p>
              ) : (
                ""
              )}
            </>
          </div>
        </div>
        <div className="new-chat-room-user-action-btn-sec">
          <ul className="new-chat-room-user-action-btn-list list-unstyled">
            {configuration.get(
              "configData.is_private_call_feature_enable"
            ) == 1 ? (
              <>
                <Media as="li">
                  <Link to="#" onClick={() => setRequestAudioCall(true)}>
                    <Image
                      className="new-chat-room-user-action-icon"
                      src={
                        window.location.origin +
                        "/assets/images/new-chat/audio-call.svg"
                      }
                    />
                  </Link>
                </Media>
                <Media as="li">
                  <Link to="#" onClick={() => setRequestVideoCall(true)}>
                    <Image
                      className="new-chat-room-user-action-icon"
                      src={
                        window.location.origin +
                        "/assets/images/new-chat/video-call.svg"
                      }
                    />
                  </Link>
                </Media>
              </>
            ) : null}
            <Media as="li">
              <Link to="#">
                <Dropdown className="new-chat-room-dropdown">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="new-chat-room-dropdown-btn"
                  >
                    <Image
                      className="three-dots-icon"
                      src={
                        window.location.origin +
                        "/assets/images/new-chat/three-dots.svg"
                      }
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link
                      className="dropdown-item"
                      to={`/${props.selectedUser.user_unique_id}`}
                    >
                      {t("view_profile")}
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => handleBlockUser()}
                    >
                      {t("block_user")}
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </Link>
            </Media>
          </ul>
        </div>
      </div>
      {isChat ? (
        <>
          {props.chatMessages.data.messages ? (
            <>
              <div
                className="new-chat-room-message-sec"
                id="scrollableDiv"
                style={{
                  minHeight: "calc(100vh - 220px)",
                  maxHeight: "calc(100vh - 215px)",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                  marginTop: "0em",
                }}
              >
                <InfiniteScroll
                  dataLength={props.chatMessages.data.messages.length}
                  next={fetchMoreMessages}
                  hasMore={
                    props.chatMessages.data.messages.length <
                    props.chatMessages.data.total
                  }
                  loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                  inverse={true}
                  style={{
                    padding: "2em",
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflow: "inherit",
                  }}
                  scrollableTarget="scrollableDiv"
                >
                  <div ref={latest} />
                  <FancyBox
                    delegate={"[data-fancybox-chat]"}
                    options={{ groupAll: true }}
                  >
                    {props.chatMessages.data.messages.map((message, i) => (
                      <>
                        {chatDate != message.date_formatted ? (
                          <div
                            className="chat-day-container"
                            style={{ zIndex: index }}
                          >
                            <div className="chat-day">
                              {chatDate}
                              {updateChatDate(message.date_formatted)}
                            </div>
                          </div>
                        ) : null}
                        {message.from_user_id == userId ? (
                          <SendChat message={message} key={i} />
                        ) : (
                          <ReceivedChat 
                            message={message} 
                            setRejectPpvModel={setRejectPpvModel}
                            setPpvRequest={setPpvRequest}
                            setChatMessageId={setChatMessageId}
                            setRejectPppvContent={setRejectPppvContent}
                            key={i} 
                          />
                        )}
                      </>
                    ))}
                    {chatDate ? (
                      <div
                        className="chat-day-container"
                        style={{ zIndex: index }}
                      >
                        <div className="chat-day">{chatDate}</div>
                      </div>
                    ) : null}
                  </FancyBox>
                </InfiniteScroll>
              </div>
              <div className="new-chat-room-input-sec">
                <Form
                  className="new-chat-room-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleMessageSubmit({});
                  }}
                >
                  <div
                    className={`emoji-container ${ 
                      showEmojis ? "show" : "hide"
                      }`}
                  >

                    <Picker
                      data={data}
                      onEmojiSelect={onEmojiPick}
                      onClickOutside={() => {
                        console.log("Outside triggered");
                        if (showEmojis) setShowEmojis(false);
                      }}
                    />
                  </div>
                  <InputGroup className="mb-0">
                    <InputGroup.Text onClick={() => handleToggleEmojis()}>
                      <Image
                        className="new-chat-emoji-icon"
                        src={
                          window.location.origin +
                          "/assets/images/feed-story/comments-emoji.svg"
                        }
                      />
                    </InputGroup.Text>        
                    <Form.Control
                      ref={messageField}
                      placeholder={t("type_something")}
                      value={!newChatUpload ? message : ""}
                      onChange={(e) => setMessage(e.target.value)}
                      // onKeyPress={e => {
                      //   if (e.key === "Enter")
                      //     handleMessageSubmit({})
                      // }}
                      autoFocus={true}
                      on
                    />

                    {/* Checking if other user is subscriber or follower of me or not */}
                    {
                      (props.chatMessages.data?.is_subscriber === 1
                      || 
                      props.chatMessages.data?.is_follower === 1)
                      ? (
                        <InputGroup.Text onClick={() => setNewChatUpload(true)}>
                        <Image
                          className="new-chat-file-icon"
                          src={
                            window.location.origin +
                            "/assets/images/new-chat/attach-file.png"
                          }
                        />
                      </InputGroup.Text>
                      ) : ""

                    }
                   

              {
                (props?.selectedUser?.is_certified_content_creator === 2 && props.chatMessages.data?.is_ppv_btn_disable === 1 && props.chatMessages.data?.is_subscribing === 1)
                ? 
                  <>
                    <Button
                      className="ppv-content-btn"
                      // onClick={() => setNewPpvModel(true)}
                      disabled={props.chatMessages.data?.is_ppv_btn_disable === 1}
                    >
                      <Image
                        className="ppv-content-icon"
                        src={
                          window.location.origin + 
                          "/assets/images/new-chat/ppv1.png"
                        }
                      />
                    </Button>
                  </>
                :
                  (props?.selectedUser?.is_certified_content_creator === 2 && props.chatMessages.data?.is_subscribing === 1) ? (

                    <Button
                      className="ppv-content-btn"
                      onClick={() => setNewPpvModel(true)}
                    >
                      <Image
                        className="ppv-content-icon"
                        src={
                          window.location.origin + 
                          "/assets/images/new-chat/ppv.png"
                        }
                      />
                    </Button>
                  ) : ""
              }
                    
              {
                props.selectedUser?.is_certified_content_creator == 2 && (

                  <Button className="sent-tip-btn" style={{background : "none" ,  border : "none", color : "black"}} onClick={()=> setTipModal(true)}  >
                    <Image
                      className="sent-tip-icon"
                      src={
                        window.location.origin +
                        "/assets/images/feed-story/sent-tip-1.svg"
                      }
                    />
                  </Button>
                )
              }

            


              
       
          {sendTip ? (
            <SendTipPaymentModal
              paymentsModal={sendTip}
              closepaymentsModal={closeSendTipModal}
              user_id={props.selectedUser?.user_id}
              type="profile"
            />
          ) : ""}
          

                    <InputGroup.Text onClick={() => handleMessageSubmit({})}>
                      <Image
                        className="new-chat-send-icon"
                        src={
                          window.location.origin +
                          "/assets/images/feed-story/comments-send.svg"
                        }
                      />
                    </InputGroup.Text>                   
                  </InputGroup>
                </Form>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <NewChatUserInfo selectedUser={props.selectedUser} />
      )}    
      {(newChatUpload || ppvRequest) ? (
        <NewChatUploadModal
          newChatUpload={newChatUpload}
          selectedUser={props.selectedUser}
          message={message}
          setMessage={setMessage}
          handleMessageSubmit={handleMessageSubmit}
          closeNewChatUploadModal={closeNewChatUploadModal}
          closePpvRequest={closePpvRequest}
          setNewChatUpload={setNewChatUpload}
          ppvRequest={ppvRequest}
        />
      ) : null}
      {newPpvModel ? (
        <NewppvChatRequest
          newPpvModel={newPpvModel}
          selectedUser={props.selectedUser}
          message={message}
          setMessage={setMessage}
          handleMessageSubmit={handleMessageSubmit}
          closePpvRequestModal={closePpvRequestModal}
          closePpvRequest={closePpvRequest}
          setNewPpvModel={setNewPpvModel}
        />
      ) : null}

      {(rejectPpvModel || rejectPpvContent) ? (
          <RejectppvRequest
            rejectPpvModel={rejectPpvModel}
            closeRejectPpvModal={closeRejectPpvModal}
            message={message}
            setMessage={setMessage}
            // setPpvRejectStatus={setPpvRejectStatus}
            rejectPpvContent={rejectPpvContent}
            closeRejectPpvContent={closeRejectPpvContent}
            handleMessageSubmit={handleMessageSubmit}
            setRejectPpvModel={setRejectPpvModel}
          />
        ) : null}
      {requestVideoCall ? (
        <PrivateCallModal
          requestVideoCall={requestVideoCall}
          closePrivateCallModal={closePrivateCallModal}
          username={props.selectedUser.username}
          userPicture={props.selectedUser.picture}
          videoAmount={props.selectedUser.video_call_amount_formatted}
          name={props.selectedUser.name}
          post_id={null}
          user_id={props.selectedUser.user_id}
        />
      ) : null}
      {requestAudioCall ? (
        <PrivateAudioCallModal
          requestAudioCall={requestAudioCall}
          closePrivateCallModal={closePrivateCallModal}
          username={props.selectedUser.username}
          userPicture={props.selectedUser.picture}
          AudioAmount={props.selectedUser.audio_call_amount_formatted}
          name={props.selectedUser.name}
          post_id={null}
          user_id={props.selectedUser.user_id}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  chatUsers: state.chat.chatUsers,
  chatMessages: state.chat.chatMessages,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewChatRoom));
