import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { translate, t } from "react-multi-lang";
import {
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { createNotification } from "react-redux-notify";
import { format } from "timeago.js";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  deletePostStart,
  fetchSinglePostStart,
} from "../../store/actions/PostAction";
import {
  fetchCommentRepliesStart,
  fetchCommentsStart,
  fetchMoreCommentRepliesStart,
  fetchMoreCommentsStart,
} from "../../store/actions/CommentsAction";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import "../helper/ProfilePostPreviousNext.css";
import "../NewHome/NewHome.css";
import "../NewHome/NewSingleView/NewSingleComment.css";
import NewSinglePostSlider from "../NewHome/NewSingleView/NewSinglePostSlider";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import NewCommentReplies from "../helper/NewCommentReplies";
import NewComments from "../helper/NewComments";
import ReportModeModal from "../helper/ReportModeModal";
import SendTipPaymentModal from "../Model/PaymentModal/SendTipPaymentModal";
import { getErrorNotificationMessage, getSuccessNotificationMessage } from "../helper/NotificationMessage";
import PostForwardModel from "../NewHome/PostForwardModel";
import { subscriptionPaymentStripeStart } from "../../store/actions/SubscriptionAction";
import { unFollowUserStart, unFollowUserSubStart } from "../../store/actions/FollowAction";
import SubscriptionPaymentModal from "../Model/PaymentModal/SubscriptionPaymentModal";

const PostAddCommentModal = (props) => {
  const [reportMode, setReportMode] = useState(false);
  const [sendTip, setTipModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [lastPostId, setLastPostId] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isForwardModelOpen, setIsForwardModelOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });
  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [showUnfollow, setShowUnfollow] = useState(false);

  const [userData, setUserData] = useState([]);

  const {
    isOpenModal,
    handleCloseModal,
    postUniqueId,
    singlePostData,
    setSinglePostData,
    isActiveTab,
  } = props;
  console.log("🚀 ~ PostAddCommentModal ~ singlePostData:", singlePostData);

  const history = useHistory();

  const closeReportModeModal = () => {
    setReportMode(false);
  };

  const closeSendTipModal = () => {
    setTipModal(false);
  };

  const handleCloseForwardModelOpen = () => setIsForwardModelOpen(true);
  const handleCloseForwardModelClose = () => setIsForwardModelOpen(false);

  useEffect(() => {
    if (
      !skipRender &&
      // !props.homePost.loading &&
      Object.keys(singlePostData).length > 0 &&
      lastPostId !== singlePostData.post_id
    ) {
      props.dispatch(
        fetchCommentsStart({
          post_id: singlePostData.post_id,
          skip: 0,
          take: 4,
        })
      );
      setLastPostId(singlePostData.post_id);
    }
  }, [props.homePost]);

  const fetchMoreComments = () => {
    props.dispatch(
      fetchMoreCommentsStart({
        post_id: singlePostData.post_id,
        skip: props.comments.data.post_comments.length,
        take: 4,
      })
    );
  };

  const fetchCommentReply = (commentId) => {
    if (commentId != null) {
      props.dispatch(
        fetchCommentRepliesStart({
          post_id: singlePostData.post_id,
          post_comment_id: commentId,
          skip: 0,
          take: 1,
        })
      );
    }
    setSelectedComment(commentId);
  };

  const fetchMoreCommentReply = (commentId) => {
    props.dispatch(
      fetchMoreCommentRepliesStart({
        post_id: singlePostData.post_id,
        post_comment_id: commentId,
        skip: props.commentReplies.data.post_comment_replies.length,
        take: 1,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleDeletePost = (event, post) => {
    event.preventDefault();
    props.dispatch(deletePostStart({ post_id: post.post_id }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.delPost.loading &&
      Object.keys(props.delPost.data).length > 0
    ) {
      history.push(`/profile`);
    }
  }, [props.delPost]);

  const handleBlockUser = (event, post) => {
    event.preventDefault();
    props.dispatch(saveBlockUserStart({ user_id: post.user_id }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveBlockUser.loading &&
      Object.keys(props.saveBlockUser.data).length > 0
    ) {
      history.push(`/home`);
    }
    setSkipRender(false);
  }, [props.saveBlockUser]);

  const handleLike = (event, post) => {
    event.preventDefault();
    props.dispatch(
      savePostLikeStart({
        post_id: post.post_id,
        isFromHome: true,
        setSinglePostData: setSinglePostData,
      })
    );
  };

  const handleBookmark = (event, post) => {
    event.preventDefault();
    props.dispatch(
      saveBookmarkStart({
        post_id: post.post_id,
        isFromHome: true,
        setSinglePostData: setSinglePostData,
      })
    );
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleUnSubscriber = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  return (
    <Modal
      show={isOpenModal}
      onHide={handleCloseModal}
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal custom-modal-container"
          : "custom-modal-container"
      }`}
    >
      <Modal.Body>
        <div className="new-modal-profile-post">
          <button className="close-btn" onClick={handleCloseModal}>
            <Image
              className="close-arrow"
              src={window.location.origin + "/assets/images/icons/plus.svg"}
            />
          </button>
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="new-single-page-box">
                  <div className="new-single-page-left">
                    <NewSinglePostSlider
                      post={singlePostData}
                      postId={singlePostData?.post_id}
                      postHomePage={"postHomePage"}
                      // postId={postUniqueId}
                    />
                  </div>
                  <div className="new-single-page-right">
                    {/* <div className="back-to-profile">
												{userData.length > 0 || locationD?.state?.data ? <button onClick={handleGoBack} className="settings-submit-btn btn btn-primary back-cross-btn"  >{locationD?.state?.from === "home" ? "×" : "×"}</button> : "" }
											</div> */}
                    <div className="new-single-post-view-card">
                      <div className="new-single-post-view-header">
                        <div className="new-feed-user-info">
                          <div className="live-streaming-user-img-sec">
                            <Image
                              className="new-feed-user-img"
                              src={singlePostData.user_picture}
                            />
                          </div>
                          <div
                            className="new-feed-user-details"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              history.push(`/${singlePostData.user_unique_id}`)
                            }
                          >
                            <h3>
                              {/* {singlePostData.user_displayname} */}
                              <span style={{ display: "flex", gap: "7px" }}>
                                {singlePostData.username}
                                {singlePostData?.user
                                  ?.is_certified_content_creator === 2 ? (
                                  <Image
                                    // style={{ marginBottom: "2px" }}
                                    className="sidebar-verified-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/verified-icon.svg"
                                    }
                                  />
                                ) : null}
                              </span>
                              {/* @{singlePostData.username} */}
                              {singlePostData.is_verified_badge == 1 ? (
                                <span>
                                  <Image
                                    className="sidebar-verified-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/verified-icon.png"
                                    }
                                  />
                                </span>
                              ) : null}
                            </h3>
                            {/* <Link
																to={`/${singlePostData.user_unique_id}`}
															>
																@{singlePostData.username}
															</Link> */}
                            {/* <p
																dangerouslySetInnerHTML={{
																	__html:
																		singlePostData.content_formatted,
																}}
															></p> */}
                          </div>
                        </div>
                        <div className="new-feed-user-btn-sec">
                          {localStorage.getItem("userId") !=
                          singlePostData.user_id ? (
                            singlePostData?.user
                              ?.is_certified_content_creator == 2 ? (
                              <Button
                                className="sent-tip-btn"
                                onClick={() => setTipModal(true)}
                              >
                                <Image
                                  className="sent-tip-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/sent-tip-1.svg"
                                  }
                                />
                                <span>{t("send_tip")}</span>
                              </Button>
                            ) : (
                              ""
                            )
                          ) : null}
                          <Dropdown className="feed-post-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="feed-post-dropdown-btn"
                            >
                              <Image
                                className="three-dots-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/feed-story/3-vertical-dots.svg"
                                }
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <CopyToClipboard
                                text={singlePostData.share_link}
                                onCopy={onCopy}
                              >
                                <Media as="li">
                                  <Link to="#" className="dropdown-a">
                                    {" "}
                                    {t("copy_link_to_post")}{" "}
                                  </Link>
                                </Media>
                              </CopyToClipboard>
                              <Media as="li" className="divider"></Media>
                              {localStorage.getItem("userId") ==
                              singlePostData.user_id ? (
                                //Own post
                                <Media as="li">
                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      handleDeletePost(event, singlePostData)
                                    }
                                    className="dropdown-a"
                                  >
                                    {t("delete_post")}
                                  </Link>
                                </Media>
                              ) : (
                                <>
                                  <Media as="li">
                                    <Link
                                      to="#"
                                      // onClick={(event) => handleReportPost(event, post)}
                                      onClick={() => setReportMode(true)}
                                      className="dropdown-a"
                                    >
                                      {t("report")}
                                    </Link>
                                  </Media>
                                  {isActiveTab === "following" ? (
                                    ""
                                  ) : (
                                    <>
                                      {singlePostData.show_follow === 1 && (
                                        <Media as="li">
                                          <Link
                                            to="#"
                                            // onClick={(event) => handleReportPost(event, post)}
                                            onClick={() => {
                                              if (
                                                localStorage.getItem("userId")
                                              ) {
                                                props.dispatch(
                                                  subscriptionPaymentStripeStart(
                                                    {
                                                      user_unique_id:
                                                      singlePostData?.user
                                                          .user_unique_id,
                                                      plan_type: "months",
                                                      is_free: 0,
                                                      pageType: "userCard",
                                                    }
                                                  )
                                                );
                                              } else {
                                                const notificationMessage =
                                                  getErrorNotificationMessage(
                                                    t("login_to_continue")
                                                  );
                                                props.dispatch(
                                                  createNotification(
                                                    notificationMessage
                                                  )
                                                );
                                              }
                                            }}
                                            className="dropdown-a"
                                          >
                                            {t("subscribe_for_free")}
                                          </Link>
                                        </Media>
                                      )}
                                    </>
                                  )}
                                  {isActiveTab === "following" ? (
                                    ""
                                  ) : (
                                    <>
                                      {singlePostData.show_unfollow === 1 && (
                                        <Media as="li">
                                          <Link
                                            to="#"
                                            // onClick={(event) => handleReportPost(event, post)}
                                            onClick={(event) =>
                                              handleUnfollow(
                                                event,
                                                singlePostData?.user?.user_id
                                              )
                                            }
                                            className="dropdown-a"
                                            style={{
                                              color: "#ff6060",
                                            }}
                                          >
                                            {t("following")}
                                          </Link>
                                        </Media>
                                      )}
                                    </>
                                  )}
                                  {isActiveTab === "subscribing" ? (
                                    ""
                                  ) : (
                                    <>
                                      {singlePostData?.payment_info
                                        ?.unsubscribe_btn_status === 0 &&
                                        singlePostData?.payment_info?.subscription_info
                                        ?.monthly_amount ? (
                                        <Media as="li">
                                          <Link
                                            to="#"
                                            // onClick={(event) => handleReportPost(event, post)}
                                            onClick={(event) => {
                                              subscriptionPayment(
                                                event,
                                                "months",
                                                singlePostData?.payment_info
                                                  ?.subscription_info
                                                  ?.monthly_amount,
                                                singlePostData?.payment_info
                                                  ?.subscription_info
                                                  ?.monthly_amount_formatted
                                              );
                                            }}
                                            className="dropdown-a"
                                          >
                                            {`Subscribe`}
                                          </Link>
                                        </Media>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  {isActiveTab === "subscribing" ? (
                                    ""
                                  ) : (
                                    <>
                                      {singlePostData?.payment_info
                                        ?.unsubscribe_btn_status === 1 && (
                                        <Media as="li">
                                          <Link
                                            to="#"
                                            // onClick={(event) => handleReportPost(event, post)}
                                            onClick={() =>
                                              handleUnfollowModalShow()
                                            }
                                            className="dropdown-a"
                                            style={{
                                              color: "#ff6060",
                                            }}
                                          >
                                            {t("Subscribing")}
                                          </Link>
                                        </Media>
                                      )}
                                    </>
                                  )}
                                  <Media as="li">
                                    <Link
                                      to="#"
                                      onClick={(event) =>
                                        handleBlockUser(event, singlePostData)
                                      }
                                      className="dropdown-a"
                                    >
                                      {" "}
                                      {t("add_to_blocklist_para")}
                                    </Link>
                                  </Media>
                                </>
                              )}

                              {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
																<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
																<Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* Comment Section */}
                      <div className="new-single-post-view-body">
                        <div
                          className="scroll-comment-sec"
                          id="commentScrollDiv"
                          style={{
                            maxHeight: "calc(100vh - 350px)",
                            minHeight: "318px",
                            overflowY: "auto",
                          }}
                        >
                          {props.comments.loading ? (
                            <CommonCenterLoader />
                          ) : (
                            <InfiniteScroll
                              dataLength={
                                props.comments.data.post_comments.length
                              }
                              next={fetchMoreComments}
                              hasMore={
                                props.comments.data.post_comments.length <
                                props.comments.data.total
                              }
                              loader={<CommonCenterLoader />}
                              scrollableTarget="commentScrollDiv"
                              style={{ height: "auto", overflow: "hidden" }}
                            >
                              <div className="user-profile-pic-description">
                                <div className="profile-picture">
                                  <Image
                                    className="comment-section-user-profile-pic"
                                    src={singlePostData.user_picture}
                                  />
                                </div>
                                <div className="user-name-description">
                                  <div className="user-name-desc-inner">
                                    <h5
                                      style={{
                                        margin: "0",
                                        cursor: "pointer",
                                        fontWeight: "600",
                                        fontSize: "1.6em",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          `/${singlePostData.user_unique_id}`
                                        )
                                      }
                                    >
                                      <span
                                        style={{ display: "flex", gap: "7px" }}
                                      >
                                        {singlePostData.username}
                                        {singlePostData?.user
                                          ?.is_certified_content_creator ===
                                        2 ? (
                                          <Image
                                            // style={{ marginBottom: "2px" }}
                                            className="sidebar-verified-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/verified-icon.svg"
                                            }
                                          />
                                        ) : null}
                                      </span>
                                      {/* @{singlePostData.username} */}
                                      {singlePostData.is_verified_badge == 1 ? (
                                        <span>
                                          <Image
                                            className="sidebar-verified-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/verified-icon.png"
                                            }
                                          />
                                        </span>
                                      ) : null}{" "}
                                    </h5>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          singlePostData.content_formatted,
                                      }}
                                      style={{ margin: "0", fontSize: "14px	" }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              {props.comments.data.post_comments.map(
                                (comment, i) => (
                                  <div className="view-reply-comment">
                                    <div className="comment-profile">
                                      <div className="comment-profile-name">
                                        <div className="comment-profile-img-sec">
                                          <Image
                                            className="comments-profile-img"
                                            src={comment.user_picture}
                                          />
                                        </div>
                                        <div className="comment-profile-details">
                                          {/* <h5>{comment.user_displayname}</h5> */}
                                          <span
                                            style={{
                                              display: "flex",
                                              gap: "7px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h6 style={{ fontSize: "1.2rem" }}>
                                              {comment.username}
                                            </h6>
                                            {comment?.is_certified_content_creator ===
                                            1 ? (
                                              <Image
                                                style={{
                                                  marginBottom: "6px",
                                                  fontSize: "14px",
                                                }}
                                                className="sidebar-verified-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/images/new-home/verified-icon.svg"
                                                }
                                              />
                                            ) : null}
                                          </span>
                                          <p
                                            style={{ fontSize: "1.2em" }}
                                            dangerouslySetInnerHTML={{
                                              __html: comment.comment,
                                            }}
                                          ></p>
                                          <div className="comment-reply-btn">
                                            <p className="time-text">
                                              {format(comment.created_at)}
                                            </p>
                                            {selectedComment ===
                                            comment.post_comment_id ? (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(null)
                                                }
                                              >
                                                {t("hide")}
                                              </Button>
                                            ) : (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(
                                                    comment.post_comment_id
                                                  )
                                                }
                                              >
                                                {comment.total_comment_replies >
                                                0 ? (
                                                  <>
                                                    {" "}
                                                    {t(`view_reply`)}{" "}
                                                    {
                                                      comment.total_comment_replies
                                                    }
                                                  </>
                                                ) : (
                                                  t(`reply`)
                                                )}
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="comment-profile-end">
                                        <p className="time-text">
                                          {format(comment.created_at)}
                                          {/* {comment.created} */}
                                        </p>
                                      </div>
                                    </div>
                                    {/* Comment reply */}
                                    {selectedComment ===
                                    comment.post_comment_id ? (
                                      <div className="new-comment-reply-sec">
                                        {props.commentReplies.loading ? (
                                          <CommonCenterLoader />
                                        ) : props.commentReplies.data
                                            .post_comment_replies.length > 0 ? (
                                          <>
                                            {props.commentReplies.data.post_comment_replies.map(
                                              (reply, i) => (
                                                <div
                                                  className="view-reply-sec"
                                                  key={i}
                                                >
                                                  <div className="comment-profile-name">
                                                    <div className="comment-profile-img-sec">
                                                      <Image
                                                        className="comments-profile-img"
                                                        src={reply.user_picture}
                                                      />
                                                    </div>
                                                    <div className="comment-profile-details">
                                                      <h5>
                                                        {reply.user_displayname}
                                                        <span>
                                                          {
                                                            reply.reply_formatted
                                                          }
                                                        </span>
                                                      </h5>

                                                      <div className="comment-reply-btn">
                                                        <p className="time-text">
                                                          {reply.created}
                                                        </p>
                                                        {/* <Button>Reply</Button> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {props.commentReplies.data
                                              .post_comment_replies.length <
                                            props.commentReplies.data.total ? (
                                              <div className="comment-reply-btn">
                                                <Button
                                                  onClick={() =>
                                                    fetchMoreCommentReply(
                                                      comment.post_comment_id
                                                    )
                                                  }
                                                >
                                                  {t("view_reply")}
                                                </Button>
                                              </div>
                                            ) : (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(null)
                                                }
                                              >
                                                {t("hide")}
                                              </Button>
                                            )}
                                          </>
                                        ) : null}
                                        <NewCommentReplies comment={comment} />
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              )}
                            </InfiniteScroll>
                          )}
                        </div>
                      </div>

                      <div className="new-single-post-view-footer">
                        <div className="new-feed-footer-action-btn-sec">
                          <div className="new-single-post-view-action-btn-sec">
                            <div className="new-feed-footer-action-left-sec">
                              <Button
                                className="new-feed-wishlist-btn"
                                onClick={(e) => handleLike(e, singlePostData)}
                              >
                                {singlePostData.is_user_liked === 1 ? (
                                  <Image
                                    className="new-feed-wishlist-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/feed-story/heart.svg"
                                    }
                                  />
                                ) : (
                                  <Image
                                    className="new-feed-wishlist-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/feed-story/heart-outline.svg"
                                    }
                                  />
                                )}
                                <span>{singlePostData.like_count}</span>
                              </Button>
                              <Button className="new-feed-wishlist-btn">
                                <Image
                                  className="new-feed-wishlist-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/comments.svg"
                                  }
                                />
                                <span>{singlePostData.total_comments}</span>
                              </Button>
                              {/* <Button
                                className="new-feed-wishlist-btn"
                                onClick={handleCloseForwardModelOpen}
                              >
                                <Image
                                  className="new-feed-wishlist-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/send.png"
                                  }
                                />
                              </Button> */}
                            </div>
                            <div className="new-single-post-view-time-sec">
                              <p className="time-text">
                                {singlePostData.created}
                              </p>
                            </div>
                          </div>
                          <div className="new-feed-footer-action-right-sec">
                            {/* <Button
                              className="new-feed-bookmark-btn"
                              onClick={(e) => handleBookmark(e, singlePostData)}
                            >
                              {singlePostData.is_user_bookmarked === 1 ? (
                                <Image
                                  className="new-feed-bookmark-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/bookmark-fill.svg"
                                  }
                                />
                              ) : (
                                <Image
                                  className="new-feed-bookmark-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/bookmark-outline.svg"
                                  }
                                />
                              )}
                            </Button> */}
                          </div>
                        </div>
                        {/* Comment Type Form */}
                        <NewComments post={singlePostData} />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {isForwardModelOpen && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <PostForwardModel
              isOpenModal={isForwardModelOpen}
              handleCloseModal={handleCloseForwardModelClose}
              isPostId={singlePostData?.post_id}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              setInputValue={setInputValue}
              inputValue={inputValue}
            />
          </div>
        )}
        {reportMode ? (
          <ReportModeModal
            reportMode={reportMode}
            closeReportModeModal={closeReportModeModal}
            post={singlePostData}
          />
        ) : null}
        {subscrptionPayment ? (
        <SubscriptionPaymentModal
          paymentsModal={subscrptionPayment}
          closepaymentsModal={closePaymentModal}
          name={singlePostData.user.name}
          user_unique_id={singlePostData.user.user_unique_id}
          subscriptionData={subscriptionData}
          pageType={"userCard"}
        />
      ) : null}
      {showUnfollow && (
        <Modal
          show={showUnfollow}
          onHide={handleUnfollowModalClose}
          backdrop="static"
          keyboard={false}
          centered
          className={`${
            localStorage.getItem("theme") !== "" &&
            localStorage.getItem("theme") !== null &&
            localStorage.getItem("theme") !== undefined &&
            localStorage.getItem("theme") === "dark"
              ? "dark-theme-modal"
              : ""
          }
                  `}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("unsubscribe")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("cancel_subscription_conformation")}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="lg"
              onClick={handleUnfollowModalClose}
            >
              {t("close")}
            </Button>
            <Button
              variant="primary"
              size="lg"
              onClick={(event) => handleUnSubscriber(event, singlePostData.user.user_id)}
            >
              {t("yes")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
        {sendTip ? (
          <SendTipPaymentModal
            paymentsModal={sendTip}
            closepaymentsModal={closeSendTipModal}
            post_id={singlePostData.post_id}
            user_id={singlePostData.user_id}
            type={"post"}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  delPost: state.post.delPost,
  comments: state.comment.comments,
  commentReplies: state.comment.commentReplies,
  saveBlockUser: state.users.saveBlockUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PostAddCommentModal));
